import { useEffect } from "react";
import { useAuthenticated, useRedirect } from "react-admin";
import { Routes } from "~/config/Routes";
import { useGetIdentity } from "~/services/auth/hooks";
import ResendEmailVerification from "~/components/auth/login/resend-email-verification";
import AuthenticationLayout from "~/layouts/site/AuthenticationLayout";
import Loading from "~/components/feedback/Loading";

const UnverifiedEmailPage = (props: any) => {
  useAuthenticated();

  const redirect = useRedirect();
  const { identity, isLoading } = useGetIdentity();

  useEffect(() => {
    if (isLoading) {
      return;
    }

    if (identity?.emailVerified) {
      redirect(Routes.FTUX);
    }
  }, [identity?.emailVerified, isLoading]);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <AuthenticationLayout>
      <ResendEmailVerification user={identity} sx={{ mt: 1 }} />
    </AuthenticationLayout>
  );
};

export default UnverifiedEmailPage;
