import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useCallback } from "react";
import { useUpdate, useTranslate } from "react-admin";
import { User } from "@swyft/domain/src/types/users";

import NotifyDialog, {
  NotifyDialogProps,
} from "~/components/feedback/NotifyDialog";
import { useGlobalComponentContext } from "~/components/GlobalComponentContext";
import { useAuthenticatedContext } from "~/components/AuthenticatedContext";
import { Routes } from "~/config/Routes";
import { AppResource } from "~/config/resources";

/**
 * Component that renders alerts controlled by a context or through
 * react-router-dom state.
 *
 * Intended to create global shared alerts. Pass in location.state with react-router-dom, to render
 * alerts after navigating to a route. The dispatcher from the context on the other hand,
 * is helpful to render an alert on-demand.
 *
 *
 * @see useGlobalAlertsContext (in GlobalAlertsContext) this component must be a consumer of the context this hook references
 * @see useNotifyDialog memoized hook (also exported here) to easily update the props of an alert below
 * @link https://reactrouter.com/en/main/components/navigate see examples of how to use location.state after navigation
 * @link https://reactrouter.com/en/main/start/concepts see 'Location State' here to understand how location.state works
 */
const GlobalAlerts = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const translate = useTranslate();
  const { notifyDialog, setNotifyDialog } = useGlobalComponentContext();
  const { user } = useAuthenticatedContext();
  const [update] = useUpdate<User>(AppResource.User, {
    id: user?.id,
    data: { isOnboardingComplete: true },
    previousData: user,
  });

  const handleOnboardingClose = () => {
    update();
    setNotifyDialog({ isOpen: false });
    navigate(Routes.Zones);
  };

  // extract the state from the location object in react-router-dom and only make changes
  // to alerts if the state changes.
  useEffect(() => {
    const locationState = (location.state || {}) as GlobalAlertsState;

    if (locationState.notifyDialog) {
      setNotifyDialog({
        ...locationState.notifyDialog,
        onClose: () => setNotifyDialog({ isOpen: false }),
      });
    }
  }, [location.state]);

  // show the onboarding alert if the user hasn't completed onboarding
  useEffect(() => {
    if (user && !user.isOnboardingComplete) {
      setNotifyDialog({
        title: translate("user.message.onboard.welcome_title"),
        content: translate("user.message.onboard.welcome_desc"),
        dismissLabel: "shared.action.tour",
        isOpen: true,
        onClose: handleOnboardingClose,
      });
    }
  }, []);

  return (
    <>
      <NotifyDialog {...notifyDialog} />
    </>
  );
};

/**
 * A hook for setting the props of the global alert dialog.
 * @returns {(alertProps: AlertDialogProps) => void} a function that sets the alert dialog props
 */
export const useNotifyDialog = () => {
  const { setNotifyDialog } = useGlobalComponentContext();

  return useCallback(
    (alertProps: NotifyDialogProps) =>
      setNotifyDialog({
        ...alertProps,
        onClose: () => setNotifyDialog({ ...alertProps, isOpen: false }),
      }),
    [setNotifyDialog],
  );
};

export interface GlobalAlertsState {
  notifyDialog?: NotifyDialogProps;
}

export default GlobalAlerts;
