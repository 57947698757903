import { useTranslate, useNotify } from "react-admin";
import { useState, useEffect } from "react";
import { Box, Typography, Link, Breadcrumbs } from "@helo/ui";
import { useDefineAppLocation } from "@react-admin/ra-navigation";
import { Merchant } from "@swyft/domain/src/types/merchants";

import { useAuthenticatedContext } from "~/components/AuthenticatedContext";
import { useGlobalComponentContext } from "~/components/GlobalComponentContext";
import InactiveMerchantAlert from "~/components/feedback/InactiveMerchantAlert";
import AddOnRates from "./AddOnRates";
import ServiceLevelRates from "./ServiceLevelRates";
import { DisplayRates, DisplayServiceLevel } from "~/services/data/types";
import AppViewLayout from "~/layouts/app/AppViewLayout";
import GracefulFallback from "~/components/feedback/GracefulFallback";
import { useMerchantsDataProvider } from "~/services/data/merchants";
import Loading from "~/components/feedback/Loading";

const RatesList = () => {
  useDefineAppLocation("settings.rates");
  const { merchant } = useAuthenticatedContext();
  const isOrgActive = merchant?.isActive;
  const translate = useTranslate();
  const notify = useNotify();
  const [resource, dataProvider] = useMerchantsDataProvider();
  const [rates, setRates] = useState<DisplayRates | undefined>();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<any>();

  useEffect(() => {
    const fetchRates = async (merchant: Merchant) => {
      try {
        const { data } = await dataProvider.getRates(resource, {
          merchantId: merchant.id,
          isActive: merchant.isActive,
          country: merchant.countryCode,
        });
        setRates(data);
        setLoading(false);
      } catch (err: any) {
        setError(err);
        setLoading(false);
        notify(`${translate("shared.message.generic.fail")}`, {
          type: "warning",
        });
      }
    };

    if (!merchant?.id) {
      return;
    }

    fetchRates(merchant);
  }, [merchant]);

  return (
    <AppViewLayout
      title="rates.header.title"
      context={translate("rates.header.currency", {
        currency: rates?.currency || "-",
      })}
      breadcrumbs={
        <Breadcrumbs>
          <Link color="inherit" underline="none">
            {translate("menu.settings.settings")}
          </Link>
          <Link color="text.primary" underline="none">
            {translate("rates.header.title")}
          </Link>
        </Breadcrumbs>
      }
      banners={
        !isOrgActive && (
          <InactiveMerchantAlert
            message={translate("rates.messaging.list_unactive_alert")}
          />
        )
      }
    >
      <RatesCard {...{ rates, loading, error }} />
    </AppViewLayout>
  );
};

const RatesCard = ({ rates, loading, error }: RatesCardProps) => {
  const translate = useTranslate();
  const { setIsContactSalesOpen } = useGlobalComponentContext();

  if (loading) {
    return <Loading loadingSecondary={translate("rates.loading")} />;
  }

  if (!rates || error) {
    return (
      <GracefulFallback type="error" details="rates.messaging.fetch_error" />
    );
  }

  return (
    <>
      <Box sx={{ marginBottom: 3 }}>
        <Typography variant="body1">
          {translate("rates.header.disclaimer")}.
          <Link
            sx={{ marginLeft: 1, cursor: "pointer" }}
            onClick={() => {
              setIsContactSalesOpen(true);
            }}
          >
            <Typography variant="body1" component="span">
              {translate("rates.header.contact_sales")}!
            </Typography>
          </Link>
        </Typography>
      </Box>
      {rates?.serviceLevel
        ? Object.keys(rates.serviceLevel).map((service) => {
            return (
              <ServiceLevelRates
                key={`${service}-rates-table`}
                title={service}
                serviceLevel={
                  rates.serviceLevel[service as keyof DisplayServiceLevel]
                }
                currency={rates.currency || ""}
                hasVolumeTiers={rates.hasVolumeTiers}
              />
            );
          })
        : null}
      {rates?.addOns.length ? (
        <AddOnRates
          addOns={rates.addOns}
          currency={rates.currency || ""}
          redeliveryRatePercent={rates?.redeliveryRatePercent}
        />
      ) : null}
    </>
  );
};

interface RatesCardProps {
  rates?: DisplayRates;
  loading: boolean;
  error: any;
}

export default RatesList;
