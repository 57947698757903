import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { Grid, LoadingButton, styled, TextField } from "@helo/ui";
import { yupResolver } from "@hookform/resolvers/yup";
import { ICreateDSP } from "@swyft/domain/src/controllers/dsp";
import { CountryISO2 } from "@swyft/domain/src/types/common";
import { useTranslate, useNotify } from "react-admin";
import { useForm } from "react-hook-form";

import ControlledAutocomplete from "~/components/inputs/ControlledAutocomplete";
import { DSPSoftware, FleetSize } from "~/constants/form";
import { getDspSchema } from "~/common/validators/ftux/dspSchema";
import { Routes } from "~/config/Routes";
import { useDomainContext } from "~/components/DomainContext";
import {
  CountryOptions,
  DSPSoftwareOptions,
  FleetSizeOptions,
} from "~/common/consts";

const DSPForm = () => {
  const translate = useTranslate();
  const { dspController } = useDomainContext();
  const defaultValues: DSPForm = {
    country: "" as CountryISO2,
    companyName: "",
    jobTitle: "",
    fleetSize: "" as FleetSize,
    software: "" as DSPSoftware,
    otherSoftware: "",
  };
  const { register, handleSubmit, formState, control, watch } = useForm({
    defaultValues: defaultValues,
    resolver: yupResolver(getDspSchema(translate)),
    mode: "onChange",
  });
  const navigate = useNavigate();
  const notify = useNotify();
  const softwareFieldValue = watch("software");
  const otherSoftwareOption = useMemo(
    () => DSPSoftwareOptions.find((opt) => opt.label === DSPSoftware.OTHER),
    [],
  );
  const isOtherSoftwareSelected =
    softwareFieldValue === otherSoftwareOption?.value;

  const onSubmit = async (formValues: DSPForm) => {
    try {
      const DSPMap: ICreateDSP = {
        companyName: formValues.companyName,
        jobTitle: formValues.jobTitle,
        fleetSize: formValues.fleetSize,
        software: isOtherSoftwareSelected
          ? formValues.otherSoftware
          : formValues.software,
        country: formValues.country,
      };
      await dspController.createDSP(DSPMap);
      notify("shared.message.submit.ok", {
        type: "success",
      });
      navigate(Routes.Dashboard);
    } catch (err: any) {
      notify(
        `${translate("shared.message.submit.fail")}: ${
          err?.message ?? JSON.stringify(err)
        }`,
        { type: "warning" },
      );
    }
  };

  return (
    <Form noValidate onSubmit={handleSubmit(onSubmit)}>
      <TextField
        error={Boolean(formState?.errors?.companyName)}
        helperText={formState?.errors?.companyName?.message}
        label={translate("Company Name")}
        {...register("companyName")}
        name="companyName"
      />
      <TextField
        error={Boolean(formState?.errors?.jobTitle)}
        helperText={formState?.errors?.jobTitle?.message}
        label={translate("Job Title")}
        {...register("jobTitle")}
        name="jobTitle"
      />
      <ControlledAutocomplete
        control={control}
        options={CountryOptions}
        name="country"
        rules={{ required: true }}
        error={Boolean(formState?.errors?.country)}
        helperText={formState?.errors?.country?.message}
        label={translate("Country")}
        placeholder={translate("shared.form.placeholder.select_country")}
      />
      <ControlledAutocomplete
        control={control}
        options={FleetSizeOptions}
        name="fleetSize"
        rules={{ required: true }}
        error={Boolean(formState?.errors?.fleetSize)}
        helperText={formState?.errors?.fleetSize?.message}
        label={translate("Number of Drivers")}
        placeholder={translate("shared.form.placeholder.select_size")}
      />
      <ControlledAutocomplete
        control={control}
        options={DSPSoftwareOptions}
        name="software"
        rules={{ required: true }}
        error={Boolean(formState?.errors?.software)}
        helperText={formState?.errors?.software?.message}
        label={translate("Software used")}
        placeholder={translate("shared.form.placeholder.select_software")}
      />
      {isOtherSoftwareSelected && (
        <TextField
          error={Boolean(formState?.errors?.software)}
          helperText={formState?.errors?.otherSoftware?.message}
          label={translate("Name of the software")}
          {...register("otherSoftware")}
          name="otherSoftware"
        />
      )}
      <ButtonContainer>
        <LoadingButton
          type="submit"
          variant="contained"
          loading={formState?.isSubmitting}
          size="large"
        >
          {translate("Save")}
        </LoadingButton>
      </ButtonContainer>
    </Form>
  );
};

const Form = styled("form")({
  display: "grid",
  gridTemplateColumns: "1fr",
  width: "500px",
  gap: 40,
});

const ButtonContainer = styled(Grid)({
  display: "grid",
});

// TODO: this interface needs to check against the key of the enum instead of the value
interface DSPForm {
  country: CountryISO2;
  companyName: string;
  jobTitle: string;
  fleetSize: FleetSize;
  software: DSPSoftware;
  otherSoftware: string;
}

export default DSPForm;
