import { Alert, Box, LoadingButton, Typography } from "@helo/ui";
import { Controller, useForm } from "react-hook-form";
import { PasswordField } from "@helo/ui/components";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import { REGEX_PATTERNS } from "~/common/consts";
import { useTranslate, useNotify } from "react-admin";

import AuthenticationLayout from "~/layouts/site/AuthenticationLayout";
import { Routes } from "~/config/Routes";

interface ResetPasswordData {
  password: string;
}

const ResetPasswordPage = () => {
  const navigate = useNavigate();
  const translate = useTranslate();
  const location = useLocation();
  const notify = useNotify();

  const [code, setCode] = useState<string | null>(null);
  const [email, setEmail] = useState<string | null>(null);
  const [isLinkExpired, setIsLinkExpired] = useState<boolean>(false);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const code = queryParams.get("oobCode") || "";

    if (!code) {
      navigate(Routes.ForgotPassword);
      return;
    }

    setCode(code);

    firebase
      .auth()
      .verifyPasswordResetCode(code)
      .then(function (email) {
        setEmail(email);
      })
      .catch(function () {
        setIsLinkExpired(true);
      });
  }, [location.search, navigate]);

  const {
    handleSubmit,
    control,
    formState: { isSubmitting },
  } = useForm<ResetPasswordData>({
    defaultValues: {
      password: "",
    },
    mode: "onChange",
  });

  const handleVerifiedSubmit = async ({ password }: ResetPasswordData) => {
    try {
      await firebase.auth().confirmPasswordReset(code as string, password);

      notify("user.message.password_update.ok", {
        type: "success",
      });
      navigate(Routes.Root);
    } catch (error: any) {
      notify(
        `${translate("user.message.password_update.fail")} ${
          error?.message ?? JSON.stringify(error)
        }`,
        { type: "warning" },
      );
    }
  };

  return (
    <AuthenticationLayout>
      <Typography
        variant="h4"
        sx={{ mb: 3 }}
        fontWeight={600}
        letterSpacing={-0.5}
      >
        {translate("Reset Password")}
      </Typography>
      {isLinkExpired ? (
        <Alert color="error">
          {translate(`Your verification link is expired. Please try requesting a new Link
          from Forgot Password`)}
        </Alert>
      ) : (
        <>
          <Box mb={3}>
            <Typography variant="body2">
              {translate("You are resetting Password for")}
            </Typography>
            <Typography variant="body2" color="success.dark" fontWeight={500}>
              {email}
            </Typography>
          </Box>
          <form onSubmit={handleSubmit(handleVerifiedSubmit)}>
            <Controller
              name={"password"}
              control={control}
              rules={{
                required: {
                  value: true,
                  message: translate("This field is required"),
                },
                pattern: {
                  value: REGEX_PATTERNS.password,
                  message: translate(
                    "Password should contain 8 characters, with mix of letters, numbers & symbols",
                  ),
                },
              }}
              render={({
                field: { onChange, value },
                fieldState: { error, isDirty, invalid },
              }) => (
                <PasswordField
                  onChange={onChange}
                  value={value}
                  fullWidth
                  label="Enter new Password"
                  error={Boolean(error)}
                  sx={{ mb: 3 }}
                  helperText={
                    error
                      ? error.message
                      : isDirty
                      ? translate("Password Strength : Strong")
                      : translate(
                          "Password should contain 8 characters, with mix of letters, numbers & symbols",
                        )
                  }
                />
              )}
            />
            <Box>
              <LoadingButton
                type="submit"
                variant="contained"
                loading={isSubmitting}
              >
                {translate("Reset Password")}
              </LoadingButton>
            </Box>
          </form>
        </>
      )}
    </AuthenticationLayout>
  );
};

export default ResetPasswordPage;
