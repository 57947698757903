import { styled, Typography, Box, ThemeType, useMediaQuery } from "@helo/ui";
import { ReactComponent as SwyftIcon } from "@helo/ui/assets/images/swyft-logo-white.svg";
import { useEffect } from "react";
import { AppBar, useSidebarState } from "react-admin";

import { useAuthenticatedContext } from "~/components/AuthenticatedContext";
import AppCurrentUserMenu from "~/components/navigation/AppCurrentUserMenu";

const AppHeaderNav = (props: any) => {
  // NOTE: will have to change this logic in the future to display the parent organization's name regardless of
  // whether they're a merchant or DSP, once the backend changes have been made.
  const { merchant } = useAuthenticatedContext();
  const isNotMobile = useMediaQuery((theme: ThemeType) =>
    theme.breakpoints.up("sm"),
  );
  const [open, setOpen] = useSidebarState();

  useEffect(() => {
    // open the side navigation in higher resolutions if it's closed (as the toggle won't be visible)
    if (isNotMobile && !open) {
      setOpen(true);
    }
  }, [isNotMobile, open]);

  return (
    <AppBar
      userMenu={<AppCurrentUserMenu />}
      sx={(theme) => ({
        display: "grid",
        py: 1,
        [theme.breakpoints.up("sm")]: {
          pl: 3,
          pr: 1.5,
          py: 0,
          "& .RaAppBar-menuButton": {
            display: "none",
          },
        },
      })}
      {...props}
    >
      <Box
        sx={({ breakpoints }) => ({
          display: "flex",
          flexGrow: 1,
          flexDirection: "column",
          [breakpoints.up("sm")]: {
            flexDirection: "row",
            alignItems: "center",
          },
        })}
      >
        <SwyftLogoContainer />
        {merchant?.name && (
          <OrgLogoContainer>
            <Separator variant="h6" component="p">
              |
            </Separator>
            <Typography variant="subtitle1" component="p">
              {merchant.name}
            </Typography>
          </OrgLogoContainer>
        )}
      </Box>
    </AppBar>
  );
};

const SwyftLogoContainer = styled(SwyftIcon)({
  width: "85px",
  height: "100%",
  display: "grid",
  placeContent: "center",
});

const Separator = styled(Typography)(({ theme }) => ({
  padding: "8px",
  display: "none",
  [theme.breakpoints.up("sm")]: {
    display: "inline-block",
  },
})) as typeof Typography;

const OrgLogoContainer = styled("div")({
  display: "flex",
  alignItems: "center",
});

export default AppHeaderNav;
