import { useTranslate } from "react-admin";
import { useAuthenticatedContext } from "~/components/AuthenticatedContext";
import inactiveSummaryImg from "assets/images/inactive-summary.png";
import DataStudioEmbed from "~/components/data/DataStudioEmbed";
import InactiveMerchantAlert from "~/components/feedback/InactiveMerchantAlert";
import AppViewLayout from "~/layouts/app/AppViewLayout";

const Reports = () => {
  const translate = useTranslate();
  const { merchant } = useAuthenticatedContext();
  const isOrgActive = merchant?.isActive;

  return (
    <AppViewLayout
      title="pages.reports.title"
      context="pages.reports.disclaimer"
      banners={
        !isOrgActive && (
          <InactiveMerchantAlert
            message={translate("reports.messaging.unactive_alert")}
          />
        )
      }
    >
      <DataStudioEmbed
        isOrgActive={isOrgActive}
        organizationId={merchant?.id}
        title={translate("reports.title")}
        description={translate("reports.desc")}
        placeholderImgSrc={inactiveSummaryImg}
        summaryType="financial"
      />
    </AppViewLayout>
  );
};

export default Reports;
