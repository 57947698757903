import { usePermissions as useRaPermissions } from "react-admin";
import { getPermissionsFromRoles, Permissions } from "@react-admin/ra-rbac";
import { UserRole } from "@swyft/domain/src/types/users";
export type { Permissions } from "@react-admin/ra-rbac";

export const usePermissions = () => useRaPermissions<Permissions>();

export const getPermissionsForUser = (
  userId: string,
  role?: UserRole,
  organizationId?: string,
) =>
  getPermissionsFromRoles({
    roleDefinitions: {
      [UserRole.ADMIN]: [{ action: "*", resource: "*" }],
      [UserRole.MEMBER]: [
        { action: ["read", "list"], resource: "*" },
        { action: "create", resource: "shipments" },
      ],
    },
    userPermissions: [
      {
        action: ["read", "write"],
        resource: "users",
        type: "allow",
        record: { id: userId },
      },
    ],
    // REVIEW: the role is temporarily being normalized to lowercase to match the UserRole enum,
    // till the Firebase trigger to keep the claims in-sync with the user object is written
    userRoles: role ? [role.toLowerCase()] : [],
  });
