import { Control, Controller } from "react-hook-form";
import { TextField, Autocomplete } from "@helo/ui";
import { SelectOption } from "~/common/helpers";
import { useMemo } from "react";

interface ControlledAutocomplete {
  options: SelectOption[];
  name: string;
  label: string;
  error: boolean;
  control?: Control<any>;
  placeholder?: string;
  helperText?: string;
  defaultValue?: string;
  onValueChange?: Function;
  rules?: object;
  sx?: object;
}

/**
 * react-hook-form controlled wrapper around the Autocomplete component.
 *
 * Usage: Pass in a control instance when invoking react-hook-form's useForm
 * where you also provide the default value, or use this component
 * as a child of a FormProvider context from which the control can be extracted.
 *
 * NOTE: MUI's autocomplete, oddly has two separate states that can be controlled.
 * the final value of the autocomplete itself, and the value of the input rendered inside.
 * For ease of use, we let the consumer pass in just the actual value from our data source
 * and we do the mapping to the respective option object in here instead.
 * @see https://mui.com/material-ui/react-autocomplete/#controlled-states
 */
const ControlledAutocomplete = ({
  options,
  name,
  defaultValue,
  error,
  rules,
  helperText,
  label,
  placeholder,
  control,
  onValueChange,
  sx,
}: ControlledAutocomplete) => {
  const defaultOption = useMemo(
    () => options.find((opt) => opt.value === defaultValue) ?? null,
    [defaultValue],
  );

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultOption}
      rules={rules}
      render={({ field: { value: controlledValue, onChange, ...rest } }) => {
        const selectedOption = useMemo(
          () => options.find((opt) => opt.value === controlledValue),
          [controlledValue],
        );

        return (
          <Autocomplete
            {...rest}
            disablePortal
            options={options}
            value={selectedOption ?? null}
            isOptionEqualToValue={(option, selectedOption) =>
              option.value === selectedOption.value
            }
            getOptionLabel={(option) => option?.label ?? ""}
            renderInput={(params) => {
              return (
                <TextField
                  {...params}
                  error={error}
                  helperText={helperText}
                  label={label}
                  placeholder={placeholder || label}
                  sx={sx}
                />
              );
            }}
            onChange={(event: any, newValue: any) => {
              // send the correct value to react-hook-form, once the user selects a value
              onChange(newValue?.value ?? newValue);

              // if onValueChanged prop is provided, pass the value along to that too
              if (onValueChange) {
                onValueChange(newValue?.value ?? newValue);
              }
            }}
          />
        );
      }}
    />
  );
};

export default ControlledAutocomplete;
