import { useCallback, ReactNode, ReactElement } from "react";
import { useTranslate, useNotify } from "react-admin";
import {
  useDropzone,
  DropzoneOptions,
  FileRejection,
  DropEvent,
} from "react-dropzone";
import { useController, UseControllerProps } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import {
  styled,
  List,
  ListItem,
  IconButton,
  DeleteIcon,
  CloudUploadIcon,
  Link,
  SxProps,
  Box,
  Typography,
} from "@helo/ui";
import {
  deleteObject,
  getDownloadURL,
  ref,
  uploadBytes,
} from "firebase/storage";
import clsx from "clsx";

import { displayBytes, shallowEqual } from "~/common/helpers";
import { firebaseStorage } from "~/services/firebase";

function FileDropPreview(props: FileDropPreviewProps) {
  const { className, onRemove: onRemoveProp, file, ...rest } = props;

  const onRemove = (file: FileDropFile) => {
    if (onRemoveProp) {
      onRemoveProp(file);
    }
  };

  return (
    <ListItem
      disableGutters
      secondaryAction={
        <IconButton onClick={() => onRemove(file)}>
          <DeleteIcon sx={{ color: "error.main" }} />
        </IconButton>
      }
      className={className}
    >
      <Link href={file.previewURL || ""} target="__blank">
        {file?.rawFile?.name}
        <span>&nbsp;({displayBytes(file?.rawFile?.size)})</span>
      </Link>
    </ListItem>
  );
}

const FileDropRootContainer = styled(Box)(({ theme }) => ({
  display: "block",
  width: "100%",
}));

function FileDrop(props: FileDropProps) {
  const {
    name,
    accept,
    className,
    inputProps,
    maxSize,
    minSize,
    multiple = false,
    label,
    labelMultiple = "ra.input.file.upload_several",
    labelSingle = "ra.input.file.upload_single",
    labelOnDrag = "shared.form.file.drop_prompt",
    labelAccepted = "shared.form.file.accepted_info",
    messageUploadValidationError = "shared.validation.file.upload_fail",
    messageUploadError = "shared.message.file.upload_fail",
    messageGenericError = "shared.message.generic.fail",
    getUploadToStoragePath,
    options = {},
    onRemove: onRemoveProp,
    placeholder,
    validateFileRemoval,
    sx,
    ...rest
  } = props;
  const translate = useTranslate();
  const notify = useNotify();
  const {
    field: { onChange, value },
    formState: { errors },
  } = useController({
    name,
    ...rest,
  });
  const files: FileDropFile[] = value
    ? Array.isArray(value)
      ? value
      : [value]
    : [];
  const { onDrop: onDropProp } = options;

  const createPreviewURL = (file: File) => {
    if (!(file instanceof File)) {
      return;
    }

    return window.URL.createObjectURL(file);
  };

  const deletePreviewURL = (previewURL: string | undefined) => {
    if (previewURL) {
      window.URL.revokeObjectURL(previewURL);
    }
  };

  const uploadFileToStorage = async (file: File) => {
    if (!getUploadToStoragePath) {
      return {};
    }

    const uploadPath = getUploadToStoragePath(file);
    const fileStorageRef = ref(firebaseStorage, uploadPath);

    try {
      const snapshot = await uploadBytes(fileStorageRef, file);
      const fileDownloadURL = await getDownloadURL(snapshot.ref);

      return {
        downloadURL: fileDownloadURL,
        storagePath: uploadPath,
      };
    } catch (e) {
      notify(messageUploadError, { type: "warning" });
      return {};
    }
  };

  const removeFileFromStorage = async (filePath: string) => {
    const fileStorageRef = ref(firebaseStorage, filePath);

    try {
      await deleteObject(fileStorageRef);
      return true;
    } catch (e) {
      notify(messageGenericError, { type: "warning" });
      return false;
    }
  };

  // take the user-provided file, and transform it into a respresentation for this component
  const transformFile = async (file: File) => {
    if (!(file instanceof File)) {
      return file;
    }

    // create a browser-preview URL
    const preview = createPreviewURL(file);
    // upload to cloud storage if necessary
    const uploadedFileDetails = getUploadToStoragePath
      ? await uploadFileToStorage(file)
      : {};
    const transformedFile: FileDropFile = {
      rawFile: file,
      previewURL: preview,
      storagePreviewURL: uploadedFileDetails?.downloadURL,
      storagePreviewPath: uploadedFileDetails?.storagePath,
    };

    return transformedFile;
  };

  const transformFiles = (files: File[]) => {
    if (!files) {
      return [];
    }

    if (Array.isArray(files)) {
      return files.map(transformFile);
    }

    return [transformFile(files)];
  };

  const onDrop = useCallback(
    async (
      newFiles: File[],
      rejectedFiles: FileRejection[],
      event: DropEvent,
    ) => {
      const transformedFiles = (
        await Promise.allSettled(transformFiles(newFiles))
      ).map((file) => {
        if (file.status === "fulfilled") {
          return file.value;
        }

        onRemove(file.reason)();
        return file.reason as FileDropFile;
      });

      const updatedFiles = multiple
        ? [...files, ...transformedFiles]
        : [...transformedFiles];

      if (multiple) {
        onChange(updatedFiles);
      } else {
        onChange(updatedFiles[0]);
      }

      if (onDropProp) {
        onDropProp(newFiles, rejectedFiles, event);
      }
    },
    [files],
  );
  const onDropRejected = useCallback((rejectedFiles: FileRejection[]) => {
    if (!rejectedFiles) {
      return;
    }

    notify(messageUploadValidationError, { type: "warning" });
  }, []);

  const onRemove = useCallback(
    (file: FileDropFile) => async () => {
      // validate if available, first
      if (validateFileRemoval) {
        try {
          await validateFileRemoval(file);
        } catch (e) {
          return;
        }
      }

      // cleanup
      deletePreviewURL(file.previewURL);

      if (file.storagePreviewPath) {
        removeFileFromStorage(file.storagePreviewPath);
      }

      // update react-hook-form
      if (multiple) {
        const filteredFiles = files.filter(
          (stateFile) => !shallowEqual(stateFile, file),
        );
        onChange(filteredFiles as any);
      } else {
        onChange(null);
      }

      // call event handler, if provided
      if (onRemoveProp) {
        onRemoveProp(file);
      }
    },
    [],
  );
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept,
    maxSize,
    minSize,
    multiple,
    ...options,
    onDrop,
    onDropRejected,
  });

  return (
    <FileDropRootContainer className={clsx(className)} sx={sx}>
      <Box
        sx={({ palette }) => ({
          padding: 4,
          cursor: "pointer",
          border: `2px dashed ${
            !!errors && Object.keys(errors).includes(name)
              ? palette.error.main
              : palette.neutral.contrastText
          }`,
          borderRadius: "0.5rem",
        })}
        {...getRootProps()}
      >
        <input {...getInputProps({ ...inputProps })} />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CloudUploadIcon sx={{ mb: 24 / 14 }} />
          {placeholder ? (
            placeholder
          ) : multiple ? (
            <Typography variant="body1">
              {translate(isDragActive ? labelOnDrag : labelMultiple)}
            </Typography>
          ) : (
            <Typography variant="body1">
              {translate(isDragActive ? labelOnDrag : labelSingle)}
            </Typography>
          )}
          <Typography variant="caption" sx={{ mt: 8 / 14 }}>
            {translate(labelAccepted, {
              format:
                accept && typeof accept === "object"
                  ? Object.values(accept).join(",")
                  : "any",
              max: maxSize ? displayBytes(maxSize) : "∞",
            })}
          </Typography>
          {/* NOTE: works for flat, single errors only */}
          {errors && (
            <ErrorMessage
              name={name}
              errors={errors}
              render={({ message }) => (
                <Typography
                  variant="caption"
                  sx={({ palette }) => ({
                    mt: 8 / 14,
                    color: palette.error.main,
                  })}
                >
                  {translate(message)}
                </Typography>
              )}
            />
          )}
        </Box>
      </Box>
      <Box sx={{ mt: 1 }}>
        {files && (
          <List dense={true}>
            {files.map((file, index) => (
              <FileDropPreview
                key={index}
                file={file}
                onRemove={onRemove(file)}
              />
            ))}
          </List>
        )}
      </Box>
    </FileDropRootContainer>
  );
}

export type FileDropProps = UseControllerProps & {
  name: string;
  accept?: DropzoneOptions["accept"];
  className?: string;
  labelMultiple?: string;
  labelSingle?: string;
  labelOnDrag?: string;
  labelAccepted?: string;
  messageUploadValidationError?: string;
  messageUploadError?: string;
  messageGenericError?: string;
  maxSize?: DropzoneOptions["maxSize"];
  minSize?: DropzoneOptions["minSize"];
  multiple?: DropzoneOptions["multiple"];
  getUploadToStoragePath?: (file: File) => string;
  options?: DropzoneOptions;
  onRemove?: (file: FileDropFile) => void;
  placeholder?: ReactNode;
  label?: string | ReactElement | false;
  inputProps?: any;
  validateFileRemoval?(file: any): boolean | Promise<boolean>;
  sx?: SxProps;
};

export type FileDropPreviewProps = {
  file: FileDropFile;
  onRemove?: Function;
  className?: string;
};

export interface FileDropFile {
  rawFile: File;
  previewURL?: string;
  storagePreviewURL?: string;
  storagePreviewPath?: string;
}

export default FileDrop;
