/* eslint-disable import/prefer-default-export */
import { Merchant } from "../types/merchants";
import {
  collection,
  doc,
  DocumentData,
  Firestore,
  FirestoreDataConverter,
  getDoc,
  WithFieldValue,
} from "firebase/firestore";

const merchantsConverter: FirestoreDataConverter<Merchant> = {
  fromFirestore(snapshot) {
    return snapshot.data() as Merchant; // TODO: map appropriate values
  },
  toFirestore(model: WithFieldValue<Merchant>): DocumentData {
    return model; // TODO: map appropriate values
  },
};

const getMerchantsCollection = (db: Firestore) =>
  collection(db, "merchants").withConverter(merchantsConverter);

export class MerchantNotFoundError extends Error {
  constructor(id: string) {
    super(`No merchant found for id ${id}`);
  }
}

export const get = async (
  db: Firestore,
  params: {
    id: string;
  },
) => {
  const merchantDocRef = doc(getMerchantsCollection(db), params.id);
  const merchantDocsSnapshot = await getDoc(merchantDocRef);

  if (merchantDocsSnapshot.exists()) {
    return merchantDocsSnapshot.data();
  }

  throw new MerchantNotFoundError(params.id);
};
// TODO: be removed once all rate-cards are migrated to merchant-zones
export const getRates = async (
  db: Firestore,
  params: {
    id: string;
  },
) => {
  const merchantDocRef = doc(getMerchantsCollection(db), params.id);
  const merchantDocsSnapshot = await getDoc(merchantDocRef);

  if (merchantDocsSnapshot.exists()) {
    //must have at least 1 RateCardV2Entry to display rateCardV2
    if (!merchantDocsSnapshot.data()?.rateCardV2?.entries?.length) {
      return merchantDocsSnapshot.data()?.rateCard;
    }
    return merchantDocsSnapshot.data()?.rateCardV2;
  }

  throw new MerchantNotFoundError(params.id);
};
