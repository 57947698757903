import { AppBar, ButtonBase, Button, styled, Link, Box } from "@helo/ui";
import { ReactComponent as SwyftIcon } from "@helo/ui/assets/images/swyft-logo-color.svg";
import { useNavigate } from "react-router-dom";
import { useGetIdentity, useTranslate } from "react-admin";
import AccountButton from "~/components/auth/login/account-button";
import { Routes } from "~/config/Routes";

const Navbar = () => {
  const navigate = useNavigate();
  const translate = useTranslate();
  const { identity } = useGetIdentity();

  return (
    <NavContainer>
      <ButtonContainer onClick={() => navigate(Routes.Root)}>
        <SwyftIconContainer />
      </ButtonContainer>
      <AccountContainer>
        {!identity && (
          <Button
            variant="outlined"
            href="https://www.useswyft.com"
            target="_blank"
            rel="noopener noreferrer"
            sx={({ breakpoints }) => ({
              fontSize: "0.6rem",
              [breakpoints.up("md")]: {
                fontSize: "0.8rem",
              },
              py: 0.75,
              px: 2,
            })}
          >
            {translate("header.public.back_to_useswyft")}
          </Button>
        )}
        <AccountButton />
      </AccountContainer>
    </NavContainer>
  );
};
export default Navbar;

const NavContainer = styled(AppBar)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  position: "static",
  minHeight: "64px",
  maxHeight: "5%",
  backgroundColor: theme.palette.primary.contrastText,
  boxShadow: theme.shadows[1],
  flexDirection: "row",
  justifyContent: "space-between",
  paddingInline: theme.spacing(2),
  paddingBlock: theme.spacing(0.5),
}));

const ButtonContainer = styled(ButtonBase)({
  display: "flex",
  justifyContent: "flex-start",
});

const AccountContainer = styled(Box)({
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "center",
});

const SwyftIconContainer = styled(SwyftIcon)(({ theme }) => ({
  height: "64px",
  width: "130px",
}));
