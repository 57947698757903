import {
  Button,
  Grid,
  LoadingButton,
  styled,
  TextField,
  Breadcrumbs,
  Link,
} from "@helo/ui";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslate, useCreate, CreateBase, useNotify } from "react-admin";
import { useNavigate, Link as RouterLink } from "react-router-dom";
import { UserRole, UserStatus } from "@swyft/domain/src/types/users";
import { OrganizationType } from "@swyft/domain/src/types/organizations";

import ControlledAutocomplete from "~/components/inputs/ControlledAutocomplete";
import { getUserCreateSchema } from "~/common/validators/user/UserCreateSchema";
import { shallowCompare } from "~/common/helpers";
import { Routes } from "~/config/Routes";
import { UserCreateForm } from "./types";
import { useAuthenticatedContext } from "~/components/AuthenticatedContext";
import AppViewLayout from "~/layouts/app/AppViewLayout";
import { AppResource } from "~/config/resources";
import { UserRoleOptions } from "~/common/consts";

const defaultValues: UserCreateForm = {
  firstName: "",
  lastName: "",
  email: "",
  role: UserRole.MEMBER,
  countryCode: "+1",
  phone: "",
  status: UserStatus.ACTIVE,
};

const toastDuration = 2000;

const UserCreate = () => {
  const translate = useTranslate();
  const { register, handleSubmit, formState, control } = useForm({
    defaultValues: defaultValues,
    resolver: yupResolver(getUserCreateSchema(translate)),
    mode: "onChange",
  });
  const notify = useNotify();
  const navigate = useNavigate();
  const [create] = useCreate();
  const { identity, merchant } = useAuthenticatedContext();

  const onSubmit = async (formValues: UserCreateForm) => {
    try {
      if (shallowCompare(defaultValues, formValues)) {
        return;
      }

      const { role, ...filteredFormValues } = formValues;

      await create(
        AppResource.User,
        {
          data: filteredFormValues,
          meta: {
            org: {
              type: !!merchant
                ? OrganizationType.Merchant
                : OrganizationType.DeliveryServicePartner,
              id: identity?.organizationId,
              role: role,
            },
          },
        },
        { returnPromise: true },
      );

      notify("shared.message.create.ok", {
        type: "success",
        autoHideDuration: toastDuration,
        messageArgs: {
          name: translate("resources.users.name", { smart_count: 1 }),
          smart_count: 1,
        },
      });
      navigate(Routes.Users);
    } catch (err: any) {
      notify(
        `${translate("shared.message.create.fail", {
          name: translate("resources.users.name", { smart_count: 1 }),
          smart_count: 1,
        })} ${err?.message ?? JSON.stringify(err)}`,
        {
          type: "warning",
          autoHideDuration: toastDuration,
        },
      );
    }
  };

  return (
    <AppViewLayout
      variant="centered"
      title={translate("shared.content.create.title", {
        name: translate("resources.users.name", { smart_count: 1 }),
      })}
      breadcrumbs={
        <Breadcrumbs>
          <Link underline="none" color="inherit">
            {translate("menu.settings.settings")}
          </Link>
          <Link
            underline="hover"
            color="inherit"
            component={RouterLink}
            to={Routes.Users}
          >
            {translate("menu.settings.users")}
          </Link>
          <Link underline="none" color="text.primary">
            {translate("shared.content.create.title", {
              name: translate("resources.users.name", { smart_count: 1 }),
            })}
          </Link>
        </Breadcrumbs>
      }
    >
      <CreateBase>
        <Form noValidate onSubmit={handleSubmit(onSubmit)}>
          <DualColumns>
            <TextField
              error={Boolean(formState?.errors?.firstName)}
              helperText={formState?.errors?.firstName?.message}
              label={translate("resources.users.fields.firstName")}
              {...register("firstName")}
              name="firstName"
            />
            <TextField
              error={Boolean(formState?.errors?.lastName)}
              helperText={formState?.errors?.lastName?.message}
              label={translate("resources.users.fields.lastName")}
              {...register("lastName")}
              name="lastName"
            />
          </DualColumns>
          <TextField
            error={Boolean(formState?.errors?.email)}
            helperText={formState?.errors?.email?.message}
            label={translate("resources.users.fields.email")}
            type="email"
            {...register("email")}
            name="email"
          />
          <PhoneContainer>
            <TextField
              error={Boolean(formState?.errors?.countryCode)}
              helperText={formState?.errors?.countryCode?.message}
              label={translate("resources.users.fields.countryCode")}
              type="tel"
              disabled
              {...register("countryCode")}
              name="countryCode"
            />
            <TextField
              error={Boolean(formState?.errors?.phone)}
              helperText={formState?.errors?.phone?.message}
              label={translate("resources.users.fields.phone")}
              type="tel"
              {...register("phone")}
              name="phone"
            />
          </PhoneContainer>
          <ControlledAutocomplete
            control={control}
            options={UserRoleOptions}
            sx={{
              "& input": {
                textTransform: "capitalize",
              },
            }}
            name="role"
            rules={{ required: true }}
            error={Boolean(formState?.errors?.role)}
            helperText={formState?.errors?.role?.message}
            label={translate("resources.users.fields.role")}
          />
          <ButtonContainer>
            <Button
              type="submit"
              variant="text"
              size="large"
              onClick={() => navigate(Routes.Users)}
              disableElevation
              style={{ textTransform: "none" }}
            >
              {translate("ra.action.cancel")}
            </Button>
            <LoadingButton
              type="submit"
              variant="contained"
              loading={formState?.isSubmitting}
              size="large"
              disableElevation
              style={{ textTransform: "none" }}
            >
              {translate("ra.action.save")}
            </LoadingButton>
          </ButtonContainer>
        </Form>
      </CreateBase>
    </AppViewLayout>
  );
};

const Form = styled("form")(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "1fr",
  width: "600px",
  gap: 20,
  placeContent: "center",
  paddingBottom: 20,
}));

const DualColumns = styled(Grid)(() => ({
  display: "grid",
  gridTemplateColumns: "1fr 1fr",
  columnGap: 10,
}));

const PhoneContainer = styled(Grid)(() => ({
  display: "grid",
  gridTemplateColumns: "1fr 5fr",
  columnGap: 10,
}));

const ButtonContainer = styled(Grid)(() => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-end",
  gap: 20,
}));

export default UserCreate;
