import { ReactNode } from "react";
import AuthBanner from "@helo/ui/assets/images/auth-banner.png";
import { Card, CardContent, styled, useTheme, useMediaQuery } from "@helo/ui";

import BaseLayout from "../BaseLayout";
import Navbar from "~/components/navigation/navbar";
import Footer from "~/components/navigation/footer";

interface AuthenticationLayoutProps {
  children: ReactNode;
}

const AuthenticationLayout = ({ children }: AuthenticationLayoutProps) => {
  return (
    <BaseLayout>
      <Navbar />
      <ContentContainer>
        <DetailsCard elevation={1} variant="elevation">
          <CardContentContainer>{children}</CardContentContainer>
        </DetailsCard>
        <ImageContainer />
      </ContentContainer>
      <Footer />
    </BaseLayout>
  );
};

export default AuthenticationLayout;

const ContentContainer = styled("div")(({ theme }) => ({
  display: "flex",
  height: "max-content",
  justifyContent: "center",
  padding: "12px 24px",
  [theme.breakpoints.up("md")]: {
    justifyContent: "space-evenly",
    minHeight: "100%",
    padding: "24px 48px",
  },
}));

const ImageContainer = styled("div")(({ theme }) => ({
  backgroundImage: `url(${AuthBanner})`,
  backgroundSize: "contain",
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
  display: "none",
  [theme.breakpoints.up("md")]: {
    display: "inline-block",
    flexGrow: 0.5,
  },
}));

const DetailsCard = styled(Card)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    width: "50%",
    alignSelf: "center",
  },
  marginTop: "2%",
  marginBottom: "2%",
  maxWidth: "500px",
}));

const CardContentContainer = styled(CardContent)(() => ({
  display: "flex",
  justifyContent: "space-between",
  flexDirection: "column",
  p: 4,
}));
