import { Translate } from "react-admin";
import { object, string } from "yup";
import { REGEX_PATTERNS } from "~/common/consts";

export const getLocationCreateSchema = (translate: Translate) =>
  object({
    name: string().min(2, translate("shared.validation.required")),
    pickupNotes: string()
      .min(0, translate("shared.validation.min"))
      .max(250, translate("shared.validation.max_dynamic", { value: 250 })),
    addressLine1: string().min(2, translate("shared.validation.required")),
    addressLine2: string(),
    city: string().min(2, translate("shared.validation.required")),
    province: string().min(2, translate("shared.validation.required")),
    country: string().min(2, translate("shared.validation.required")),
    postalCode: string().min(2, translate("shared.validation.required")),
    firstName: string()
      .min(1, translate("shared.validation.min"))
      .max(100, translate("shared.validation.max_dynamic", { value: 100 })),
    lastName: string()
      .min(1, translate("shared.validation.min"))
      .max(100, translate("shared.validation.max_dynamic", { value: 100 })),
    jobTitle: string().min(2, translate("shared.validation.required")),
    email: string()
      .email(
        translate("shared.validation.invalid_dynamic", {
          value: translate("shared.label.email"),
        }),
      )
      .max(120, translate("shared.validation.max_dynamic", { value: 120 }))
      .required(
        translate("shared.validation.required_dynamic", {
          value: translate("shared.label.email"),
        }),
      ),
    countryCode: string().matches(/\+1/),
    phone: string()
      .matches(
        REGEX_PATTERNS.phone_number,
        translate("shared.validation.invalid_dynamic", {
          value: translate("shared.label.phone"),
        }),
      )
      .max(10, translate("shared.validation.max_dynamic", { value: "10" }))
      .required(
        translate("shared.validation.required_dynamic", {
          value: translate("shared.label.phone"),
        }),
      ),
  });
