import {
  CreateResult,
  DeleteManyResult,
  GetListResult,
  GetOneResult,
  DeleteResult,
  DeleteParams,
  DataProvider,
  useDataProvider,
} from "react-admin";
import { FirebaseOptions } from "firebase/app";
import { UsersController } from "@swyft/domain/src/controllers/users";
import { User } from "@swyft/domain/src/types/users";
import { AppResource } from "~/config/resources";

/**
 * Data Provider for the "users" resource and domain.
 *
 * @see https://github.com/marmelab/react-admin/issues/5476#issuecomment-1165471324 - return types of the core functions cannot be type-restricted at the moment due to the way the generics were written
 */
export default (firebaseConfig: FirebaseOptions): UsersDataProvider => {
  const usersController = new UsersController(firebaseConfig);

  return {
    getList: async (resource, params): Promise<GetListResult> =>
      await usersController.getList(params),
    getOne: async (resource, params): Promise<GetOneResult> =>
      await usersController.getOne(params),
    getMany: (resource, params) => {
      throw new Error("Function not implemented.");
    },
    getManyReference: (resource, params) => {
      throw new Error("Function not implemented.");
    },
    create: async (resource, params): Promise<CreateResult> => {
      const userId = await usersController.create(params);

      return {
        data: {
          id: userId,
        },
      };
    },
    update: async (resource, params): Promise<CreateResult> => {
      const { data } = await usersController.update({
        ...params,
        id: String(params.id),
      });

      return {
        data,
      };
    },
    updateMany: (resource, params) => {
      throw new Error("Function not implemented.");
    },
    delete: async (resource, params): Promise<DeleteResult> => {
      const { data } = await usersController.deleteOne({
        id: String(params.id),
      });

      return { data: { ...params.previousData, id: data } };
    },
    deleteMany: async (resource, params): Promise<DeleteManyResult> => {
      const response = await usersController.deleteMany({
        ids: params.ids.map((id) => String(id)),
      });

      return response;
    },
    /**
     * Removes a user from a specified organization
     * @async
     * @param {string} resource must be provided if calling through react-admin's dataProvider
     * @param {DeleteParams} params contains the id of the user to remove, and the organization id/type in the meta property
     * @returns {{ data: string }} the id of the user that was removed
     */
    removeFromOrg: async (
      resource: string,
      params: DeleteParams,
    ): Promise<DeleteResult<User>> => {
      if (!params.meta?.org?.id || !params.meta?.org?.type) {
        return Promise.reject(
          new Error("Organization id and type must be provided!"),
        );
      }

      const { data } = await usersController.removeFromOrg({
        id: String(params.id),
        ...params.meta,
      });

      return { data: { ...params.previousData, id: data } };
    },
  };
};

export const useUsersDataProvider = (): [AppResource, UsersDataProvider] => [
  AppResource.Merchant,
  useDataProvider<UsersDataProvider>(),
];

export interface UsersDataProvider extends DataProvider {
  removeFromOrg: (
    resource: string,
    params: DeleteParams,
  ) => Promise<DeleteResult<User>>;
}
