import { FirebaseOptions, initializeApp } from "firebase/app";
import { getFunctions, HttpsCallable, httpsCallable } from "firebase/functions";
import { Firestore, getFirestore } from "firebase/firestore";
import { User } from "~/types/users";

import errorHandler from "../errorHandler";
import { get, list } from "../../models/users";
import {
  UserCreateParams,
  UserUpdateParams,
  SetPassword,
  UsersGetListParams,
  UsersRemoveFromOrgParams,
  UserUpdateRequest,
  UserCreateRequest,
} from "./types";
import { DeleteManyParams, DeleteOneParams, GetOneParams } from "../types";
import { getPaginationSlice } from "../shared";

type UserFunctions = {
  createUser: HttpsCallable<UserCreateRequest, string>;
  updateUser: HttpsCallable<UserUpdateRequest, { data: User }>;
  deleteUser: HttpsCallable<DeleteOneParams, void>;
  removeUserFromOrg: HttpsCallable<UsersRemoveFromOrgParams, void>;
  resendVerificationEmail: HttpsCallable;
  resetPassword: HttpsCallable;
  setNewPassword: HttpsCallable;
};

export default class UsersController {
  private db: Firestore;

  private functions: UserFunctions;

  /**
   * Creates a Users controller instance with provided config to setup Firebase app.
   * @param {FirebaseOptions} config Firebase configuration to initialize app.
   */
  constructor(config: FirebaseOptions) {
    const firebaseApp = initializeApp(config);
    const firebaseFunctions = getFunctions(firebaseApp);

    this.db = getFirestore(firebaseApp);

    this.functions = {
      createUser: httpsCallable(firebaseFunctions, "users-createUser"),
      updateUser: httpsCallable(firebaseFunctions, "users-updateUser"),
      deleteUser: httpsCallable(
        firebaseFunctions,
        "httpsOnCallUser-deleteUser",
      ),
      removeUserFromOrg: httpsCallable(firebaseFunctions, "users-removeUser"),
      resendVerificationEmail: httpsCallable(
        firebaseFunctions,
        "users-resendVerificationEmail",
      ),
      resetPassword: httpsCallable(firebaseFunctions, "users-resetPassword"),
      setNewPassword: httpsCallable(firebaseFunctions, "users-setNewPassword"),
    };
  }

  /**
   * Resends email to user for verifying their account.
   * @returns void if successful
   */
  resendVerificationEmail = async () => {
    try {
      await this.functions.resendVerificationEmail();
    } catch (err: unknown) {
      return errorHandler(err);
    }
  };

  /**
   * Resets password for account and sends email with password reset code.
   * @param {string} email Email of the account for resetting the password.
   * @returns void if successful
   */
  resetPassword = async (email: string) => {
    try {
      await this.functions.resetPassword({ email });
    } catch (err: unknown) {
      return errorHandler(err);
    }
  };

  /**
   * Sets new password for the user.
   * @param {SetPassword} newPasswordDetails User provided data for the new password.
   * @returns void if successful
   */
  setNewPassword = async (newPasswordDetails: SetPassword) => {
    try {
      await this.functions.setNewPassword(newPasswordDetails);
    } catch (err: unknown) {
      return errorHandler(err);
    }
  };

  /**
   * Gets a list of users
   * @param params.filter the filter parameters to narrow down the list of users
   * @param params.sort the sort parameters to sort the list of users
   * @param params.pagination the pagination parameters to page/limit the list of users
   * @returns a list of user objects, along with a total count of users satisfying all filters, irrespective of pagination
   */
  getList = async (params: UsersGetListParams = {}) => {
    const { startIndex, endIndex } = getPaginationSlice(params.pagination);
    const users = await list(this.db, {
      filter: params.filter,
      ...(params.sort && {
        sort: {
          ...params.sort,
          order: params.sort.order === "ASC" ? "asc" : "desc",
        },
      }),
    });

    return {
      data: users.slice(startIndex, endIndex),
      total: users.length,
    };
  };

  /**
   * Gets a single user's details
   * @param params.id the user's id
   * @returns the user object
   */
  getOne = async (params: GetOneParams) => {
    const user = await get(this.db, {
      id: params.id,
    });

    return { data: user };
  };

  /**
   * Creates a user
   * @async
   * @param params.data user details
   * @param params.meta organization association details
   * @returns the created user object's id
   */
  create = async (params: UserCreateParams): Promise<{ data: string }> => {
    try {
      const { data } = await this.functions.createUser({
        ...params.data,
        ...params.meta,
      });

      return { data };
    } catch (err: unknown) {
      return errorHandler(err);
    }
  };

  /**
   * Updates a user
   * @async
   * @param params user object properties to update
   * @returns the user object after it was updated
   */
  update = async (params: UserUpdateParams): Promise<{ data: User }> => {
    try {
      const { data } = await this.functions.updateUser({
        ...params.data,
        ...params.meta,
        id: params.id,
      });

      return data;
    } catch (err: unknown) {
      return errorHandler(err);
    }
  };

  /**
   * Delete multiple users at the same time
   * @async
   * @param {UsersDeleteManyParams} params contains an array of user ids
   * @returns the ids of the users that were deleted
   */
  deleteMany = async (
    params: DeleteManyParams,
  ): Promise<{ data?: string[] }> => {
    try {
      await Promise.all(
        params.ids.map((id) => this.functions.deleteUser({ id })),
      );

      return { data: params.ids };
    } catch (err: unknown) {
      return errorHandler(err);
    }
  };

  /**
   * Deletes a single user
   * @async
   * @param {UsersDeleteOneParams} params contains the id of the user to delete
   * @returns the id of the user that was deleted
   */
  deleteOne = async (params: DeleteOneParams): Promise<{ data: string }> => {
    try {
      await this.functions.deleteUser(params);
      return { data: params.id };
    } catch (err: unknown) {
      return errorHandler(err);
    }
  };

  /**
   * Removes a specified user, from the specified organization
   * @async
   * @param {UsersRemoveFromOrgParams} params contains the user id and the organization id/type
   * @returns the id of the user that got removed
   */
  removeFromOrg = async (
    params: UsersRemoveFromOrgParams,
  ): Promise<{ data: string }> => {
    try {
      await this.functions.removeUserFromOrg(params);
      return { data: params.id };
    } catch (err: unknown) {
      return errorHandler(err);
    }
  };
}
