import { useTranslate } from "react-admin";
import ActionAlert from "~/components/feedback/ActionAlert";

const CutOffTimeAlert = ({ merchantSlug, cutoffTime }: CutOffAlertProps) => {
  /**
   * Returns cutoff time message for merchant based on its associated pickup location settings.
   * For the holt toronto merchant slugs (evening and morning), returns hardcoded messages.
   * @returns string cutoff time message for the merchant
   */
  const getCutoffTimeMessage = (
    slug: string,
    cutoffTime: string,
  ): string | null => {
    if (!cutoffTime) {
      return null;
    }

    const translate = useTranslate();
    const holtEveningMerchantSlugs = [
      "holt-renfrew-bloor",
      "holt-renfrew-bloor-2",
      "holt-renfrew-yorkdale",
      "holt-renfrew-square-one",
    ];
    const holtMorningMerchantSlugs = [
      "holt-renfrew-bloor-morning-delivery",
      "holt-renfrew-bloor-2-morning-delivery",
      "holt-renfrew-yorkdale-morning-delivery",
      "holt-renfrew-square-one-morning-delivery",
    ];
    let message = translate("shipments.content.create.cutoff_alert", {
      cutoffTime,
    });

    if (holtEveningMerchantSlugs.includes(slug)) {
      message = translate(
        "shipments.content.create.holt_evening_cutoff_alert",
        { cutoffTime },
      );
    }

    if (holtMorningMerchantSlugs.includes(slug)) {
      message = translate("shipments.content.create.holt_morning_cutoff_alert");
    }

    return message;
  };

  const message = getCutoffTimeMessage(merchantSlug, cutoffTime);

  if (!message) {
    return null;
  }

  return (
    <ActionAlert
      alertSeverity="info"
      alertMessage={message}
      sx={{
        marginBottom: 2,
      }}
    />
  );
};

interface CutOffAlertProps {
  merchantSlug: string;
  cutoffTime: string;
}

export default CutOffTimeAlert;
