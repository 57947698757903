import { combineDataProviders } from "react-admin";

import firebaseConfig from "~/config/firebase";
import { AppResource } from "~/config/resources";
import getUsersDataProvider from "./users";
import getMerchantsDataProvider from "./merchants";
import getLocationsDataProvider from "./locations";
import getZonesDataProvider from "./zones";

const users = getUsersDataProvider(firebaseConfig);
const merchants = getMerchantsDataProvider(firebaseConfig);
const locations = getLocationsDataProvider(firebaseConfig);
const zones = getZonesDataProvider(firebaseConfig);

const dataProvider = combineDataProviders((resource) => {
  switch (resource) {
    case AppResource.User:
      return users;
    case AppResource.Zone:
      return zones;
    case AppResource.Merchant:
      return merchants;
    case AppResource.Location:
      return locations;
    default:
      throw new Error(
        `No data provider available for unknown resource: ${resource}`,
      );
  }
});

export default dataProvider;
