import { createContext, PropsWithChildren, useContext } from "react";
import { useGetOne } from "react-admin";
import { Merchant } from "@swyft/domain/src/types/merchants";
import { User } from "@swyft/domain/src/types/users";

import { useGetIdentity } from "../services/auth/hooks";
import { UserIdentity } from "~/services/auth/authProvider";
import { AppResource } from "~/config/resources";
import Loading from "~/components/feedback/Loading";

interface AuthenticatedContextType {
  identity?: UserIdentity;
  user?: User;
  merchant?: Merchant;
}

const AuthenticatedContext = createContext<AuthenticatedContextType | null>({});

export const useAuthenticatedContext = () => {
  const context = useContext(AuthenticatedContext);

  if (context === null) {
    throw new Error("Must be called within AuthenticatedContextProvider.");
  }

  return context;
};

export function AuthenticatedContextProvider({
  children,
}: PropsWithChildren<{}>) {
  // fetch authenticated user identity
  const { identity, isLoading: loadingIdentity } = useGetIdentity();

  // fetch user
  const { data: user, isLoading: loadingUser } = useGetOne<User>(
    AppResource.User,
    { id: identity?.id ?? "" },
    { enabled: !!identity?.id },
  );

  // fetch merchant
  const { data: merchant, isLoading: loadingMerchant } = useGetOne<Merchant>(
    AppResource.Merchant,
    { id: identity?.organizationId ?? user?.organizationId ?? "" },
    {
      enabled: !!identity?.organizationId || !!user?.organizationId,
    },
  );

  if (loadingIdentity || loadingUser || loadingMerchant) {
    return <Loading loadingPrimary="shared.message.loading.account" />;
  }

  return (
    <AuthenticatedContext.Provider
      value={{
        identity,
        user,
        merchant,
      }}
    >
      {children}
    </AuthenticatedContext.Provider>
  );
}
