import {
  collection,
  doc,
  DocumentData,
  Firestore,
  FirestoreDataConverter,
  getDoc,
  getDocs,
  orderBy,
  OrderByDirection,
  query,
  QueryConstraint,
  where,
  WithFieldValue,
} from "firebase/firestore";
import { User, UserStatus, UserRole } from "../types/users";
import { OrganizationType } from "../types/organizations";

const usersConverter: FirestoreDataConverter<User> = {
  fromFirestore(snapshot) {
    return snapshot.data() as User; // TODO: map appropriate values
  },
  toFirestore(model: WithFieldValue<User>): DocumentData {
    return model; // TODO: map appropriate values
  },
};

const getUsersCollection = (db: Firestore) =>
  collection(db, "users").withConverter(usersConverter);

export class UserNotFoundError extends Error {
  constructor(id: string) {
    super(`No user found for id ${id}`);
  }
}

export const get = async (
  db: Firestore,
  params: {
    id: string;
  },
) => {
  const userDocRef = doc(getUsersCollection(db), params.id);
  const usersDocsSnapshot = await getDoc(userDocRef);

  if (usersDocsSnapshot.exists()) {
    return usersDocsSnapshot.data();
  }

  throw new UserNotFoundError(params.id);
};

export const list = async (
  db: Firestore,
  params: {
    filter?: {
      org?: { id: string; type: OrganizationType };
      roles?: UserRole[];
      status?: UserStatus;
      isDeleted?: boolean;
    };
    sort?: {
      field: string;
      order: OrderByDirection;
    };
  } = {},
) => {
  const queryConstraints: QueryConstraint[] = [];
  const { filter, sort } = params;

  if (filter?.org) {
    switch (filter.org.type) {
      case OrganizationType.Merchant:
        queryConstraints.push(where("merchantId", "==", filter.org.id));
        break;
      case OrganizationType.DeliveryServicePartner:
        queryConstraints.push(where("dspId", "==", filter.org.id));
        break;
      default:
        queryConstraints.push(where("organizationId", "==", filter.org.id));
    }
  }

  if (filter?.status) {
    queryConstraints.push(where("status", "==", filter.status));
  }

  if (filter?.roles) {
    queryConstraints.push(where("role", "in", filter.roles));
  }

  // if we want deleted users
  // NOTE: if we want to filter by users NOT deleted, Firebase can't do this. Instead, we'll have to manually filter the results (see end)
  if (filter?.isDeleted) {
    queryConstraints.push(where("deletedAt", "!=", null));
  }

  if (sort?.field) {
    queryConstraints.push(orderBy(sort.field, sort.order));
  }

  const usersQuery = query(getUsersCollection(db), ...queryConstraints);
  const usersDocsSnapshot = await getDocs(usersQuery);

  return usersDocsSnapshot.docs.reduce<User[]>(
    (usersDocs, usersDocSnapshot) => {
      const data = usersDocSnapshot.data();

      // if we don't want deleted users, but this record is soft-deleted, ignore it
      if (!filter?.isDeleted && data.deletedAt) {
        return usersDocs;
      }

      usersDocs.push(data);

      return usersDocs;
    },
    [],
  );
};
