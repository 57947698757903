import { CountryISO2 } from "./common";
import { ServiceType } from "./labels";

export enum ZoneStatus {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
  PENDING = "PENDING",
}

export interface Zone {
  id: string;
  name: string;
  postalCodes: string[];
  serviceLevel: ServiceType;
  country: CountryISO2;
  coverageFileUrl: string;
  trailing90OTDUrl: string;
  createdAt: Date;
  updatedAt: Date;
  polygon?: string | null;
  active?: boolean;
}
