export enum UserRole {
  ADMIN = "admin",
  MEMBER = "member",
}

export enum UserStatus {
  ACTIVE = "ACTIVE", // User can use portal, default status for users
  INACTIVE = "INACTIVE", // User cannot use portal, org admins can change user status to inactive
}

// REVIEW: legacy. To be deprecated.
export enum UserType {
  MERCHANT = "MERCHANT",
  MERCHANT_GROUP = "MERCHANT_GROUP",
  WAREHOUSE = "WAREHOUSE",
  GATSBY = "GATSBY",
  INTERNAL = "INTERNAL",
  DRIVER = "DRIVER",
  DRIVER_UNMANAGED = "DRIVER_UNMANAGED",
  WAREHOUSE_UNMANAGED = "WAREHOUSE_UNMANAGED",
}

export interface User {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  phone?: string;
  countryCode?: string;
  status: UserStatus;
  role: UserRole;
  organizationId: string;
  isOnboardingComplete: boolean;
  merchantId: string; // REVIEW: for legacy support
  type?: UserType; // REVIEW: for legacy support

  /** Bookkeeping */
  createdAt: Date;
  updatedAt: Date;
  deletedAt?: Date;
}
