import { Create, Confirm, useTranslate, useNotify } from "react-admin";
import {
  TextField,
  Toolbar,
  Grid,
  Button,
  Typography,
  Link,
  LoadingButton,
} from "@helo/ui";
import { MerchantRequestQuoteParams } from "@swyft/domain/src/controllers/merchant/types";
import type { CreateProps } from "react-admin";
import {
  WizardForm,
  WizardFormStep,
  WizardToolbarProps,
  useWizardFormContext,
} from "@react-admin/ra-form-layout";
import { useFormContext, UseFormReturn, useFormState } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { IZone } from "@swyft/swyft-common";
import { getDownloadURL, ref } from "firebase/storage";
import { nanoid } from "nanoid";

import { Routes } from "~/config/Routes";
import { firebaseStorage } from "~/services/firebase";
import { CacheFileReference } from "~/common/types";
import FileDrop from "~/components/inputs/FileDrop";
import { GlobalAlertsState } from "~/components/feedback/GlobalAlerts";
import {
  RequestQuoteValidationSchema,
  RequestQuoteSchema,
} from "~/common/validators/merchant/RequestQuoteSchema";
import ControlledAutocomplete from "~/components/inputs/ControlledAutocomplete";
import { useAuthenticatedContext } from "~/components/AuthenticatedContext";
import { CountryOptions, STORAGE_BUCKET_FOLDERS } from "~/common/consts";
import AppViewLayout from "~/layouts/app/AppViewLayout";
import { useMerchantsDataProvider } from "~/services/data/merchants";

const formResolver = yupResolver(RequestQuoteValidationSchema);
const defaultValues: RequestQuoteSchema = {
  shipping_volume_file: null,
  location: {
    name: "",
    notes: "",
    address: {
      line_one: "",
      line_two: "",
      city: "",
      sub_division: "",
      country: "",
      postal_zip: "",
    },
    contact: {
      first_name: "",
      last_name: "",
      job_title: "",
      phone: "",
      email: "",
    },
  },
};

const WizardFormToolbarWithCancel = (
  props: WizardToolbarProps & {
    initialStep: number;
    cancelTitle?: string;
    submitTriggerLabel?: string;
    handleCancel?: (event: React.MouseEvent<HTMLElement>) => void;
    handleSubmitSuccess?: (data: any) => Promise<any>;
    handleSubmitError?: (errors: any) => void;
    isStepValid?: (currentStep: number, formContext: UseFormReturn) => boolean;
  },
) => {
  const {
    initialStep,
    cancelTitle,
    submitTriggerLabel = "shared.action.submit",
    handleCancel: handleCancelProp,
    handleSubmitSuccess: handleSubmitSuccessProp,
    handleSubmitError: handleSubmitErrorProp,
    isStepValid,
  } = props;
  const translate = useTranslate();
  const navigate = useNavigate();
  const { isDirty, isValid, isValidating, isSubmitting } = useFormState();
  const formContext = useFormContext();
  const { reset, handleSubmit } = formContext;
  const {
    hasPreviousStep,
    hasNextStep,
    goToPreviousStep,
    goToNextStep,
    currentStep,
    goToStep,
  } = useWizardFormContext();
  const disabled = isValidating || isSubmitting;
  const [isInitComplete, setIsInitComplete] = useState(false);
  const [isCancelConfirmOpen, setIsCancelConfirmOpen] = useState(false);

  // navigate to the step specified once, if set
  useEffect(() => {
    if (initialStep) {
      goToStep(initialStep);
    }
    setIsInitComplete(true);
  }, []);

  useEffect(() => {
    // react-admin's WizardForm sets the step to 0 initially, regardless of
    // the initialStep navigation above. The following makes sure
    // the checks in this effect only happen once the correct step is navigated
    // to, if specified.
    if (!isInitComplete) {
      return;
    }

    // step 1 should send the user back to the coverage map
    if (currentStep === 0) {
      navigate(Routes.Zones);
    }
  }, [currentStep]);

  const handleNextClick = (event: React.MouseEvent<HTMLElement>): void => {
    event.preventDefault();
    goToNextStep();
  };

  const handlePreviousClick = (event: React.MouseEvent<HTMLElement>): void => {
    event.preventDefault();
    goToPreviousStep();
  };

  const handleCancelConfirmClose = () => setIsCancelConfirmOpen(false);
  const handleCancelConfirmOpen = () => setIsCancelConfirmOpen(true);

  const handleCancel = (event: React.MouseEvent<HTMLElement>): void => {
    reset();
    setIsCancelConfirmOpen(false);

    if (handleCancelProp) {
      handleCancelProp(event);
    }
  };

  // returning a promise here, so react-hook-form handles full submission flow asynchronously
  // instead of setting isSubmitting to true immediately after submit is triggered
  const handleSubmitSuccess = async (data: any): Promise<void> => {
    if (handleSubmitSuccessProp) {
      await handleSubmitSuccessProp(data);
    }
  };

  const handleSubmitError = (errors: any): void => {
    if (handleSubmitErrorProp) {
      handleSubmitErrorProp(errors);
    }
  };

  return (
    <Toolbar sx={{ px: 2 }} disableGutters>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item>
          {hasPreviousStep ? (
            <Button
              variant="contained"
              color="neutral"
              onClick={handlePreviousClick}
              disableElevation
            >
              {translate("ra-form-layout.action.previous")}
            </Button>
          ) : null}
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="neutral"
            onClick={handleCancelConfirmOpen}
            disableElevation
            sx={{ mr: 1 }}
            disabled={disabled}
          >
            {translate("ra.action.cancel")}
          </Button>
          {hasNextStep ? (
            <Button
              variant="contained"
              color="primary"
              onClick={handleNextClick}
              disabled={
                (disabled && !hasNextStep) ||
                (isStepValid && !isStepValid(currentStep, formContext))
              }
              disableElevation
            >
              {translate("ra-form-layout.action.next")}
            </Button>
          ) : (
            <LoadingButton
              variant="contained"
              color="primary"
              loading={isSubmitting}
              disabled={disabled || !isValid}
              disableElevation
              onClick={handleSubmit(handleSubmitSuccess, handleSubmitError)}
              type="submit"
            >
              {translate(submitTriggerLabel)}
            </LoadingButton>
          )}
        </Grid>
      </Grid>
      <Confirm
        isOpen={isCancelConfirmOpen}
        title={cancelTitle || "ra.action.cancel"}
        content="shared.message.cancel.confirm_desc"
        onConfirm={handleCancel}
        onClose={handleCancelConfirmClose}
      ></Confirm>
    </Toolbar>
  );
};

const AddPickupLocationForm = () => {
  const {
    register,
    formState: { errors },
    control,
  } = useFormContext();
  const translate = useTranslate();

  return (
    <Grid container spacing={2}>
      <Grid item sm={12} xs={12}>
        <TextField
          label={`${translate("resources.locations.fields.name")}*`}
          fullWidth
          {...register("location.name")}
          error={!!errors?.location?.name}
          helperText={translate(errors?.location?.name?.message || "")}
        />
      </Grid>
      <Grid item sm={12} xs={12}>
        <TextField
          label={`${translate("resources.locations.fields.pickupNotes")}`}
          multiline
          rows={4}
          fullWidth
          {...register("location.notes")}
        />
      </Grid>
      <Grid item sm={12} xs={12}>
        <Typography variant="subtitle1" sx={{ mt: 3, fontWeight: 700 }}>
          {translate("shared.content.header.addr_info")}
        </Typography>
      </Grid>
      <Grid item sm={6} xs={12}>
        <TextField
          label={`${translate(
            "resources.locations.fields.contact.address.line1",
          )}*`}
          fullWidth
          {...register("location.address.line_one")}
          error={!!errors?.location?.address?.line_one}
          helperText={translate(
            errors?.location?.address?.line_one?.message || "",
          )}
        />
      </Grid>
      <Grid item sm={6} xs={12}>
        <TextField
          label={translate("resources.locations.fields.contact.address.line2")}
          fullWidth
          {...register("location.address.line_two")}
        />
      </Grid>
      <Grid item sm={6} xs={12}>
        <TextField
          label={`${translate(
            "resources.locations.fields.contact.address.city",
          )}*`}
          fullWidth
          {...register("location.address.city")}
          error={!!errors?.location?.address?.city}
          helperText={translate(errors?.location?.address?.city?.message || "")}
        />
      </Grid>
      <Grid item sm={6} xs={12}>
        <TextField
          label={`${translate(
            "resources.locations.fields.contact.address.province",
          )}*`}
          fullWidth
          {...register("location.address.sub_division")}
          error={!!errors?.location?.address?.sub_division}
          helperText={translate(
            errors?.location?.address?.sub_division?.message || "",
          )}
        />
      </Grid>
      <Grid item sm={6} xs={12}>
        <ControlledAutocomplete
          control={control}
          options={CountryOptions}
          name="location.address.country"
          error={!!errors?.location?.address?.country}
          helperText={translate(
            errors?.location?.address?.country?.message || "",
          )}
          label={translate(
            "resources.locations.fields.contact.address.country",
          )}
          placeholder={translate("shared.form.placeholder.select_country")}
        />
      </Grid>
      <Grid item sm={6} xs={12}>
        <TextField
          label={`${translate(
            "resources.locations.fields.contact.address.postalCode",
          )}*`}
          fullWidth
          {...register("location.address.postal_zip")}
          error={!!errors?.location?.address?.postal_zip}
          helperText={translate(
            errors?.location?.address?.postal_zip?.message || "",
          )}
        />
      </Grid>
      <Grid item sm={12} xs={12}>
        <Typography variant="subtitle1" sx={{ mt: 3, fontWeight: 700 }}>
          {translate("shared.content.header.contact_info")}
        </Typography>
      </Grid>
      <Grid item sm={6} xs={12}>
        <TextField
          label={`${translate(
            "resources.locations.fields.contact.firstName",
          )}*`}
          fullWidth
          {...register("location.contact.first_name")}
          error={!!errors?.location?.contact?.first_name}
          helperText={translate(
            errors?.location?.contact?.first_name?.message || "",
          )}
        />
      </Grid>
      <Grid item sm={6} xs={12}>
        <TextField
          label={`${translate("resources.locations.fields.contact.lastName")}*`}
          fullWidth
          {...register("location.contact.last_name")}
          error={!!errors?.location?.contact?.last_name}
          helperText={translate(
            errors?.location?.contact?.last_name?.message || "",
          )}
        />
      </Grid>
      <Grid item sm={6} xs={12}>
        <TextField
          label={`${translate("resources.locations.fields.contact.title")}*`}
          fullWidth
          {...register("location.contact.job_title")}
          error={!!errors?.location?.contact?.job_title}
          helperText={translate(
            errors?.location?.contact?.job_title?.message || "",
          )}
        />
      </Grid>
      <Grid item sm={6} xs={12}>
        <TextField
          label={`${translate("resources.locations.fields.contact.phone")}*`}
          type="tel"
          fullWidth
          {...register("location.contact.phone")}
          error={!!errors?.location?.contact?.phone}
          helperText={translate(
            errors?.location?.contact?.phone?.message || "",
          )}
        />
      </Grid>
      <Grid item sm={12} xs={12}>
        <TextField
          label={`${translate("resources.locations.fields.contact.email")}*`}
          type="email"
          fullWidth
          {...register("location.contact.email")}
          error={!!errors?.location?.contact?.email}
          helperText={translate(
            errors?.location?.contact?.email?.message || "",
          )}
        />
      </Grid>
    </Grid>
  );
};

const ShippingVolumeForm = () => {
  const translate = useTranslate();
  const shippingVolumeFileName = "shipping_volume_file";
  const acceptedFileTypes = {
    "text/*": [".csv"],
  }; // as specified in https://react-dropzone.netlify.app/#section-accepting-specific-file-types
  const maxFileSize = 25 * 1024 ** 2; // MB as bytes
  const [shippingVolumeTemplateLink, setShippingVolumeTemplateLink] =
    useState<string>();
  const { control, trigger } = useFormContext();

  // manually triggering validation for this step to lock step navigation
  useEffect(() => {
    trigger(shippingVolumeFileName);
  }, []);

  useEffect(() => {
    const getFileLink = async () => {
      const path = `${process.env.REACT_APP_FIREBASE_PUBLIC_BUCKET}/templates/Shipping Data Collection Template - Template to paste data.csv`;
      const fileRef = ref(firebaseStorage, path);

      return await getDownloadURL(fileRef);
    };

    const configureTemplateFileLink = async () => {
      const cachedFileData = localStorage.getItem("templates.shipping_volume");

      if (cachedFileData) {
        const parsedData = JSON.parse(cachedFileData) as CacheFileReference;

        if (parsedData.expiry < Date.now()) {
          setShippingVolumeTemplateLink(parsedData.link);
          return;
        }
      }

      const fileLink = await getFileLink();

      localStorage.setItem(
        "templates.shipping_volume",
        JSON.stringify({
          link: fileLink,
          expiry: Date.now() + 1000 * 60 * 60, // 60 min cache bust
        } as CacheFileReference),
      );
      setShippingVolumeTemplateLink(fileLink);
    };

    configureTemplateFileLink();
  }, []);

  return (
    <>
      <Typography variant="body1" sx={{ mb: 1.71 }}>
        {translate("merchant.request_quote.form.volume_file_step_desc")}
      </Typography>
      <FileDrop
        name={shippingVolumeFileName}
        control={control}
        accept={acceptedFileTypes}
        maxSize={maxFileSize}
        getUploadToStoragePath={(file: File) =>
          `${STORAGE_BUCKET_FOLDERS.merchant_request_quote}/${nanoid()}/${
            file.name
          }`
        }
      />
      <Link
        sx={{ fontSize: "1.07rem", mt: "2rem", display: "inline-block" }}
        variant="body2"
        underline="hover"
        href={shippingVolumeTemplateLink}
      >
        {translate("shared.action.download_template_csv")}
      </Link>
    </>
  );
};

export default function ZonesRequestQuote(props: ZonesRequestQuoteProps) {
  const t = useTranslate();
  const navigate = useNavigate();
  const location = useLocation();
  const notify = useNotify();
  const { merchant } = useAuthenticatedContext();
  const [resource, dataProvider] = useMerchantsDataProvider();

  const handleSubmitSuccess = (data: RequestQuoteSchema) => {
    // if the merchant or zone id isn't set
    if (!merchant?.id || !zone?.id) {
      notify(t("shared.message.submit_request.invalid"), {
        type: "warning",
      });

      return Promise.reject();
    }

    // add the required merchantId and zoneId for the domain layer call
    const shapedData: MerchantRequestQuoteParams = {
      merchantId: merchant.id,
      zoneId: zone.id,
      shippingVolumeFileURL: data.shipping_volume_file?.storagePreviewURL ?? "",
      location: {
        name: data.location.name,
        notes: data.location.notes,
        address: {
          city: data.location.address.city,
          country: data.location.address.country,
          lineOne: data.location.address.line_one,
          lineTwo: data.location.address.line_two,
          postalZip: data.location.address.postal_zip,
          subDivision: data.location.address.sub_division,
        },
        contact: {
          firstName: data.location.contact.first_name,
          lastName: data.location.contact.last_name,
          jobTitle: data.location.contact.job_title,
          phone: data.location.contact.phone,
          email: data.location.contact.email,
        },
      },
    };

    return dataProvider
      .requestZoneActivation(resource, shapedData)
      .then(() => {
        // navigate back to the coverage map, with a popup confirmation
        navigate(Routes.Zones, {
          state: {
            notifyDialog: {
              title: t("shared.message.submit_request.ok"),
              content: t("merchant.request_quote.message.success_confirm_body"),
              isOpen: true,
            },
          } as GlobalAlertsState,
        });
      })
      .catch((err: Error) => {
        // inform the user
        notify(`${t("shared.message.submit_request.fail")}: (${err.message})`, {
          type: "warning",
        });
      });
  };
  const handleSubmitError = (errors: any) => {
    notify(`${t("shared.message.submit_request.validation_fail")}`, {
      type: "warning",
    });
  };
  const handleCancel = () => {
    navigate(Routes.Zones);
  };
  const checkIfStepValid = (
    currentStep: number,
    formContext: UseFormReturn,
  ) => {
    const { getFieldState } = formContext;

    if (currentStep === 1) {
      const { error } = getFieldState("shipping_volume_file");

      // if there's no error
      return !error;
    }

    return true;
  };

  if (!location.state) {
    navigate(Routes.Zones);
    return <></>;
  }

  // get the zone and initialStep information from routing state
  const { zone, step = 1 } = location.state as { zone: IZone; step?: number };

  if (!zone) {
    navigate(Routes.Zones);
    return <></>;
  }

  const cancelConfirmTitle = `${t("ra.action.cancel")} ${t(
    "merchant.request_quote.message.cancel_confirm_title",
    { zone: zone?.name },
  )}`;

  return (
    <Create
      {...props}
      sx={{
        width: "60%",
        margin: "0 auto",
        "& .RaCreate-main": {
          marginBottom: 8,
        },
        "& .RaCreate-card": {
          borderRadius: "none",
          boxShadow: "none",
        },
      }}
    >
      <AppViewLayout
        variant="centered"
        title={t("merchant.request_quote.form.title", { zone: zone?.name })}
      >
        <WizardForm
          resolver={formResolver}
          defaultValues={defaultValues}
          toolbar={
            <WizardFormToolbarWithCancel
              initialStep={step}
              cancelTitle={cancelConfirmTitle}
              submitTriggerLabel="shared.action.submit_request"
              handleCancel={handleCancel}
              handleSubmitSuccess={handleSubmitSuccess}
              handleSubmitError={handleSubmitError}
              isStepValid={checkIfStepValid}
            />
          }
        >
          <WizardFormStep label={t("zones.create.form.step_1")}>
            {/*
            NOTE: not an actual step. The toolbar takes care of navigation
            back to the coverage map when this step is triggered.
          */}
          </WizardFormStep>
          {/* Upload Shipping Volume */}
          <WizardFormStep label={t("zones.create.form.step_2")}>
            <ShippingVolumeForm />
          </WizardFormStep>
          {/* Pick-up Location */}
          <WizardFormStep label={t("zones.create.form.step_3")}>
            <AddPickupLocationForm />
          </WizardFormStep>
        </WizardForm>
      </AppViewLayout>
    </Create>
  );
}

interface ZonesRequestQuoteProps extends CreateProps {}
