import {
  collection,
  doc,
  DocumentData,
  Firestore,
  FirestoreDataConverter,
  getDocs,
  WithFieldValue,
  query,
  where,
} from "firebase/firestore";
import { MerchantZone } from "../types/merchants";

const merchantZonesConverter: FirestoreDataConverter<MerchantZone> = {
  fromFirestore(snapshot) {
    return snapshot.data() as MerchantZone; // TODO: map appropriate values
  },
  toFirestore(model: WithFieldValue<MerchantZone>): DocumentData {
    return model; // TODO: map appropriate values
  },
};

const getMerchantZonesCollection = (db: Firestore, merchantId: String) =>
  collection(db, `merchants/${merchantId}/zones`).withConverter(
    merchantZonesConverter,
  );

export class RatesNotFoundError extends Error {
  constructor(merchantId: string, zoneId: string) {
    super(
      `No rates found for mecrchant id ${merchantId} for zone id ${zoneId}`,
    );
  }
}

export const list = async (
  db: Firestore,
  params: {
    id: string;
  },
) => {
  const merchantZonesDocsSnapshot = await getDocs(
    getMerchantZonesCollection(db, params.id),
  );
  return merchantZonesDocsSnapshot.docs.map((merchantZonesDocSnapshot) =>
    merchantZonesDocSnapshot.data(),
  );
};

// v2 rate per merchant-zone implementation
export const getRates = async (
  db: Firestore,
  params: {
    merchantId: string;
    zoneId: string;
  },
) => {
  const MerchantZonesCollectionRef = collection(
    db,
    `merchants/${params.merchantId}/zones`,
  );
  const zoneRef = doc(db, "zones", params.zoneId);
  const q = query(MerchantZonesCollectionRef, where("zone", "==", zoneRef));
  const MerchantZonesQuerySnapshot = await getDocs(q);

  // the number of docs returned should never be greater than 1
  if (MerchantZonesQuerySnapshot.docs.map((snapshot) => snapshot.data())[0]) {
    return MerchantZonesQuerySnapshot.docs.map((snapshot) => snapshot.data())[0]
      .rate;
  }
  throw new RatesNotFoundError(params.merchantId, params.zoneId);
};
