import { TextField, StandardTextFieldProps } from "../..";

interface PhoneNumberFieldProps extends StandardTextFieldProps {}

const PhoneNumberField = ({
  error = false,
  ...others
}: PhoneNumberFieldProps) => {
  return <TextField error={error} fullWidth type="tel" {...others} />;
};

export default PhoneNumberField;
