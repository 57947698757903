export enum OrganizationType {
  Merchant = "merchant",
  DeliveryServicePartner = "dsp",
}

export interface Organization {
  id: string;
  type: OrganizationType;
}

export interface OrganizationSpec {
  org: Organization;
}

export interface OrganizationSpecSimple {
  org: {
    id: string;
  };
}
