import { useEffect, useState } from "react";
import { useGetIdentity as useRaGetIdentity, useRedirect } from "react-admin";
import { useAuthenticated } from "@react-admin/ra-rbac";
import {
  onSnapshot,
  doc,
  DocumentSnapshot,
  FirestoreError,
  DocumentReference,
} from "firebase/firestore";

import { UserIdentity } from "~/services/auth/authProvider";
import { useAuthenticatedContext } from "~/components/AuthenticatedContext";
import { Routes } from "~/config/Routes";
import { firebaseDb } from "~/services/firebase";
import { User } from "@swyft/domain/src/types/users";

interface State {
  isLoading: boolean;
  identity?: UserIdentity;
  error?: any;
}

/**
 * Wrapper around react-admin's useGetIdentity to override type for the identity object
 * @returns the same State object as the react-admin's hook, except with identity typed with our custom type
 */
export const useGetIdentity = (): State => {
  const { isLoading, error, identity } = useRaGetIdentity();

  return {
    identity: identity as UserIdentity,
    error,
    isLoading,
  };
};

/**
 * Check if the user has verified their email.
 * If not, redirect them to the view that can help them do it.
 */
export const useIsVerified = () => {
  const { identity } = useAuthenticatedContext();
  const redirect = useRedirect();

  useEffect(() => {
    if (!!identity && !identity.emailVerified) {
      redirect(Routes.UnverifiedEmail);
    }
  }, [identity?.emailVerified]);
};

/**
 * Check if the current logged-in user has an organization associated with their account.
 * If they're not associated with an organization, they are redirected to the view that can help the user satisfy the check.
 * @param canCheck set to false if the user details in the db shouldn't be checked. default is true.
 */
export const useHasOrgAssociation = (canCheck: boolean = true) => {
  const { identity, user } = useAuthenticatedContext();
  const redirect = useRedirect();

  useEffect(() => {
    if (!canCheck) {
      return;
    }

    // if neither the identity nor the user object have the org association, redirect back to onboarding
    if (!identity?.organizationId && !user?.organizationId) {
      redirect(Routes.FTUX);
    }
  }, [identity?.organizationId, user?.organizationId, canCheck]);
};

/**
 * Check if the current logged-in user has an organization associated with their account, immediately, or after a change to the user object
 * If they're not associated with an organization, they are redirected to the view that can help the user satisfy the check.
 * @param shouldWaitUntilUserUpdate set to true if the check should happen after the user object is updated with an org association
 * @returns a state object with details. isLoading - true if any of the dependencies are still loading, or if shouldWaitUntilUserUpdate param
 * is set to true and we're waiting for the user update to happen.
 */
export const useHasOrgAssociationOnUserUpdate = (
  shouldWaitUntilUserUpdate?: boolean,
) => {
  const { identity, isLoading: isIdentityLoading } = useGetIdentity();
  const [canCheckAsync, setCanCheckAsync] = useState<boolean | null>(null);
  let canCheck = !shouldWaitUntilUserUpdate; // don't check immediately if shouldWaitUntilUserUpdate is set to true

  useOnUserUpdate(
    identity?.id ?? null,
    {
      next: () => {
        // assuming the user object has been updated correctly, we can proceed with the check
        setCanCheckAsync(true);
      },
      error: (error) => {
        console.error(error);
        setCanCheckAsync(true);
      },
    },
    !canCheck,
  );
  useHasOrgAssociation(canCheckAsync !== null ? canCheckAsync : canCheck);

  return {
    isLoading: isIdentityLoading || (!canCheck && !canCheckAsync),
  };
};

/**
 * Attach a Firestore listener to the logged-in user's document, and fire an observer whenever it changes
 * @param userId the id of the user document in Firestore to attach the listener for
 * @param observer provide two methods: one to run when changes happen and two, when an error happens
 * @param shouldSubscribe should the listener be attached at all?
 */
export const useOnUserUpdate = (
  userId: string | null,
  observer: {
    next?: (snapshot: DocumentSnapshot<User>) => void;
    error?: (error: FirestoreError) => void;
    complete?: () => void;
  },
  shouldSubscribe: boolean = true,
) => {
  useEffect(() => {
    if (!userId || !shouldSubscribe) {
      return;
    }

    const unsub = onSnapshot<User>(
      doc(firebaseDb, "users", userId) as DocumentReference<User>,
      observer,
    );

    return () => unsub();
  }, [userId, shouldSubscribe]);
};
