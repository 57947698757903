import { PaginationPayload } from "./types";

/* eslint-disable import/prefer-default-export */
export const DEFAULT_PER_PAGE = 25; // number of results per page
export const DEFAULT_PAGE = 1; // the page
export const DEFAULT_PAGINATION_CONFIG = {
  page: DEFAULT_PAGE,
  perPage: DEFAULT_PER_PAGE,
};

/**
 * Get the start index and the end index to slice a list of items, given a page number (index-1 based), and the number of items per page.
 * @param pagination the pagination payload from a inbound query to a controller
 * @returns the start index to slice at (inclusive) and the end index to end the slicing at (exclusive)
 */
export const getPaginationSlice = (pagination?: PaginationPayload) => {
  const actualPagination = Object.assign(
    DEFAULT_PAGINATION_CONFIG,
    pagination ?? {},
  );
  const offset = (actualPagination.page - 1) * actualPagination.perPage;

  return {
    startIndex: offset,
    endIndex: offset + actualPagination.perPage,
  };
};
