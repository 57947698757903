import { styled } from "@helo/ui";
import { useTranslate } from "react-admin";

const DataStudioEmbed = ({
  organizationId,
  isOrgActive = false,
  placeholderImgSrc,
  title,
  description,
  summaryType,
}: DataStudioEmbedProps) => {
  const translate = useTranslate();
  const FINANCIAL_SUMMARY_URL = `https://datastudio.google.com/embed/u/0/reporting/f075d99d-f058-41cf-8836-ffb90884ef0e/page/p_stqfnutfsc?params=%7B%22df34%22:%22include%25EE%2580%25800%25EE%2580%2580IN%25EE%2580%2580${organizationId}%22%7D`;
  const DASHBOARD_SUMMARY_URL = `https://datastudio.google.com/embed/u/0/reporting/f075d99d-f058-41cf-8836-ffb90884ef0e/page/7zDmC?params=%7B%22df33%22:%22include%25EE%2580%25800%25EE%2580%2580IN%25EE%2580%2580${organizationId}%22%7D`;

  return (
    <>
      {isOrgActive ? (
        <SummaryContainer
          src={
            summaryType === "financial"
              ? FINANCIAL_SUMMARY_URL
              : DASHBOARD_SUMMARY_URL
          }
          frameBorder="0"
          allowFullScreen
          id="financialSummaryIFrame"
        />
      ) : (
        <ContainerInactive
          src={placeholderImgSrc}
          alt={translate("shared.content.alt.placeholder", {
            value:
              summaryType === "financial"
                ? translate("pages.reports.title")
                : translate("pages.dashboard.title"),
          })}
        />
      )}
    </>
  );
};

const SummaryContainer = styled("iframe")(({ theme }) => ({
  height: "inherit",
  width: "100%",
  margin: "0 auto",
  maxWidth: theme.breakpoints.values.lg,
}));

const ContainerInactive = styled("img")({
  height: "auto",
  width: "100%",
  display: "block",
  margin: "20px auto",
});

interface DataStudioEmbedProps {
  organizationId?: string;
  isOrgActive?: boolean;
  placeholderImgSrc: string;
  title: string;
  description?: string;
  summaryType?: "financial";
}

export default DataStudioEmbed;
