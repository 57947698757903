import { useTranslate, useLocaleState } from "react-admin";
import { useMemo } from "react";
import { currencyFormatter } from "~/common/helpers";
import {
  Box,
  Typography,
  Paper,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@helo/ui";
import { DisplayRateEntries } from "~/services/data/types";
import { PackageSize } from "@swyft/domain/src/types/labels";

interface ServiceLevelRatesProps {
  title: string | undefined;
  serviceLevel: DisplayRateEntries[] | undefined;
  currency: string;
  hasVolumeTiers: boolean;
}

const ServiceLevelRates = ({
  title,
  serviceLevel,
  currency,
  hasVolumeTiers,
}: ServiceLevelRatesProps) => {
  const translate = useTranslate();
  const [locale] = useLocaleState();
  const ratePlaceholder = "-";

  // retrieve rate for specific package size to display in correct column
  const getRate = (
    entries: DisplayRateEntries[],
    size: PackageSize,
  ): string => {
    const item = entries.find((e) => e.packageSize === size);
    const rate = item?.rate;
    return rate ? currencyFormatter(locale, rate, currency) : ratePlaceholder;
  };

  const createVolumeTiers = (entries: DisplayRateEntries[]): any => {
    const tierSet = new Set<string>();
    const tiers = new Map();

    entries.forEach((e) => {
      const tierName = `${e.minVolume}-${e.maxVolume}`;
      tierSet.add(tierName);
      if (tiers.has(tierName)) {
        tiers.set(tierName, [...tiers.get(tierName), e]);
      } else {
        tiers.set(tierName, [e]);
      }
    });
    const tierNames = Array.from(tierSet).sort();
    return { tierNames, tiers };
  };

  const volumeTieredDisplayRates = useMemo(() => {
    return hasVolumeTiers && serviceLevel
      ? createVolumeTiers(serviceLevel)
      : null;
  }, [hasVolumeTiers, serviceLevel]);

  return (
    <Box sx={{ marginBottom: 3 }}>
      <Typography variant="subtitle1" sx={{ marginBottom: 1 }}>
        {translate(`rates.service_level.title.${title}`)}
      </Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              {hasVolumeTiers ? (
                <TableCell>
                  {translate("rates.service_level.table.head.monthly_volume")}
                </TableCell>
              ) : null}
              <TableCell>
                {translate("rates.service_level.table.head.small")}
              </TableCell>
              <TableCell>
                {translate("rates.service_level.table.head.medium")}
              </TableCell>
              <TableCell>
                {translate("rates.service_level.table.head.large")}
              </TableCell>
              <TableCell>
                {translate("rates.service_level.table.head.extra_large")}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!hasVolumeTiers && serviceLevel ? (
              <TableRow>
                <TableCell>
                  {getRate(serviceLevel, PackageSize.SMALL)}
                </TableCell>
                <TableCell>
                  {getRate(serviceLevel, PackageSize.MEDIUM)}
                </TableCell>
                <TableCell>
                  {getRate(serviceLevel, PackageSize.LARGE)}
                </TableCell>
                <TableCell>
                  {getRate(serviceLevel, PackageSize.EXTRA_LARGE)}
                </TableCell>
              </TableRow>
            ) : null}
            {hasVolumeTiers && volumeTieredDisplayRates
              ? volumeTieredDisplayRates.tierNames.map(
                  (tierName: string, idx: number) => {
                    const highestTier =
                      volumeTieredDisplayRates.tierNames.length - 1 === idx;
                    return (
                      <TableRow key={`${serviceLevel}-${tierName}-rates`}>
                        <TableCell>
                          {highestTier
                            ? tierName.replace(/-\d+/, "+")
                            : tierName}
                        </TableCell>
                        <TableCell>
                          {getRate(
                            volumeTieredDisplayRates.tiers.get(tierName),
                            PackageSize.SMALL,
                          )}
                        </TableCell>
                        <TableCell>
                          {getRate(
                            volumeTieredDisplayRates.tiers.get(tierName),
                            PackageSize.MEDIUM,
                          )}
                        </TableCell>
                        <TableCell>
                          {getRate(
                            volumeTieredDisplayRates.tiers.get(tierName),
                            PackageSize.LARGE,
                          )}
                        </TableCell>
                        <TableCell>
                          {getRate(
                            volumeTieredDisplayRates.tiers.get(tierName),
                            PackageSize.EXTRA_LARGE,
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  },
                )
              : null}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default ServiceLevelRates;
