export enum CountryISO2 {
  USA = "US",
  CANADA = "CA",
}

export enum Currencies {
  CAD = "CAD",
  USD = "USD",
}

export enum LANGUAGE {
  ENGLISH = "ENG",
  FRENCH = "FRE",
}

/**
 * @deprecated We no longer use ValidatedAddress. Use ValidatedAddressV2 instead.
 */
export interface ValidatedAddress {
  Id: string;
  DomesticId: string;
  Language: LANGUAGE;
  LanguageAlternatives: string;
  Department: string;
  Company: string;
  SubBuilding: string;
  BuildingNumber: string;
  BuildingName: string;
  SecondaryStreet: string;
  Street: string;
  Block: string;
  Neighbourhood: string;
  District: string;
  City: string;
  Line1: string;
  Line2: string;
  Line3: string;
  Line4: string;
  Line5: string;
  AdminAreaName: string;
  AdminAreaCode: string;
  Province: string;
  ProvinceName: string;
  ProvinceCode: string;
  PostalCode: string;
  CountryName: string;
  CountryIso2: string;
  CountryIso3: string;
  CountryIsoNumber: string;
  SortingNumber1: string;
  SortingNumber2: string;
  Barcode: string;
  POBoxNumber: string;
  Label: string;
  Type: string;
  DataLevel: string;
  AcRbdi: string;
  AcMua: string;
}

/**
 * Interface for the version 2 validated address
 * line2 - apartment number
 * label - the full, validated address in one line
 * addressPrecision - smarty streets estimate of the accuracy of their address lookup
 * geocodePrecision - smarty streets estimate of the accuracy of their geocode lookup
 */
export interface ValidatedAddressV2 {
  country: string;
  province: string;
  city: string;
  postalCode: string;
  buildingNumber: string;
  street: string;
  line2?: string;
  label: string;
  geocode?: Coordinate;
  geohash?: string;
  geocodePrecision?: SmartyStreetsPrecision;
  addressPrecision: SmartyStreetsPrecision;
  validationSource: ValidationEngine;
  taxRegionId?: string;
}

/**
 * Smarty Streets precision options
 * Metric from SS to estimate the accuracy of either the geocode lookup or the address validation.
 * These are ordered by accuracy, for example, "Manual" and "DeliveryPoint" are the most accurate and "None" is least accurate.
 * See https://smartystreets.com/docs/cloud/international-street-api for more details.
 * Option "Manual" is for manual geocodes from the dashboard. These are assumed to be highest accuracy because
 * we expect ops to fill in the correct geocode here.
 */
export enum SmartyStreetsPrecision {
  MANUAL = "Manual",
  DELIVERY_POINT = "DeliveryPoint",
  PREMISE = "Premise",
  THOROUGHFARE = "Thoroughfare",
  LOCALITY = "Locality",
  ADMINISTRATIVE_AREA = "AdministrativeArea",
  NONE = "None",
}

/**
 * We have multiple validation engine options. This enum is collection of them.
 */
export enum ValidationEngine {
  SMARTY = "SMARTY",
  GOOGLE = "GOOGLE",
}

export interface Coordinate {
  latitude: number;
  longitude: number;
}

export interface Address {
  line1: string;
  line2?: string;
  city: string;
  postalCode: string;
  province: string;
  country: string;
}

/**
 * Contains the base properties of the Recipient
 * If you want to modify the Recipient, please modify this interface
 */
interface RecipientBase {
  firstName: string;
  lastName?: string;
  address: Address;
  validatedAddress?: ValidatedAddressV2;
  validatedAddressV2?: ValidatedAddressV2;
  /** @deprecated we use the geocode in ValidatedAddressV2 instead */
  location?: Coordinate;
}

/**
 * Extends RecipientBase with a required phone number and optional email
 * Please modify RecipientBase for shared properties
 */
interface RecipientPhone extends RecipientBase {
  phone: string;
  email?: string;
}

/**
 * Extends RecipientBase with a required email and optional phone number
 * Please modify RecipientBase for shared properties
 */
interface RecipientEmail extends RecipientBase {
  phone?: string;
  email: string;
}

/**
 * Recipient is a union of RecipientEmail and RecipientPhone
 * This allow us to require a phone number OR email (or possibly both)
 */
export type Recipient = RecipientEmail | RecipientPhone;
