import {
  Datagrid,
  TextField,
  EditButton,
  useTranslate,
  useListContext,
  FunctionField,
} from "react-admin";
import { IfCanAccess } from "@react-admin/ra-rbac";
import { Card, Skeleton, Stack } from "@helo/ui";
import { PickupLocation } from "@swyft/domain/src/types/pickup-locations";

import { getPickupRange } from "./utils";

const LocationTable = () => {
  const translate = useTranslate();
  const { isLoading } = useListContext();

  if (isLoading) {
    return (
      <Stack spacing={2}>
        {[...Array(5)].map((...[, idx]) => (
          <Skeleton variant="rectangular" key={`${idx}-skeleton`} />
        ))}
      </Stack>
    );
  }

  return (
    <Card sx={{ overflow: "visible" }}>
      <Datagrid bulkActionButtons={false}>
        <TextField source="name" />
        <TextField
          source="contact.address.line1"
          label={translate("shared.label.address.all")}
        />
        <TextField source="contact.address.city" />
        <TextField source="cutoffTime" />
        <FunctionField
          label={translate("resources.locations.fields.pickupTime")}
          id="id"
          render={(record: PickupLocation) => {
            if (!record.pickupTime) {
              return translate("shared.content.not_available");
            }

            return getPickupRange(record.pickupTime);
          }}
        />
        <IfCanAccess action="edit">
          <EditButton />
        </IfCanAccess>
      </Datagrid>
    </Card>
  );
};

export default LocationTable;
