const primary = {
  main: "#18A0FB",
  dark: "#0072C7",
  light: "#6DD1FF",
  contrastText: "#FFFFFF",
};
const secondary = {
  main: "#031A6B",
  dark: "#000040",
  light: "#44419A",
  contrastText: "#FFFFFF",
};
const palette = {
  primary,
  secondary,
};

export default palette;
