import {
  Autocomplete,
  Box,
  Grid,
  Slide,
  styled,
  TextField,
  Typography,
} from "@helo/ui";
import { useTranslate } from "react-admin";
import { Dispatch, SetStateAction, useState } from "react";

import { UserRoles, UserRolesKey } from "~/constants/form";
import DSPForm from "./DSPForm";
import MerchantForm from "./MerchantForm";
import { UserTypeOptions } from "~/common/consts";
import reverseEnumMapping from "~/common/reverseEnumMapping";

const BasicInfo = ({
  setUserType,
}: {
  setUserType: Dispatch<SetStateAction<UserRolesKey>>;
}) => {
  const translate = useTranslate();
  const defaultValue = UserTypeOptions[0];

  return (
    <FormContainer>
      <Typography
        variant="h4"
        sx={{ mb: 3 }}
        fontWeight={600}
        letterSpacing={-0.5}
      >
        {translate("Tell us more about yourself")}
      </Typography>
      <Autocomplete
        disablePortal
        options={UserTypeOptions}
        isOptionEqualToValue={(option: any, value: any) => {
          return option.label === value?.label || value?.label === "";
        }}
        defaultValue={defaultValue}
        onChange={(
          event: React.SyntheticEvent<Element, Event>,
          selectedOption: {
            value: string;
            label: string;
          } | null,
        ) => {
          if (selectedOption?.value && selectedOption?.label) {
            setUserType(selectedOption.value as UserRolesKey);
          }
        }}
        renderInput={(params) => (
          <TextField {...params} label={translate("I am a ...")} />
        )}
      />
    </FormContainer>
  );
};

const DynamicForm = ({ userType }: { userType: UserRolesKey }) => {
  const merchantUserTypes = [
    UserRoles["3PL"],
    UserRoles.ECOMMERCE,
    UserRoles.RETAILER,
  ].map((value) => reverseEnumMapping(UserRoles, value));
  const dspUserTypes = [UserRoles.COURIER].map((value) =>
    reverseEnumMapping(UserRoles, value),
  );

  if (!userType || merchantUserTypes.includes(userType)) {
    return (
      <Slide direction="up" in={true} mountOnEnter unmountOnExit>
        <Box sx={{ width: "100%" }}>
          <MerchantForm />
        </Box>
      </Slide>
    );
  } else if (dspUserTypes.includes(userType)) {
    return (
      <Slide direction="up" in={true} mountOnEnter unmountOnExit>
        <Box>
          <DSPForm />
        </Box>
      </Slide>
    );
  } else {
    return null;
  }
};

const FTUXForm = () => {
  const [userType, setUserType] = useState<UserRolesKey>(
    UserTypeOptions[0].value as UserRolesKey,
  );

  return (
    <>
      <BasicInfo setUserType={setUserType} />
      <DynamicForm userType={userType} />
    </>
  );
};

const FormContainer = styled(Grid)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  padding: "0px 16px 40px",
  [theme.breakpoints.up("md")]: {
    maxWidth: "500px",
    paddingRight: 0,
    paddingLeft: 0,
  },
}));

export default FTUXForm;
