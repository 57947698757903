import { useRef, useEffect, useState } from "react";
import {
  useTranslate,
  useGetList,
  usePermissions,
  useNotify,
} from "react-admin";
import { useDefineAppLocation } from "@react-admin/ra-navigation";
import { canAccess } from "@react-admin/ra-rbac";
import { useNavigate } from "react-router-dom";
import { styled, Chip, Breadcrumbs, Link } from "@helo/ui";
import { ZoneStatus } from "@swyft/domain/src/types/zones";
// @ts-ignore
// eslint-disable-next-line
import mapboxgl, { Map } from "!mapbox-gl";
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import "mapbox-gl/dist/mapbox-gl.css";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";
import "./map_styles/mapbox.css";

import theme from "~/config/theme";
import {
  addClickEvent,
  addHoverEvent,
  mapOnLoad,
  paintZones,
  PaintStyles,
} from "./helper_functions/mapEvents";
import { useAuthenticatedContext } from "~/components/AuthenticatedContext";
import { useGlobalComponentContext } from "~/components/GlobalComponentContext";
import { useNotifyDialog } from "~/components/feedback/GlobalAlerts";
import ZonePopup from "./PopUp";
import ToolTip from "./Tooltip";
import InactiveMerchantAlert from "~/components/feedback/InactiveMerchantAlert";
import { Routes } from "~/config/Routes";
import AppViewLayout from "~/layouts/app/AppViewLayout";
import { useMerchantsDataProvider } from "~/services/data/merchants";
import { PlaceTwoTone } from "@helo/ui";
import { render } from "react-dom";
import { Marker } from "mapbox-gl";
import Loading from "~/components/feedback/Loading";

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;

//types
export interface IPaintZones {
  reset: string;
  focus: string;
  hover: string;
}

export interface deactivateZoneRequest {
  merchantId: string;
  zoneId: string;
}

//consts
const initialLng = -98.5795;
const initialLat = 39.828175;
const initialZoom = 3.4;
//@ts-ignore
const inactiveZoneColor = theme.palette?.primary.main;
//@ts-ignore
const activeZoneColor = theme.palette?.success.main;
//@ts-ignore
const pendingZoneColor = theme.palette?.secondary.main;

const ZonesMap = () => {
  useDefineAppLocation("settings.coverage");
  const translate = useTranslate();
  const navigate = useNavigate();
  const notify = useNotify();
  const { merchant } = useAuthenticatedContext();
  const merchantId = merchant?.id || "";
  const isOrgActive = merchant?.isActive;
  const [resource, dataProvider] = useMerchantsDataProvider();
  const { permissions } = usePermissions();
  const canRequestQuote = canAccess({
    permissions,
    action: "write",
    resource: "zones",
  });
  const { data, isLoading } = useGetList("zones", {
    pagination: { page: 1, perPage: 10 },
    sort: { field: "id", order: "ASC" },
    filter: {},
    meta: {
      getCoverageUrl: true,
      get90DayOTDUrl: true,
      fetchGeoJSON: true,
      merchantId,
    },
  });
  const notifyDialog = useNotifyDialog();
  const managePickupLocation = () => {
    navigate(Routes.Locations);
  };

  //triggers deactivateZone callable
  const deactivateZoneRequestHandler = async ({
    merchantId,
    zoneId,
  }: deactivateZoneRequest) => {
    try {
      //close popup and open full page loading modal
      map.current.fire("closePopup");
      setIsLoadingModalOpen(true);
      await dataProvider.deactivateZone(resource, {
        zoneId,
        merchantId,
      });
      notifyDialog({
        title: translate(
          "maps.popup.requests.deactivate.message.success.title",
        ),
        content: translate(
          "maps.popup.requests.deactivate.message.success.description",
        ),
        isOpen: true,
      });
    } catch (err: unknown) {
      notify(`${translate("maps.popup.requests.deactivate.message.error")}`, {
        type: "warning",
      });
    } finally {
      setIsLoadingModalOpen(false);
    }
  };

  //states
  const [currentZone, setCurrentZone] = useState<IPaintZones | undefined>({
    reset: "",
    focus: "",
    hover: "",
  });
  const [activeCount, setActiveCount] = useState<number>(0);

  const { setIsLoadingModalOpen } = useGlobalComponentContext();

  //Mapbox setup
  const mapContainer = useRef<any>();
  const geocoderContainerRef = useRef<any>();
  const map = useRef<Map>();
  const geocoderRef = useRef<MapboxGeocoder>();

  if (data && mapContainer.current && permissions) {
    // initialize map only once
    if (!map.current) {
      map.current = new mapboxgl.Map({
        container: mapContainer.current,
        style: "mapbox://styles/mapbox/light-v10",
        center: [initialLng, initialLat],
        zoom: initialZoom,
      });

      //search & zoom
      geocoderRef.current = new MapboxGeocoder({
        accessToken: mapboxgl.accessToken,
        mapboxgl: mapboxgl,
        reverseGeocode: true,
        types: "postcode",
        countries: "us,ca",
        flyTo: {
          zoom: 10,
          easing: function (t: number) {
            map.current.fire("closePopupFromSearch");
            return t;
          },
        },
        placeholder: translate("maps.search.placeholder"),
        marker: false,
      });

      geocoderContainerRef.current.appendChild(
        geocoderRef.current?.onAdd(map.current),
      );

      //custom map pin
      const markerNode = document.createElement("div");
      const markerIcon = <PlaceTwoTone color="primary" fontSize="large" />;
      render(markerIcon, markerNode);
      const marker: Marker = new mapboxgl.Marker(markerNode);

      //add custom marker to map on result
      geocoderRef.current.on("result", (e) => {
        marker.setLngLat(e.result.center).addTo(map.current);
      });
      //remove custom marker to map on clear
      geocoderRef.current.on("clear", () => {
        marker.remove();
      });

      //add controls
      map.current.addControl(
        new mapboxgl.NavigationControl({
          showZoom: true,
          showCompass: false,
        }),
        "top-right",
      );

      //removes search result marker if you click on the map
      map.current.on("click", () => {
        geocoderRef.current?.clear();
      });

      //add polygon layers
      mapOnLoad(
        map,
        data,
        {
          active: activeZoneColor,
          inactive: inactiveZoneColor,
          pending: pendingZoneColor,
        },
        setActiveCount,
      );

      //add layer events
      for (const zone of data) {
        // reactRouterDom render does not support usetranslate within renedered component unless passed as prop
        const popupContent = {
          title: translate("maps.popup.title", { name: `${zone.name} ` }),
          feature_title: translate("maps.popup.zone_features.title"),
          features: [
            translate("maps.popup.zone_features.sms"),
            translate("maps.popup.zone_features.pod"),
            translate("maps.popup.zone_features.delivery_attempts"),
          ],
          download_zips: translate("maps.popup.downloads.zips"),
          download_otd: translate("maps.popup.downloads.otd"),
          quote: translate("maps.popup.requests.quote"),
          deactive: translate("maps.popup.requests.deactivate.button"),
          pending: translate("maps.popup.requests.pending"),
          manage_pickup: translate("maps.popup.requests.manage_pickup"),
        };
        addClickEvent(
          map,
          zone.id,
          mapboxgl,
          <ZonePopup
            content={popupContent}
            zoneId={zone.id}
            merchantId={merchantId}
            zipUrl={zone.coverageFileUrl}
            otdUrl={zone.trailing90OTDUrl}
            canRequestQuote={canRequestQuote}
            status={zone.status || ZoneStatus.INACTIVE}
            deactivateZoneRequestHandler={deactivateZoneRequestHandler}
            managePickupLocationsHandler={managePickupLocation}
            requestQuoteHandler={() => {
              navigate(`${Routes.Zones}/create`, {
                state: { zone, step: 1 },
              });
            }}
          />,
          { zoom: initialZoom, lat: initialLat, long: initialLng },
          setCurrentZone,
        );
        addHoverEvent(
          map,
          zone.id,
          mapboxgl,
          <ToolTip zoneName={popupContent.title} />,
          setCurrentZone,
        );
      }
    }
  }
  useEffect(() => {
    if (currentZone?.focus) {
      paintZones(PaintStyles.focus, currentZone?.focus, map);
      if (currentZone?.reset) {
        paintZones(PaintStyles.reset, currentZone.reset, map);
      }
    }
    if (currentZone?.reset) {
      paintZones(PaintStyles.reset, currentZone.reset, map);
    }
  }, [currentZone]);

  return (
    <AppViewLayout
      title="maps.labels.page_title"
      titleFlair={
        <Chip
          label={translate("maps.chip", {
            count: activeCount,
            total: data?.length || 0,
          })}
          sx={({ palette }) => {
            const color = activeCount
              ? `${palette.success.main}`
              : `${palette.neutral.main}`;
            const textColor = activeCount
              ? `${palette.primary.contrastText}`
              : `${palette.neutral.contrastText}`;
            return {
              marginLeft: 1,
              backgroundColor: color,
              color: textColor,
            };
          }}
        />
      }
      breadcrumbs={
        <Breadcrumbs>
          <Link color="inherit" underline="none">
            {translate("menu.settings.settings")}
          </Link>
          <Link color="text.primary" underline="none">
            {translate("maps.labels.page_title")}
          </Link>
        </Breadcrumbs>
      }
      banners={
        !isOrgActive && (
          <InactiveMerchantAlert
            message={translate("maps.messaging.list_unactive_alert")}
          />
        )
      }
      sx={{
        "& .app-view-layout__header-container": {
          mb: 0,
        },
      }}
    >
      {isLoading ? <Loading /> : null}
      <GeocoderContainer ref={geocoderContainerRef} />
      <MapContainer ref={mapContainer} />
    </AppViewLayout>
  );
};

//styled containers
const MapContainer = styled("div")({
  height: "80%",
  width: "100%",
  marginBlock: 10,
});

const GeocoderContainer = styled("div")({
  maxHeight: "10%",
  paddingTop: 20,
  paddingBottom: 10,
});

export default ZonesMap;
