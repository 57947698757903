import { Merchant } from "~/types/merchants";
import { FirebaseOptions, initializeApp } from "firebase/app";
import { getFunctions, httpsCallable, HttpsCallable } from "firebase/functions";
import { getFirestore, Firestore } from "firebase/firestore";
import errorHandler from "../errorHandler";
import {
  GetRatesParams,
  MerchantCreateOneParams,
  MerchantRequestQuoteParams,
  DeactivateZoneParams,
  ContactSalesParams,
} from "./types";
import { GetOneParams } from "../types";
import { get, getRates } from "../../models/merchants";
import {
  getRates as getMerchantZoneRates,
  list as merchantZonesList,
} from "../../models/merchant-zones";

type MerchantFunctions = {
  createMerchant: HttpsCallable<MerchantCreateOneParams, { data: string }>;
  activateZone: HttpsCallable<MerchantRequestQuoteParams, void>;
  deactivateZone: HttpsCallable<DeactivateZoneParams, void>;
  contactSales: HttpsCallable<ContactSalesParams, void>;
};
export default class MerchantController {
  private db: Firestore;

  private functions: MerchantFunctions;

  /**
   * Creates MerchantController instance with provided config to setup Firebase app.
   * @param {FirebaseOptions} config Firebase configuration to initialize app.
   */
  constructor(config: FirebaseOptions) {
    const firebaseApp = initializeApp(config);
    const firebaseFunctions = getFunctions(firebaseApp);

    this.db = getFirestore(firebaseApp);
    this.functions = {
      createMerchant: httpsCallable<MerchantCreateOneParams, { data: string }>(
        firebaseFunctions,
        "merchants-createMerchant",
      ),
      deactivateZone: httpsCallable<DeactivateZoneParams, void>(
        firebaseFunctions,
        "merchants-deactivateZone",
      ),
      activateZone: httpsCallable<MerchantRequestQuoteParams, void>(
        firebaseFunctions,
        "merchants-activateZone",
      ),
      contactSales: httpsCallable<ContactSalesParams, void>(
        firebaseFunctions,
        "merchants-contactSales",
      ),
    };
  }

  /**
   * Create a merchant
   * @param merchant data needed to create a new merchant
   * @returns the id of the newly created merchant
   */
  create = async (merchant: MerchantCreateOneParams) => {
    try {
      const { data } = await this.functions.createMerchant(merchant);

      return data;
    } catch (err: unknown) {
      return errorHandler(err);
    }
  };

  /**
   * Get a single merchant
   * @param params.id the id of the merchant
   * @returns the merchant object
   */
  getOne = async (params: GetOneParams) => {
    const merchant = await get(this.db, {
      id: params.id,
    });

    return { data: merchant };
  };

  /**
   * Deactivate a particular merchant-zone
   * @param params.merchantId the merchant making the deactivation request
   * @param params.zoneId the zone id to deactivate
   * @returns void if successful
   */
  deactivateZone = async (params: DeactivateZoneParams): Promise<void> => {
    try {
      await this.functions.deactivateZone(params);
    } catch (err: unknown) {
      return errorHandler(err);
    }
  };

  /**
   * Request activation for a particular zone, for a merchant.
   * Data comes from the request quote flow.
   * @param params data needed to request zone activation for a merchant
   * @returns the merchant object zone activation was requested for
   */
  requestZoneActivation = async (
    params: MerchantRequestQuoteParams,
  ): Promise<void> => {
    try {
      await this.functions.activateZone(params);
    } catch (err: unknown) {
      return errorHandler(err);
    }
  };

  /**
   * Get a list of zones for a merchant (merchant-zone)
   * @param params.id the merchant id
   * @returns a list of merchant-zone objects
   */
  getZones = async (params: GetOneParams) => {
    const zones = await merchantZonesList(this.db, {
      id: params.id,
    });
    return { data: zones };
  };

  /**
   * Get rates from a merchant-zone or from merchant document depending on params
   * @param params the merchant, zone, and country details. The merchant id must be provided.
   * @returns the rate card for the specified merchant
   */
  getRates = async (params: GetRatesParams) => {
    let rates;
    // if zoneId is provided check merchant-zones sub-collection for rate
    if (params.zoneId) {
      rates = await getMerchantZoneRates(this.db, {
        merchantId: params.merchantId,
        zoneId: params.zoneId,
      });
    } else {
      rates = await getRates(this.db, { id: params.merchantId });
    }
    return { data: rates };
  };

  /**
   * Send an email to the sales team
   * @param params.merchantId the merchant this message is from
   * @param params.message the plain-text message to send
   * @returns void if successful
   */
  contactSales = async (params: ContactSalesParams): Promise<void> => {
    try {
      await this.functions.contactSales(params);
    } catch (err: unknown) {
      return errorHandler(err);
    }
  };
}
