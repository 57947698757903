import { List, Loading, useTranslate } from "react-admin";
import { IfCanAccess } from "@react-admin/ra-rbac";
import { useDefineAppLocation } from "@react-admin/ra-navigation";
import { AddOutlinedIcon, Button, Breadcrumbs, Link, styled } from "@helo/ui";
import { useNavigate, Link as RouterLink } from "react-router-dom";
import { OrganizationType } from "@swyft/domain/src/types/organizations";

import LocationTable from "./LocationTable";
import { Routes } from "~/config/Routes";
import { useAuthenticatedContext } from "~/components/AuthenticatedContext";
import AppViewLayout from "~/layouts/app/AppViewLayout";
import InactiveMerchantAlert from "~/components/feedback/InactiveMerchantAlert";
import ResourceEmptyState from "~/components/data/ResourceEmptyState";
import { AppResource } from "~/config/resources";

const LocationList = () => {
  useDefineAppLocation("settings.locations");
  const navigate = useNavigate();
  const translate = useTranslate();
  const { merchant } = useAuthenticatedContext();

  const isOrgActive = merchant?.isActive;

  const filter = {
    org: {
      id: merchant?.id,
      type: !!merchant
        ? OrganizationType.Merchant
        : OrganizationType.DeliveryServicePartner,
    },
  };

  return (
    <AppViewLayout
      title="menu.settings.locations"
      context={
        <IfCanAccess resource={AppResource.Location} action="create">
          <CreateButtonStyled
            type="submit"
            variant="contained"
            size="large"
            startIcon={<AddOutlinedIcon sx={{ marginRight: 0 }} />}
            onClick={() => navigate(`${Routes.Locations}/create`)}
            disableElevation
            disabled={!isOrgActive}
          >
            {translate("shared.content.create.title", {
              name: translate("resources.locations.name", { smart_count: 1 }),
            })}
          </CreateButtonStyled>
        </IfCanAccess>
      }
      breadcrumbs={
        <Breadcrumbs>
          <Link color="inherit" underline="none">
            {translate("menu.settings.settings")}
          </Link>
          <Link
            color="text.primary"
            underline="hover"
            component={RouterLink}
            to={Routes.Locations}
          >
            {translate("menu.settings.locations")}
          </Link>
        </Breadcrumbs>
      }
      banners={
        !isOrgActive && (
          <InactiveMerchantAlert
            message={translate("location.content.list.unactive_alert")}
          />
        )
      }
    >
      <List
        actions={false}
        filter={filter}
        exporter={false}
        empty={<ResourceEmptyState />}
        component="div"
      >
        <LocationTable />
      </List>
    </AppViewLayout>
  );
};

const CreateButtonStyled = styled(Button)({
  height: 35,
  marginBlock: 20,
});

export default LocationList;
