import { ReactNode } from "react";
import { styled } from "@helo/ui";

const BaseLayout = ({ children }: BaseLayoutProps) => {
  return <SiteContainer>{children}</SiteContainer>;
};

const SiteContainer = styled("div")({
  display: "grid",
  width: "100%",
  height: "100vh",
});

interface BaseLayoutProps {
  children: ReactNode;
}

export default BaseLayout;
