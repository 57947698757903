import { styled } from "@helo/ui";

import Navbar from "~/components/navigation/navbar";
import Footer from "~/components/navigation/footer";
import AppShellLayout from "~/layouts/app/AppShellLayout";
import FTUXForm from "~/components/forms/FTUXForm";

const FTUXPage = () => {
  return (
    <AppShellLayout>
      <FormContainer>
        <FTUXForm />
      </FormContainer>
    </AppShellLayout>
  );
};

const FormContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  margin: "30px 0",
  [theme.breakpoints.up("md")]: {
    margin: "60px 0",
  },
}));

export default FTUXPage;
