import { Currencies } from "@swyft/domain/src/types/common";
import { PackageSize, ServiceType } from "@swyft/domain/src/types/labels";
import { RateCardV2 } from "@swyft/domain/src/types/merchants";

/* TODO: to be removed and replaced by a default placeholder rateCard that
will be generated for a merchant on creation in future implementations */

export const placeholderRates: RateCardV2 = {
  currency: Currencies.USD,
  redeliveryRatePercent: 75,
  addOns: {
    ADDRESS_CORRECTION: {
      active: true,
      rate: 300,
    },
    CHECK_ID: {
      active: true,
      rate: 100,
    },
    RELABELLING_FEE: {
      active: true,
      rate: 200,
    },
    SIGNATURE: {
      active: true,
      rate: 300,
    },
    WAITING_FEE: {
      active: true,
      rate: 5000,
    },
    XL_PACKAGE: {
      active: true,
      rate: 500,
    },
  },
  entries: [
    {
      maxVolume: 10000,
      minVolume: 100,
      packageSize: PackageSize.SMALL,
      rate: 700,
      serviceLevel: ServiceType.NEXT_DAY,
    },
    {
      maxVolume: 10000,
      minVolume: 100,
      packageSize: PackageSize.MEDIUM,
      rate: 750,
      serviceLevel: ServiceType.NEXT_DAY,
    },
    {
      maxVolume: 10000,
      minVolume: 100,
      packageSize: PackageSize.LARGE,
      rate: 900,
      serviceLevel: ServiceType.NEXT_DAY,
    },
    {
      maxVolume: 10000,
      minVolume: 100,
      packageSize: PackageSize.EXTRA_LARGE,
      rate: 1200,
      serviceLevel: ServiceType.NEXT_DAY,
    },
    {
      maxVolume: 10000,
      minVolume: 100,
      packageSize: PackageSize.SMALL,
      rate: 800,
      serviceLevel: ServiceType.SAME_DAY,
    },
    {
      maxVolume: 10000,
      minVolume: 100,
      packageSize: PackageSize.MEDIUM,
      rate: 850,
      serviceLevel: ServiceType.SAME_DAY,
    },
    {
      maxVolume: 10000,
      minVolume: 100,
      packageSize: PackageSize.LARGE,
      rate: 1000,
      serviceLevel: ServiceType.SAME_DAY,
    },
    {
      maxVolume: 10000,
      minVolume: 100,
      packageSize: PackageSize.EXTRA_LARGE,
      rate: 1300,
      serviceLevel: ServiceType.SAME_DAY,
    },
    {
      maxVolume: 50000,
      minVolume: 10001,
      packageSize: PackageSize.SMALL,
      rate: 650,
      serviceLevel: ServiceType.NEXT_DAY,
    },
    {
      maxVolume: 50000,
      minVolume: 10001,
      packageSize: PackageSize.MEDIUM,
      rate: 650,
      serviceLevel: ServiceType.NEXT_DAY,
    },
    {
      maxVolume: 50000,
      minVolume: 10001,
      packageSize: PackageSize.LARGE,
      rate: 875,
      serviceLevel: ServiceType.NEXT_DAY,
    },
    {
      maxVolume: 50000,
      minVolume: 10001,
      packageSize: PackageSize.EXTRA_LARGE,
      rate: 1175,
      serviceLevel: ServiceType.NEXT_DAY,
    },
    {
      maxVolume: 50000,
      minVolume: 10001,
      packageSize: PackageSize.SMALL,
      rate: 775,
      serviceLevel: ServiceType.SAME_DAY,
    },
    {
      maxVolume: 50000,
      minVolume: 10001,
      packageSize: PackageSize.MEDIUM,
      rate: 825,
      serviceLevel: ServiceType.SAME_DAY,
    },
    {
      maxVolume: 50000,
      minVolume: 10001,
      packageSize: PackageSize.LARGE,
      rate: 975,
      serviceLevel: ServiceType.SAME_DAY,
    },
    {
      maxVolume: 50000,
      minVolume: 10001,
      packageSize: PackageSize.EXTRA_LARGE,
      rate: 1275,
      serviceLevel: ServiceType.SAME_DAY,
    },
    {
      maxVolume: 99999,
      minVolume: 50001,
      packageSize: PackageSize.SMALL,
      rate: 600,
      serviceLevel: ServiceType.NEXT_DAY,
    },
    {
      maxVolume: 99999,
      minVolume: 50001,
      packageSize: PackageSize.MEDIUM,
      rate: 600,
      serviceLevel: ServiceType.NEXT_DAY,
    },
    {
      maxVolume: 99999,
      minVolume: 50001,
      packageSize: PackageSize.LARGE,
      rate: 850,
      serviceLevel: ServiceType.NEXT_DAY,
    },
    {
      maxVolume: 99999,
      minVolume: 50001,
      packageSize: PackageSize.EXTRA_LARGE,
      rate: 1150,
      serviceLevel: ServiceType.NEXT_DAY,
    },
    {
      maxVolume: 99999,
      minVolume: 50001,
      packageSize: PackageSize.SMALL,
      rate: 750,
      serviceLevel: ServiceType.SAME_DAY,
    },
    {
      maxVolume: 99999,
      minVolume: 50001,
      packageSize: PackageSize.MEDIUM,
      rate: 800,
      serviceLevel: ServiceType.SAME_DAY,
    },
    {
      maxVolume: 99999,
      minVolume: 50001,
      packageSize: PackageSize.LARGE,
      rate: 950,
      serviceLevel: ServiceType.SAME_DAY,
    },
    {
      maxVolume: 99999,
      minVolume: 50001,
      packageSize: PackageSize.EXTRA_LARGE,
      rate: 1250,
      serviceLevel: ServiceType.SAME_DAY,
    },
  ],
};
