import { Admin, CustomRoutes } from "react-admin";
import { Route } from "react-router-dom";
import { Resource } from "@react-admin/ra-rbac";

import { UserList, UserEdit, UserCreate } from "~/features/users";
import RatesCard from "~/features/rates/RatesCard";
import LoginPage from "~/pages/auth/login";
import ForgotPassword from "~/pages/auth/forgot-password";
import Register from "~/pages/auth/register";
import Reports from "~/features/reports/Reports";
import ResetPassword from "~/pages/auth/reset-password";
import authProvider from "~/services/auth/authProvider";
import dataProvider from "~/services/data/dataProvider";
import i18nProvider from "~/locale";

import { Routes } from "~/config/Routes";
import theme from "~/config/theme";
import FTUX from "~/pages/auth/ftux";
import Dashboard from "~/features/dashboard/SimpleDashboard";
import UnverifiedEmailPage from "~/pages/auth/unverified-email";
import { AppLayout } from "~/layouts/app";
import {
  LocationList,
  LocationCreate,
  LocationEdit,
} from "~/features/locations";
import { ShipmentLabelsList, ShipmentLabelsCreate } from "~/features/shipments";
import { ZonesMap, ZonesRequestQuote } from "~/features/zones";
import PageNotFoundError from "~/components/feedback/PageNotFound";
import { AppResource } from "~/config/resources";
import { isSignupEnabled } from "./services/remote-config";

const MainAdmin = () => {
  return (
    <Admin
      title={"Swyft"}
      loginPage={LoginPage}
      theme={theme}
      authProvider={authProvider}
      dataProvider={dataProvider}
      i18nProvider={i18nProvider}
      dashboard={Dashboard}
      layout={AppLayout}
      catchAll={PageNotFoundError}
      disableTelemetry // don't send anonymous analytics to marmelab
      // NOTE: https://marmelab.com/react-admin/Authentication.html can't disable anonymous access with requireAuth prop as it covers custom routes too.
      // react-admin also optimisitcally renders by default unless requireAuth is provided, so pessimistic rendering is handled in the layout component instead.
    >
      <Resource
        name={AppResource.User}
        list={UserList}
        create={UserCreate}
        edit={UserEdit}
      />
      <Resource
        name={AppResource.Shipment}
        list={ShipmentLabelsList}
        create={ShipmentLabelsCreate}
      />
      <Resource name={AppResource.Rate} list={RatesCard} />
      <Resource
        name={AppResource.Zone}
        list={ZonesMap}
        create={ZonesRequestQuote}
      />
      <Resource
        name={AppResource.Location}
        list={LocationList}
        edit={LocationEdit}
        create={LocationCreate}
      />
      <CustomRoutes>
        <Route path={Routes.Reports} element={<Reports />} />
      </CustomRoutes>
      <CustomRoutes noLayout>
        {isSignupEnabled() && (
          <Route path={Routes.Register} element={<Register />} />
        )}
        <Route path={Routes.ResetPassword} element={<ResetPassword />} />
        <Route path={Routes.ForgotPassword} element={<ForgotPassword />} />
        <Route
          path={Routes.UnverifiedEmail}
          element={<UnverifiedEmailPage />}
        />
        <Route path={Routes.FTUX} element={<FTUX />} />
      </CustomRoutes>
    </Admin>
  );
};
export default MainAdmin;
