import { createContext, PropsWithChildren, useContext } from "react";
import firebaseConfig from "~/config/firebase";
import { DSPController } from "@swyft/domain/src/controllers/dsp";
import { MerchantController } from "@swyft/domain/src/controllers/merchant";
import { UsersController } from "@swyft/domain/src/controllers/users";

interface DomainContext {
  usersController: UsersController;
  dspController: DSPController;
  merchantController: MerchantController;
}

const defaultValue: DomainContext = {
  usersController: new UsersController(firebaseConfig),
  dspController: new DSPController(firebaseConfig),
  merchantController: new MerchantController(firebaseConfig),
};

const DomainContext = createContext<DomainContext>(defaultValue);

export const useDomainContext = () => {
  const context = useContext(DomainContext);

  if (context === null) {
    throw new Error("Must be called within DomainContextProvider.");
  }

  return context;
};

export const DomainContextProvider = ({ children }: PropsWithChildren<{}>) => {
  return (
    <DomainContext.Provider
      value={{
        usersController: defaultValue.usersController,
        dspController: defaultValue.dspController,
        merchantController: defaultValue.merchantController,
      }}
    >
      {children}
    </DomainContext.Provider>
  );
};
