export enum ServiceAddOns {
  SIGNATURE = "SIGNATURE",
  CHECK_ID = "CHECK_ID",
  XL_PACKAGE = "XL_PACKAGE",
  ADDRESS_CORRECTION = "ADDRESS_CORRECTION",
  RELABELLING_FEE = "RELABELLING_FEE",
  WAITING_FEE = "WAITING_FEE",
}

export enum PackageSize {
  DEFAULT = "DEFAULT",
  SMALL = "SMALL",
  MEDIUM = "MEDIUM",
  LARGE = "LARGE",
  EXTRA_LARGE = "EXTRA_LARGE",
}

export enum ServiceType {
  SAME_DAY = "SAMEDAY",
  NEXT_DAY = "NEXTDAY",
}
