import {
  Box,
  Button,
  Grid,
  LoadingButton,
  Stack,
  styled,
  TextField,
  Typography,
} from "@helo/ui";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  CountryCodeField,
  PasswordField,
  PhoneNumberField,
} from "@helo/ui/components";
import { useTranslate, useNotify, useLogin, useCreate } from "react-admin";

import { getRegisterFormSchema } from "~/common/validators/register-form/registerForm";
import AuthenticationLayout from "~/layouts/site/AuthenticationLayout";
import { Routes } from "~/config/Routes";
import { UserSignupForm } from "~/features/users/types";
import { AppResource } from "~/config/resources";

const RegisterPage = () => {
  const translate = useTranslate();
  const { register, handleSubmit, formState, reset, setValue } = useForm({
    defaultValues: {
      firstName: "",
      lastName: "",
      phone: "",
      countryCode: "+1",
      email: "",
      password: "",
    },
    resolver: yupResolver(getRegisterFormSchema(translate)),
    mode: "onSubmit",
  });
  const notify = useNotify();
  const login = useLogin();
  const [create] = useCreate(AppResource.User);

  const onSubmit = async (formValues: UserSignupForm) => {
    try {
      await create(
        AppResource.User,
        {
          data: {
            firstName: formValues.firstName,
            lastName: formValues.lastName,
            phone: formValues.phone,
            email: formValues.email,
            password: formValues.password,
            countryCode: formValues.countryCode,
          },
        },
        {
          returnPromise: true,
        },
      );
      notify("user.message.register.ok", {
        type: "success",
      });
      reset();
      await login({ email: formValues.email, password: formValues.password });
    } catch (err: any) {
      if (err.details?.code === "auth/email-already-exists") {
        notify("user.message.register.fail_email_exists", { type: "warning" });
      } else {
        notify(
          `${translate("user.message.register.fail")} ${
            err?.message ?? JSON.stringify(err)
          }`,
          { type: "warning" },
        );
      }
    }
  };

  return (
    <AuthenticationLayout>
      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item sm={6} xs={12}>
            <TextField
              error={Boolean(formState?.errors?.firstName)}
              fullWidth
              helperText={formState?.errors?.firstName?.message}
              label={translate("resources.users.fields.firstName")}
              margin="normal"
              type="text"
              {...register("firstName")}
              name="firstName"
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <TextField
              error={Boolean(formState?.errors?.lastName)}
              fullWidth
              helperText={formState?.errors?.lastName?.message}
              label={translate("resources.users.fields.lastName")}
              margin="normal"
              {...register("lastName")}
              name="lastName"
            />
          </Grid>
          <Grid item sx={{ paddingLeft: "16px" }} sm={12} xs={12}>
            <TextField
              error={Boolean(formState?.errors?.email)}
              fullWidth
              helperText={formState?.errors?.email?.message}
              label={translate("shared.label.email")}
              margin="normal"
              type="email"
              {...register("email")}
              name="email"
            />
          </Grid>
          <Grid item sm={12} xs={12}>
            <PasswordField
              error={Boolean(formState?.errors?.password)}
              fullWidth
              helperText={
                //TODO: update to only show "trong" once creiteria has been met
                formState?.errors?.password
                  ? formState?.errors?.password?.message
                  : formState?.dirtyFields?.password
                  ? translate("shared.validation.password_strong")
                  : translate("shared.validation.password")
              }
              label={translate("ra.auth.password")}
              {...register("password", {
                onChange: (e) => setValue("password", e?.target?.value),
              })}
              name="password"
            />
          </Grid>
          <Grid item sm={12} xs={12}>
            <Stack direction="row" spacing={2}>
              <Box sx={{ mt: 2, width: "80px" }}>
                <CountryCodeField
                  fullWidth
                  id="countrycode"
                  {...register("countryCode", {
                    onChange: (e) => setValue("countryCode", e?.target?.value),
                  })}
                  error={Boolean(formState?.errors?.countryCode)}
                  label={translate("shared.label.countryCode")}
                  name="countryCode"
                />
              </Box>
              <Box sx={{ mt: 0, width: "calc(100% - 80px)" }}>
                <PhoneNumberField
                  error={Boolean(formState?.errors?.phone)}
                  fullWidth
                  helperText={formState?.errors?.phone?.message}
                  label={translate("shared.label.phone")}
                  margin="normal"
                  type="number"
                  {...register("phone", {
                    onChange: (e) => setValue("phone", e?.target?.value),
                  })}
                  name="phone"
                />
              </Box>
            </Stack>
          </Grid>
        </Grid>
        <SignUpButtonContainer>
          <LoadingButton
            type="submit"
            variant="contained"
            loading={formState?.isSubmitting}
            size="large"
            fullWidth
            style={{ textTransform: "none" }}
          >
            {translate("ra.action.create_item", {
              item: translate("user.account"),
            })}
          </LoadingButton>
        </SignUpButtonContainer>
        <Link to={Routes.Root} style={{ textDecoration: "none" }}>
          <Button
            type="reset"
            style={{ textTransform: "none" }}
            variant="text"
            sx={{ mt: 2 }}
            size="small"
          >
            {translate("user.form.register.back_to_login")}
          </Button>
        </Link>
      </form>
    </AuthenticationLayout>
  );
};

const SignUpButtonContainer = styled(Grid)({
  width: "100%",
  paddingTop: "15px",
});

export default RegisterPage;
