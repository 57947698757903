import { useTranslate, useLocaleState } from "react-admin";
import {
  Box,
  Typography,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
} from "@helo/ui";
import { currencyFormatter } from "~/common/helpers";
import { DisplayAddOns } from "~/services/data/types";

interface AddOnRatesProps {
  addOns: DisplayAddOns[] | undefined;
  currency: string;
  redeliveryRatePercent?: number;
}

const AddOnRates = ({
  addOns,
  currency,
  redeliveryRatePercent,
}: AddOnRatesProps) => {
  const translate = useTranslate();
  const [locale] = useLocaleState();
  return (
    <Box sx={{ marginBottom: 3 }}>
      <Typography variant="subtitle1" sx={{ marginBottom: 1 }}>
        {translate("rates.add_ons.title")}
      </Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                {translate("rates.add_ons.table.head.type")}
              </TableCell>
              <TableCell>
                {translate("rates.add_ons.table.head.description")}
              </TableCell>
              <TableCell>{translate("rates.add_ons.table.head.fee")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {addOns
              ? addOns.map((item, idx) => (
                  <TableRow key={`${idx}-${item.addOn}-details`}>
                    <TableCell>
                      {translate(`rates.add_ons.table.body.${item.addOn}.name`)}
                    </TableCell>
                    <TableCell>
                      {translate(
                        `rates.add_ons.table.body.${item.addOn}.description`,
                      )}
                    </TableCell>
                    <TableCell>
                      {currencyFormatter(locale, item.rate, currency)}
                    </TableCell>
                  </TableRow>
                ))
              : null}
            {redeliveryRatePercent ? (
              <TableRow>
                <TableCell>
                  {translate("rates.add_ons.table.body.redelivery_rate.name")}
                </TableCell>
                <TableCell>
                  {translate(
                    "rates.add_ons.table.body.redelivery_rate.description",
                    { percentage: redeliveryRatePercent },
                  )}
                </TableCell>
              </TableRow>
            ) : null}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default AddOnRates;
