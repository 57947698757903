import { TrackingEventState } from "@swyft/swyft-common";
import moment from "moment";
import { sortStrings, getAddressString } from "~/common/labelHelpers";
import { Timestamp } from "firebase/firestore";
import { ICsvTemplateRow } from "~/features/shipments/types";
import { CsvUploadColumnType } from "~/common/csv/types";
import { getOptions } from "./helpers";
import { UserStatus, UserRole } from "@swyft/domain/src/types/users";
import {
  Country,
  DSPSoftware,
  FleetSize,
  MonthlyVolume,
  PackageSize,
  PackageWeight,
  UserRoles,
} from "~/constants/form";

export const EXPORT_DATE_FORMAT = "MMMD";
export const COMPANY_NAME = "Swyft";

/**
 * Specification for the allowed columns when a user uploads a CSV
 * for label creation.
 */
export const CSV_TEMPLATE_COLUMNS: CsvUploadColumnType<ICsvTemplateRow>[] = [
  {
    title: "First_Name",
    readableTitle: "First Name",
    dataIndex: "First_Name",
    width: 175,
    required: true,
    sorter: (a: ICsvTemplateRow, b: ICsvTemplateRow) =>
      sortStrings(a["First_Name"], b["First_Name"]),
  },
  {
    title: "Last_Name",
    readableTitle: "Last Name",
    dataIndex: "Last_Name",
    width: 175,
    required: true,
    sorter: (a: ICsvTemplateRow, b: ICsvTemplateRow) =>
      sortStrings(a["Last_Name"], b["Last_Name"]),
  },
  {
    title: "Phone",
    readableTitle: "Phone",
    dataIndex: "Phone",
    width: 150,
    required: false, // PHONE OR EMAIL
    sorter: (a: ICsvTemplateRow, b: ICsvTemplateRow) =>
      sortStrings(a["Phone"], b["Phone"]),
  },
  {
    title: "Email",
    readableTitle: "Email",
    dataIndex: "Email",
    key: "Email",
    width: 150,
    required: false, // PHONE OR EMAIL
    sorter: (a: ICsvTemplateRow, b: ICsvTemplateRow) =>
      sortStrings(a["Email"], b["Email"]),
  },
  {
    title: "Notes",
    readableTitle: "Notes",
    dataIndex: "Notes",
    width: 150,
    required: false,
  },
  {
    title: "Address_Line1",
    readableTitle: "Address Line 1",
    dataIndex: "Address_Line1",
    width: 175,
    required: true,
  },
  {
    title: "Address_Line2",
    readableTitle: "Address Line 2",
    dataIndex: "Address_Line2",
    width: 150,
    required: false,
  },
  {
    title: "City/Town",
    readableTitle: "City/Town",
    dataIndex: "City/Town",
    width: 150,
    required: true,
    sorter: (a: ICsvTemplateRow, b: ICsvTemplateRow) =>
      sortStrings(a["City/Town"], b["City/Town"]),
  },
  {
    title: "Postal/Zip",
    readableTitle: "Postal/Zip",
    dataIndex: "Postal/Zip",
    width: 150,
    required: true,
    sorter: (a: ICsvTemplateRow, b: ICsvTemplateRow) =>
      sortStrings(a["Postal/Zip"], b["Postal/Zip"]),
  },
  {
    title: "Province/State",
    readableTitle: "Province/State",
    dataIndex: "Province/State",
    width: 150,
    required: true,
    sorter: (a: ICsvTemplateRow, b: ICsvTemplateRow) =>
      sortStrings(a["Province/State"], b["Province/State"]),
  },
  {
    title: "Country",
    readableTitle: "Country",
    dataIndex: "Country",
    key: "Country",
    width: 150,
    required: true,
    sorter: (a: ICsvTemplateRow, b: ICsvTemplateRow) =>
      sortStrings(a["Country"], b["Country"]),
  },
  {
    title: "Order_Number",
    readableTitle: "Order Number",
    dataIndex: "Order_Number",
    key: "Order_Number",
    width: 150,
    required: false,
    sorter: (a: ICsvTemplateRow, b: ICsvTemplateRow) =>
      sortStrings(a["Order_Number"], b["Order_Number"]),
  },
  {
    title: "Tracking_Number",
    readableTitle: "Tracking Number",
    dataIndex: "Tracking_Number",
    key: "Tracking_Number",
    width: 150,
    required: false,
    sorter: (a: ICsvTemplateRow, b: ICsvTemplateRow) =>
      sortStrings(a["Tracking_Number"], b["Tracking_Number"]),
  },
  {
    title: "Package_Weight_LB",
    readableTitle: "Package Weight (lb)",
    dataIndex: "Package_Weight_LB",
    key: "Package_Weight_LB",
    width: 150,
    required: false,
  },
  {
    title: "Length_IN",
    readableTitle: 'Length (in ")',
    dataIndex: "Length_IN",
    key: "Length_IN",
    width: 150,
    required: false,
  },
  {
    title: "Width_IN",
    readableTitle: 'Width (in ")',
    dataIndex: "Width_IN",
    key: "Width_IN",
    width: 150,
    required: false,
  },
  {
    title: "Height_IN",
    readableTitle: 'Height (in ")',
    dataIndex: "Height_IN",
    key: "Height_IN",
    width: 150,
    required: false,
  },
  {
    title: "Signature_Required",
    readableTitle: "Signature Required",
    dataIndex: "Signature_Required",
    key: "Signature_Required",
    width: 150,
    required: false,
  },
];

export const CSV_EXPORT_COLUMNS = [
  "createdAt",
  "shipDate",
  "completionTime",
  "state",
  "recipientName",
  "addressLine1",
  "addressLine2",
  "city",
  "province",
  "country",
  "postalCode",
  "packageType",
  "deliveryPrice",
  "currency",
  "deliveryServiceType",
  "notes",
  "orderNumber",
  "trackingNumber",
  "trackingUrl",
  "failureReason",
  "failureNotes",
  "redeliveryAttempts",
  "photoURLs",
];

export const PRINT_SELECTED_LABELS_COLUMNS = [
  {
    title: "Created At",
    dataIndex: "createdAt",
    sortIndex: "createdAt",
    width: 150,
    render: (createdAt: Timestamp) =>
      moment(createdAt.toDate()).format("MMM. D [at] h:mm a"),
    sorter: (a: any, b: any) => a.createdAt - b.createdAt,
  },
  {
    title: "Ship Date",
    dataIndex: "shipDate",
    sortIndex: "shipDate",
    width: 100,
    render: (shipDate: string) => moment(shipDate).format("MMM. DD"),
    sorter: (a: any, b: any) => {
      if (moment(a.shipDate).isAfter(moment(b.shipDate))) {
        return -1;
      } else if (moment(b.shipDate).isAfter(moment(a.shipDate))) {
        return 1;
      } else {
        return 0;
      }
    },
  },
  {
    title: "Tracking Number",
    dataIndex: "destination",
    sortIndex: "destination.trackingNumber",
    render: (destination: any) => destination.trackingNumber,
    sorter: (a: any, b: any) =>
      sortStrings(a.destination?.trackingNumber, b.destination?.trackingNumber),
  },
  {
    title: "Address",
    dataIndex: ["destination", "address"],
    sortIndex: "address",
    width: 300,
    render: (address: any) => {
      return `${address.line1}, ${address.line2 ? address.line2 : ""}
      ${address.city}, ${address.province}, ${address.postalCode}`;
    },
    sorter: (a: any, b: any) => {
      return sortStrings(getAddressString(a), getAddressString(b));
    },
  },
  {
    title: "Email",
    dataIndex: ["destination", "email"],
    sortIndex: "email",
    width: 150,
    sorter: (a: any, b: any) =>
      sortStrings(a.destination.email, b.destination.email),
  },
  {
    title: "Order #",
    align: "center" as any,
    sortIndex: "orderNumber",
    dataIndex: "orderNumber",
    sorter: (a: any, b: any) => sortStrings(a.orderNumber, b.orderNumber),
  },
];

/** Alternative names for a few tracking states to display to merchants */
export const trackingEventStateAlias: { [key in TrackingEventState]?: string } =
  {
    [TrackingEventState.IN_TRANSIT]: "OUT_FOR_DELIVERY",
    [TrackingEventState.DELETED]: "CANCELLED",
    [TrackingEventState.RETURNING_TO_SENDER]: "RETURNING_TO_SENDER",
  };

export const trackingEventStateDescription: {
  [key in TrackingEventState]?: string;
} = {
  [TrackingEventState.PENDING]: `${COMPANY_NAME} is validating the label.`,
  [TrackingEventState.CONFIRMED]: `${COMPANY_NAME} has validated the label and has accepted it for delivery.`,
  [TrackingEventState.ASSIGNED]:
    "The package is assigned to a driver and will go out soon.",
  [TrackingEventState.IN_TRANSIT]: "The package is in transit.",
  [TrackingEventState.ARRIVING]:
    "The delivery is within 150 meters (~500 feet) of the destination.",
  [TrackingEventState.DELIVERED]: "The package was successfully delivered.",
  [TrackingEventState.FAILED]: "The delivery failed.",
  [TrackingEventState.DELETED]: "The label was cancelled by the sender.",
  [TrackingEventState.NOT_RECEIVED]: `The package was not received at a ${COMPANY_NAME} facility when expected.`,
  [TrackingEventState.RETURNING_TO_SENDER]:
    "The package is undeliverable and will be returned to the sender.",
  [TrackingEventState.RETURNED_TO_SENDER]:
    "The package has been returned to the sender.",
  [TrackingEventState.STORAGE]: `The package is in storage at a ${COMPANY_NAME} facility.`,
  [TrackingEventState.PROBLEM]: `There is an issue with the label and ${COMPANY_NAME} is fixing it.`,
};

const trackingPageUrl = "https://track.useswyft.com/track";

export const getTrackingPageUrl = (trackingNumber: string) =>
  `${trackingPageUrl}?trackingNumber=${trackingNumber}`;

export const GENERIC_ERROR_MESSAGE = `Something went wrong. Please contact ${COMPANY_NAME}.`;
export const OPERATIONS_EMAIL = "operations@useswyft.com";

export const REGEX_PATTERNS = {
  phone_number: /^\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{4})$/,
  password: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&.]{8,}$/,
};

export const STORAGE_BUCKET_FOLDERS = {
  merchant_request_quote: "request-quote/merchant",
};

export const UserRoleOptions = getOptions(UserRole);
export const UserStatusOptions = getOptions(UserStatus);
export const UserTypeOptions = getOptions(UserRoles, {
  capitalize: false,
  filterPredicate: ([k, v]) => v !== UserRoles.COURIER,
  mapKeyToValue: true,
});
export const CountryOptions = getOptions(Country, {
  mapKeyToValue: true,
});
export const FleetSizeOptions = getOptions(FleetSize, {
  mapKeyToValue: true,
});
export const DSPSoftwareOptions = getOptions(DSPSoftware, {
  mapKeyToValue: true,
});
export const MonthlyVolumeOptions = getOptions(MonthlyVolume, {
  mapKeyToValue: true,
});
export const PackageSizeOptions = getOptions(PackageSize, {
  mapKeyToValue: true,
});
export const PackageWeightOptions = getOptions(PackageWeight, {
  mapKeyToValue: true,
});
