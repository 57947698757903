import { Controller, useForm } from "react-hook-form";
import {
  Alert,
  ArrowBackIcon,
  Button,
  Grid,
  LoadingButton,
  styled,
  TextField,
  Typography,
} from "@helo/ui";

import { Link } from "react-router-dom";
import { useState } from "react";
import { useTranslate, useNotify } from "react-admin";

import { useDomainContext } from "~/components/DomainContext";
import AuthenticationLayout from "~/layouts/site/AuthenticationLayout";
import { Routes } from "~/config/Routes";

const ForgotPasswordPage = () => {
  const translate = useTranslate();
  const notify = useNotify();
  const [isSent, setIsSent] = useState<boolean>(false);
  const {
    handleSubmit,
    control,
    formState: { isSubmitting },
  } = useForm<ForgotPasswordData>({
    defaultValues: {
      email: "",
    },
    mode: "onChange",
  });
  const { usersController } = useDomainContext();
  const handleVerifiedSubmit = async ({ email }: ForgotPasswordData) => {
    try {
      await usersController.resetPassword(email);
      notify("user.message.password_reset.ok", {
        type: "success",
      });
      setIsSent(true);
    } catch (err: any) {
      notify(
        `${translate("user.message.password_reset.fail")} ${
          err?.message ?? JSON.stringify(err)
        }`,
        { type: "warning" },
      );
    }
  };

  return (
    <AuthenticationLayout>
      <TitleText sx={{ mb: 3 }} variant="h6">
        {translate("Forgot Password")}
      </TitleText>
      {isSent ? (
        <Alert color="success">
          {translate(
            "We have sent you an password reset link on your email. Please check that to continue. Also don't forget to check the SPAM folder",
          )}
        </Alert>
      ) : (
        <form onSubmit={handleSubmit(handleVerifiedSubmit)}>
          <Typography
            variant="body2"
            mb={2}
            color="neutral.500"
            fontWeight={300}
          >
            {translate(`Enter the email address associated with your account and we'll send
            you instructions on how to set a new one.`)}
          </Typography>
          <Controller
            name={"email"}
            control={control}
            rules={{
              required: {
                value: true,
                message: "This field is required",
              },
              pattern: {
                value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                message: translate("Please enter Valid Email"),
              },
            }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                type="email"
                onChange={onChange}
                value={value}
                label={translate("Enter your Email")}
                sx={{ mb: 3 }}
                color="success"
                fullWidth
                error={Boolean(error)}
                helperText={error?.message || ""}
              />
            )}
          />
          <PWRButtonContainer>
            <LoadingButton
              type="submit"
              variant="contained"
              loading={isSubmitting}
              style={{ textTransform: "none" }}
            >
              {translate("Send Password Request")}
            </LoadingButton>
            <Link to={Routes.Root} style={{ textDecoration: "none" }}>
              <Button
                type="reset"
                variant="text"
                sx={{ mt: 2 }}
                startIcon={<ArrowBackIcon />}
                size="small"
                style={{ textTransform: "none" }}
              >
                {translate("Back to Login")}
              </Button>
            </Link>
          </PWRButtonContainer>
        </form>
      )}
    </AuthenticationLayout>
  );
};

const TitleText = styled(Typography)({
  fontWeight: 600,
});

const PWRButtonContainer = styled(Grid)({
  display: "grid",
  gridTemplateColumns: "1fr",
  width: "100%",
});

interface ForgotPasswordData {
  email: string;
}

export default ForgotPasswordPage;
