/* eslint-disable max-classes-per-file */
import {
  collection,
  doc,
  DocumentData,
  Firestore,
  FirestoreDataConverter,
  getDoc,
  getDocs,
  orderBy,
  OrderByDirection,
  query,
  QueryConstraint,
  where,
  WithFieldValue,
} from "firebase/firestore";

import { PickupLocation } from "~/types/pickup-locations";

const pickupLocationsConverter: FirestoreDataConverter<PickupLocation> = {
  fromFirestore(snapshot) {
    return snapshot.data() as PickupLocation; // TODO: map appropriate values
  },
  toFirestore(model: WithFieldValue<PickupLocation>): DocumentData {
    return model; // TODO: map appropriate values
  },
};

const getPickLocationsCollection = (db: Firestore, merchantId: string) =>
  collection(db, `merchants/${merchantId}/pickup-locations`).withConverter(
    pickupLocationsConverter,
  );

export class PickupLocationNotFoundError extends Error {
  constructor(id: string) {
    super(`No pickup location found for id ${id}`);
  }
}

export class PickupLocationOrgNotSpecified extends Error {
  constructor() {
    super(`An organization needs to be specified to get pickup locations`);
  }
}

export const get = async (
  db: Firestore,
  params: {
    org?: {
      id: string;
    };
    id: string;
  },
) => {
  if (!params.org?.id) {
    throw new PickupLocationOrgNotSpecified();
  }

  const docRef = doc(getPickLocationsCollection(db, params.org.id), params.id);
  const docsSnapshot = await getDoc(docRef);

  if (docsSnapshot.exists()) {
    return docsSnapshot.data();
  }

  throw new PickupLocationNotFoundError(params.id);
};

export const list = async (
  db: Firestore,
  params: {
    filter?: {
      org?: {
        id: string;
      };
      address?: {
        city?: string;
      };
      name?: string;
    };
    sort?: {
      field: string;
      order: OrderByDirection;
    };
  } = {},
) => {
  const queryConstraints: QueryConstraint[] = [];
  const { filter, sort } = params;

  if (!filter?.org?.id) {
    throw new PickupLocationOrgNotSpecified();
  }

  if (filter?.address?.city) {
    queryConstraints.push(
      where("contact.validatedAddressV2.city", "==", filter.address.city),
    );
  }

  if (filter?.name) {
    queryConstraints.push(where("name", "==", filter.name));
  }

  if (sort?.field) {
    queryConstraints.push(orderBy(sort.field, sort.order));
  }

  const finalQuery = query(
    getPickLocationsCollection(db, filter.org.id),
    ...queryConstraints,
  );

  const docsSnapshot = await getDocs(finalQuery);
  return docsSnapshot.docs.map((docSnapshot) => docSnapshot.data());
};
