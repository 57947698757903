import React from "react";
import { Tabs } from "antd";
import { useTranslate } from "react-admin";

import { useAuthenticatedContext } from "~/components/AuthenticatedContext";
import { CreateLabelForm } from "./CreateLabelForm";
import { ImportCsv } from "./ImportCsv";
import InactiveMerchantAlert from "~/components/feedback/InactiveMerchantAlert";
import AppViewLayout from "~/layouts/app/AppViewLayout";

export const CreateLabel: React.FC = () => {
  const { TabPane } = Tabs;
  const { merchant } = useAuthenticatedContext();
  const translate = useTranslate();

  const isOrgActive = merchant?.isActive;

  if (!merchant) return null;

  return (
    <AppViewLayout
      title="menu.shipments.create"
      banners={
        !isOrgActive && (
          <InactiveMerchantAlert
            message={translate("shipments.content.create.unactive_alert")}
          />
        )
      }
    >
      <Tabs defaultActiveKey="1" size="large">
        <TabPane tab="Create a label" key="1">
          <CreateLabelForm merchant={merchant} isMerchantGroup={false} />
        </TabPane>
        <TabPane tab="Upload a CSV" key="2" style={{ maxWidth: "70vw" }}>
          <ImportCsv merchant={merchant} isMerchantGroup={false} />
        </TabPane>
      </Tabs>
    </AppViewLayout>
  );
};
