import { string, mixed, object, InferType } from "yup";
import { REGEX_PATTERNS } from "~/common/consts";
import { CountryISO2 } from "@swyft/domain/src/types/common";

export const RequestQuoteValidationSchema = object({
  shipping_volume_file: object({
    previewURL: string(),
    rawFile: mixed(),
    storagePreviewPath: string(),
    storagePreviewURL: string().url(),
  })
    .nullable()
    .test("invalid", "shared.file.upload_fail", (data) => {
      if (!data) {
        return true;
      }

      // empty files are invalid
      return !!data?.rawFile?.size;
    }),
  location: object({
    name: string().required("shared.validation.required"),
    notes: string(),
    address: object({
      line_one: string().required("shared.validation.required"),
      line_two: string(),
      city: string().required("shared.validation.required"),
      sub_division: string().required("shared.validation.required"),
      country: string()
        .required("shared.validation.required")
        .oneOf(Object.values(CountryISO2), "shared.validation.required_select")
        .nullable(),
      postal_zip: string().required("shared.validation.required"),
    }),
    contact: object({
      first_name: string().required("shared.validation.required"),
      last_name: string().required("shared.validation.required"),
      job_title: string().required("shared.validation.required"),
      phone: string()
        .matches(REGEX_PATTERNS.phone_number, "shared.validation.invalid")
        .required("shared.validation.required"),
      email: string()
        .email("shared.validation.invalid")
        .required("shared.validation.required"),
    }),
  }),
});

export interface RequestQuoteSchema
  extends InferType<typeof RequestQuoteValidationSchema> {}
