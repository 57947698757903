import { useState, useEffect } from "react";
import moment, { Moment } from "moment";
import * as FirestoreService from "~/services/firestore";
import { isEmpty, isUndefined } from "lodash";
import { RedoOutlined } from "@ant-design/icons";
import { InboxOutlined } from "@helo/ui";
import {
  Row,
  Col,
  Steps,
  Typography,
  DatePicker,
  Button,
  List,
  Spin,
  Input,
  Result,
  Alert,
  Table,
  Space,
  TablePaginationConfig,
  message,
} from "antd";
import { grey } from "@ant-design/colors";
import Dragger from "antd/lib/upload/Dragger";
import { readString } from "react-papaparse";
import { validateTemplateHeader } from "~/common/csv/ValidateTemplateHeader";
import { IClientCsvErrorMap, validateRows } from "~/common/csv/ValidateRows";
import { ParseResult } from "papaparse";
import {
  CountryISO2,
  DATE_FORMAT,
  ILabelV2,
  IPickupLocation,
} from "@swyft/swyft-common";
import { exportLabelsPDF } from "~/common/fileExportHelper";
import {
  CSV_TEMPLATE_COLUMNS,
  EXPORT_DATE_FORMAT,
  OPERATIONS_EMAIL,
  COMPANY_NAME,
  GENERIC_ERROR_MESSAGE,
} from "~/common/consts";
import { createLabelReqFromCsvRow, validateDims } from "~/common/labelHelpers";
import {
  ICsvTemplateRow,
  CreateLabelFormProps,
  CsvUploadStates,
} from "./types";
import {
  getEarliestShipDate,
  CanadaHolidays,
  USHolidays,
} from "@swyft/swyft-common/lib/helpers/ShipDateHelpers";
import { FilterValue, SorterResult } from "antd/lib/table/interface";
import { useNotify, useTranslate } from "react-admin";
import ActionAlert from "~/components/feedback/ActionAlert";
import { useNavigate } from "react-router-dom";
import { Routes } from "~/config/Routes";

const { Step } = Steps;
const { Link, Title, Text } = Typography;

export const ImportCsv = ({
  merchant,
  isMerchantGroup,
}: CreateLabelFormProps) => {
  const notify = useNotify();
  const translate = useTranslate();
  const [csvData, setCsvData] = useState<readonly ICsvTemplateRow[]>([]);
  const [labels, setLabels] = useState<ILabelV2[]>([]);
  const [csvUploadError, setCsvUploadError] = useState<string>("");
  const [csvCreationError, setCsvCreationError] = useState<string>("");
  const [serverCsvErrors, setServerCsvErrors] = useState<
    { rowIndex: number; message: string }[]
  >([]);
  const [clientCsvErrors, setClientCsvErrors] =
    useState<IClientCsvErrorMap | null>(null);
  const [csvUploadState, setCsvUploadState] = useState<CsvUploadStates>(
    CsvUploadStates.NONE,
  );
  const [shipDate, setShipDate] = useState<Moment>({} as Moment);
  const navigate = useNavigate();
  const [pickupLocation, setPickupLocation] = useState<IPickupLocation>(
    {} as IPickupLocation,
  );

  // derived values
  const isOrgActive = merchant.isActive;
  const isInvalid =
    isEmpty(merchant) || isEmpty(pickupLocation) || !isOrgActive;

  const beforeUnloadListener = (e: BeforeUnloadEvent) => {
    e.preventDefault();
    e.returnValue =
      "Warning, labels are being processed. Are you sure you want to leave this page?";
  };

  useEffect(() => {
    if (!isEmpty(merchant)) {
      fetchPickupLocation(merchant.id);
    }
  }, [merchant]);

  useEffect(() => {
    if (!isEmpty(merchant) && !isUndefined(pickupLocation?.cutoffTime)) {
      setShipDate(getEarliestAvailableShipDate());
    }
  }, [merchant, pickupLocation]);

  const fetchPickupLocation = async (merchantId: string) => {
    try {
      const pickupLocationData =
        await FirestoreService.getPickupLocationsForMerchantId(merchantId);
      setPickupLocation(pickupLocationData[0]);
    } catch (err) {
      message.error(GENERIC_ERROR_MESSAGE);
      console.error(err);
    }
  };

  const getFormattedCutoffTime = (cutoffTime: string) => {
    return moment(cutoffTime, DATE_FORMAT.CUT_OFF_TIME);
  };

  const getEarliestAvailableShipDate = () => {
    return getEarliestShipDate(pickupLocation);
  };

  const draggerProps = {
    name: "file",
    accept: ".csv",
    disabled: isInvalid,
    multiple: false,
    showUploadList: false,
    beforeUpload: (file: any, _fileList: any[]) => {
      if (!isOrgActive) {
        notify("shipments.message.create.unactive_fail", {
          type: "warning",
        });
        return;
      }

      const reader = new FileReader();

      reader.onload = () => {
        // @ts-ignore
        const csv = readString(reader.result as string, {
          header: true,
          skipEmptyLines: true,
        }) as unknown as ParseResult<ICsvTemplateRow>;

        const headerErrors = validateTemplateHeader(csv.meta.fields || []);

        if (!csv.data || isEmpty(csv.data)) {
          setCsvUploadError(
            "We could not read your file contents. Please make sure it is well-formatted and that you are using the template.",
          );
        } else if (!isEmpty(headerErrors)) {
          setCsvUploadError(
            `Your CSV is missing the following required columns: ${headerErrors.join(
              ", ",
            )}.`,
          );
        } else {
          setCsvData(csv.data);
          setClientCsvErrors(validateRows(csv.data as ICsvTemplateRow[]));
          setCsvUploadState(CsvUploadStates.CSV_LOADED);
        }
      };
      reader.readAsText(file);

      // Prevent remote upload
      return false;
    },
  };

  const createLabels = async () => {
    if (!isOrgActive) {
      notify("shipments.message.create.unactive_fail", {
        type: "warning",
      });
      return;
    }

    if (isInvalid) {
      notify("shared.message.create.invalid", {
        type: "warning",
        messageArgs: {
          name: translate("resources.labels.name", {
            smart_count: csvData.length,
          }),
          smart_count: csvData.length,
        },
      });
      return;
    }

    setCsvUploadState(CsvUploadStates.LABEL_CREATION_REQUESTED);

    window.addEventListener("beforeunload", beforeUnloadListener);

    const createLabelRequests =
      csvData.map((row: ICsvTemplateRow) =>
        createLabelReqFromCsvRow(
          row,
          shipDate.format(DATE_FORMAT.SHIP_DATE),
          merchant?.contact?.address?.country?.toUpperCase() as CountryISO2,
        ),
      ) || [];

    try {
      validateDims(createLabelRequests);

      let results: any;

      if (isMerchantGroup) {
        const merchantGroupCreateLabelRequest = createLabelRequests.map(
          (createLabelRequest) => {
            return {
              ...createLabelRequest,
              slug: merchant.slug,
            };
          },
        );
        results = await FirestoreService.merchantGroupBatchCreateLabels(
          merchantGroupCreateLabelRequest,
        );
      } else {
        results = await FirestoreService.batchCreateLabels(createLabelRequests);
      }

      const { merchantLabelIds: labelIds, errors: serverCsvErrors } = results;

      if (serverCsvErrors?.length > 0) {
        setServerCsvErrors(serverCsvErrors);
        setCsvUploadState(CsvUploadStates.LABEL_CREATION_ERROR);
      } else {
        const labels: ILabelV2[] = await Promise.all(
          labelIds.map((id: string) =>
            FirestoreService.getLabelByMerchantIdAndLabelId(merchant.id, id),
          ),
        );

        setLabels(labels);
        setCsvUploadState(CsvUploadStates.LABEL_CREATION_RESPONSE);
        window.removeEventListener("beforeunload", beforeUnloadListener);
      }
    } catch (err: any) {
      console.error(err);
      setCsvCreationError(err?.message || err);
      setCsvUploadState(CsvUploadStates.LABEL_CREATION_ERROR);
    }
  };

  const handlePrintLabels = async () => {
    const formattedDate = moment(labels[0].shipDate).format(EXPORT_DATE_FORMAT);
    const fileName = `${COMPANY_NAME.toLowerCase()}_labels_${formattedDate}.pdf`;
    await exportLabelsPDF(labels, fileName);
  };

  const handleSetShipDate = (shipDate: Moment) => {
    setShipDate(shipDate);
  };

  const resetSteps = () => {
    setCsvData([]);
    setLabels([]);
    setCsvUploadError("");
    setClientCsvErrors(null);
    setServerCsvErrors([]);
    setCsvCreationError("");
    setCsvUploadState(CsvUploadStates.NONE);
    setShipDate(getEarliestAvailableShipDate());
  };

  const isInvalidShipDate = (current: Moment) => {
    if (isInvalid) {
      return true;
    }

    const { eligibleShipDays } = pickupLocation;
    const day = current.day();

    return !eligibleShipDays.includes(day);
  };

  const isBeforeEarliestShipDate = (current: Moment) => {
    if (isInvalid) {
      return true;
    }

    const formattedCutoffTime = getFormattedCutoffTime(
      pickupLocation.cutoffTime,
    );
    const earliestShipDate = getEarliestAvailableShipDate();

    return earliestShipDate.isBefore(formattedCutoffTime)
      ? current.isBefore(earliestShipDate.clone().subtract(1, "days"))
      : current.isBefore(earliestShipDate, "day");
  };

  const isHoliday = (current: Moment) => {
    if (isInvalid) {
      return true;
    }

    const { address } = pickupLocation.contact;
    if (address.country === "CA") {
      return CanadaHolidays.has(current.format(DATE_FORMAT.SHIP_DATE));
    } else {
      return USHolidays.has(DATE_FORMAT.SHIP_DATE);
    }
  };

  /**
   * When the table data is sorted, update state csvData
   */
  const handleTableDataChange = (
    _pagination: TablePaginationConfig,
    _filters: Record<string, FilterValue | null>,
    _sorter: SorterResult<ICsvTemplateRow> | SorterResult<ICsvTemplateRow>[],
    extra: { currentDataSource: ICsvTemplateRow[] },
  ): void => {
    setCsvData(extra.currentDataSource);
  };

  return (
    <>
      {isEmpty(pickupLocation) && isOrgActive && (
        <ActionAlert
          alertSeverity="warning"
          alertMessage={translate(
            "location.message.create.bulk_pickup_req_warn",
          )}
          actionText={translate("shared.content.create.title", {
            name: translate("resources.locations.name", { smart_count: 1 }),
          })}
          actionHandler={() => navigate(`${Routes.Locations}/create`)}
        />
      )}
      {isMerchantGroup && (
        <Row>
          <Text style={{ fontSize: "1.6em", marginRight: "0.8em" }}>
            Merchant Name:
          </Text>
          <Input
            style={{ width: "20em" }}
            disabled
            placeholder="Merchant Name"
            value={merchant.name}
          />
        </Row>
      )}
      <Row
        justify="center"
        align="middle"
        gutter={24}
        style={{ paddingTop: "4em", paddingBottom: "4em" }}
      >
        <Col span={18} style={{ textAlign: "center" }}>
          <Steps current={csvUploadState} progressDot>
            <Step title="Upload" description="Upload a CSV" />
            <Step title="Ship date" description="Select a ship date" />
            <Step title="Print" description="Order labels" />
          </Steps>
          {csvUploadState !== CsvUploadStates.NONE && (
            <Button
              style={{ margin: 10 }}
              onClick={resetSteps}
              icon={<RedoOutlined />}
            >
              Reset
            </Button>
          )}
        </Col>
      </Row>

      {csvUploadState === CsvUploadStates.NONE && (
        <>
          {!isEmpty(csvUploadError) && (
            <Row justify="center" align="middle">
              <Col span={16} style={{ textAlign: "center" }}>
                <Alert
                  message="Unable to read your file."
                  description={csvUploadError}
                  type="error"
                  showIcon
                  style={{ marginBottom: "3em" }}
                />
              </Col>
            </Row>
          )}
          <Row justify="center" align="middle">
            <Col span={16} style={{ textAlign: "center" }}>
              <Dragger
                {...draggerProps}
                style={{ paddingTop: "3em", paddingBottom: "3em" }}
              >
                <p className="ant-upload-drag-icon">
                  <InboxOutlined
                    fontSize="large"
                    sx={({ palette }) => ({
                      color: `${
                        isInvalid ? palette.neutral.main : palette.primary.main
                      }`,
                    })}
                  />
                </p>
                <p
                  className={`${
                    isInvalid ? "ant-upload-hint" : "ant-upload-text"
                  }`}
                >
                  Click or drag file to this area to upload
                </p>
                <p className="ant-upload-hint">
                  Please upload a well-formatted CSV file that follows the
                  template
                </p>
              </Dragger>
            </Col>
          </Row>
          <Row justify="center" align="middle" style={{ paddingTop: "3em" }}>
            <Col span={16} style={{ textAlign: "center" }}>
              <Link
                href={`${
                  process.env.PUBLIC_URL
                }/${COMPANY_NAME.toLowerCase()}-labels-template.csv`}
                target="_blank"
                download={`${COMPANY_NAME.toLowerCase()}-labels-template.csv`}
              >
                Click here to download the CSV template
              </Link>
            </Col>
          </Row>
        </>
      )}

      {csvUploadState === CsvUploadStates.CSV_LOADED &&
        (clientCsvErrors && !isEmpty(clientCsvErrors) ? (
          <>
            <Row justify="center" align="middle">
              <Col span={20} style={{ textAlign: "center" }}>
                <Result
                  style={{ paddingTop: 0 }}
                  status="error"
                  title={"Your CSV could not be processed."}
                  subTitle={"Please fix the following errors and re-upload."}
                />
              </Col>
            </Row>
            <Row
              justify="center"
              align="middle"
              style={{ paddingBottom: "3em" }}
            >
              <Col span={10}>
                <List
                  style={{ whiteSpace: "pre" }}
                  bordered
                  dataSource={Object.keys(clientCsvErrors).sort((a, b) =>
                    Number(a) > Number(b) ? 1 : -1,
                  )}
                  renderItem={(rowNum) => (
                    <List.Item>
                      <Typography.Text type="danger">
                        Row {rowNum}{" "}
                      </Typography.Text>
                      {"\n" + clientCsvErrors[rowNum].join("\n")}
                    </List.Item>
                  )}
                />
              </Col>
            </Row>
          </>
        ) : (
          <>
            <Row
              justify="center"
              align="middle"
              style={{ marginBottom: "2em" }}
            >
              <Col span={20}>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Space direction="horizontal">
                    <Title level={4} style={{ margin: 0, color: "#434343" }}>
                      Ship Date:
                    </Title>

                    <DatePicker
                      defaultValue={shipDate}
                      size="large"
                      format="MMM DD, YYYY"
                      value={shipDate}
                      onSelect={(shipDate) => {
                        handleSetShipDate(moment(shipDate));
                      }}
                      disabledDate={(current) =>
                        isBeforeEarliestShipDate(moment(current)) ||
                        isInvalidShipDate(moment(current)) ||
                        isHoliday(moment(current))
                      }
                      style={{ marginRight: "1em" }}
                      clearIcon={null}
                    />
                    <Title level={5} style={{ margin: 0, color: "#434343" }}>
                      Labels will be printed in the sorted order below
                    </Title>
                  </Space>
                  <Button type="primary" size="large" onClick={createLabels}>
                    Create Labels ({csvData.length})
                  </Button>
                </div>
              </Col>
            </Row>
            <Row justify="center" align="middle">
              <Col span={20}>
                <Table
                  rowKey={(row: ICsvTemplateRow) => csvData.indexOf(row)}
                  size="small"
                  dataSource={csvData}
                  columns={CSV_TEMPLATE_COLUMNS}
                  bordered
                  sticky
                  pagination={{
                    pageSize: 50,
                  }}
                  onChange={handleTableDataChange}
                  scroll={{ x: 1500 }}
                />
              </Col>
            </Row>
          </>
        ))}

      {csvUploadState === CsvUploadStates.LABEL_CREATION_REQUESTED && (
        <Row justify="center" align="middle">
          <Col>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                paddingTop: "2em",
              }}
            >
              <Spin size="large" style={{ paddingBottom: "2em" }} />
              <Text style={{ fontSize: "1.5em", color: grey[6] }}>
                Creating your labels... Please do not refresh the page.
              </Text>
            </div>
          </Col>
        </Row>
      )}

      {csvUploadState === CsvUploadStates.LABEL_CREATION_RESPONSE && (
        <>
          {!isEmpty(labels) && (
            <>
              <Row justify="center" align="middle">
                <Result
                  style={{ paddingTop: 0 }}
                  status="success"
                  title={`Successfully created ${labels.length} labels.`}
                />
              </Row>
              <Row justify="center" align="middle">
                <Button
                  type="primary"
                  size="large"
                  key="console"
                  onClick={handlePrintLabels}
                >
                  Print Labels
                </Button>
              </Row>
            </>
          )}
        </>
      )}

      {/** @todo Add better error handling. Need to be able to surface user fixable errors. */}
      {csvUploadState === CsvUploadStates.LABEL_CREATION_ERROR && (
        <Row justify="center" align="middle">
          {serverCsvErrors.length > 0 ? (
            <>
              <Col span={24}>
                <Result
                  style={{ paddingTop: 0 }}
                  status="error"
                  title={"Your CSV could not be processed."}
                  subTitle={
                    "Please fix or remove the following rows and re-upload."
                  }
                />
              </Col>
              <Col span={24}>
                {serverCsvErrors.map((error) => (
                  <Table
                    title={() => (
                      <Text type="danger">
                        Row {error.rowIndex} {error.message}
                      </Text>
                    )}
                    size="small"
                    dataSource={[csvData[error.rowIndex]]}
                    columns={CSV_TEMPLATE_COLUMNS}
                    bordered
                    sticky
                    pagination={false}
                    scroll={{ x: 1500 }}
                  />
                ))}
              </Col>
            </>
          ) : (
            <Result
              status="error"
              title={`Something went wrong creating labels. Please contact ${COMPANY_NAME} Operations at ${OPERATIONS_EMAIL}`}
              subTitle={csvCreationError}
            />
          )}
        </Row>
      )}
    </>
  );
};
