import {
  Box,
  Button,
  Grid,
  LoadingButton,
  styled,
  TextField,
  Typography,
  Breadcrumbs,
  Link,
  TimePicker,
} from "@helo/ui";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  useTranslate,
  useUpdate,
  useEditController,
  DeleteWithConfirmButton,
  useRedirect,
  useRefresh,
  useResourceContext,
  useNotify,
} from "react-admin";
import { useCanAccess } from "@react-admin/ra-rbac";
import { useNavigate, Link as RouterLink } from "react-router-dom";
import { UserRole } from "@swyft/domain/src/types/users";
import { PickupLocation } from "@swyft/domain/src/types/pickup-locations";
import { format, parse, isValid } from "date-fns";

import { Routes } from "~/config/Routes";
import { getLocationEditSchema } from "~/common/validators/locations/LocationEditSchema";
import { useGetIdentity } from "~/services/auth/hooks";
import AppViewLayout from "~/layouts/app/AppViewLayout";
import GracefulFallback from "~/components/feedback/GracefulFallback";
import { AppResource } from "~/config/resources";
import { TIME_DISPLAY_FORMAT } from "~/features/locations/utils";
import Loading from "~/components/feedback/Loading";

const LocationEdit = () => {
  const navigate = useNavigate();
  const [update] = useUpdate();
  const translate = useTranslate();
  const resource = useResourceContext();
  const {
    identity,
    isLoading: isLoadingIdentity,
    error: errorIdentity,
  } = useGetIdentity();
  const {
    record,
    isLoading: isLoadingRecord,
    error: errorRecord,
  } = useEditController({
    resource: AppResource.Location,
    queryOptions: {
      meta: {
        org: {
          id: identity?.organizationId,
        },
      },
      enabled: !isLoadingIdentity && !errorIdentity,
    },
  });
  const notify = useNotify();
  const redirect = useRedirect();
  const refresh = useRefresh();
  const { canAccess } = useCanAccess({
    action: "edit",
    resource,
  });
  const [defaultValues, setDefaultValues] = useState<any>();
  const { register, handleSubmit, formState, reset, control } = useForm({
    defaultValues: defaultValues,
    resolver: yupResolver(getLocationEditSchema(translate)),
    mode: "onBlur",
    reValidateMode: "onBlur",
  });
  const isLoading = isLoadingIdentity || isLoadingRecord;
  const error = errorIdentity || errorRecord;

  useEffect(() => {
    if (record?.slug || record?.name) {
      const parsedCutoffTime = parse(
        record.cutoffTime,
        TIME_DISPLAY_FORMAT,
        new Date(),
      );
      const parsedPickupTime = parse(
        record.pickupTime,
        TIME_DISPLAY_FORMAT,
        new Date(),
      );

      const newDefaultValues = {
        id: record.id,
        name: record.name,
        pickupNotes: record.pickupNotes,
        cutoffTime: isValid(parsedCutoffTime) ? parsedCutoffTime : null,
        pickupTime: isValid(parsedPickupTime) ? parsedPickupTime : null,
        addressLine1: record.contact.address.line1,
        addressLine2: record.contact.address.line2,
        firstName: record.contact.firstName,
        lastName: record.contact.lastName,
        phone: record.contact.phone,
        province: record.contact.address.province,
        country: record.contact.address.country,
        city: record.contact.address.city,
        postalCode: record.contact.address.postalCode,
        coverageAreas: record.coverageAreas ? record.coverageAreas : [],
        countryCode: "+1",
        jobTitle: record.contact.title,
        email: record.contact.email,
      };

      setDefaultValues(newDefaultValues);
      reset(newDefaultValues);
    }
  }, [record]);

  const onSubmit = async (formValues: any) => {
    try {
      const data: Partial<PickupLocation> = {
        id: formValues.id,
        name: formValues.name,
        pickupNotes: formValues.pickupNotes,
        cutoffTime: formValues.cutoffTime
          ? format(formValues.cutoffTime, TIME_DISPLAY_FORMAT)
          : formValues.cutoffTime,
        contact: {
          address: {
            line1: formValues.addressLine1,
            line2: formValues.addressLine2,
            city: formValues.city,
            postalCode: formValues.postalCode,
            province: formValues.province,
            country: formValues.country,
          },
          firstName: formValues.firstName,
          lastName: formValues.lastName,
          title: formValues.jobTitle,
          email: formValues.email,
          phone: formValues.phone,
        },
      };
      const meta = {
        org: {
          id: identity?.organizationId,
        },
      };

      await update(
        AppResource.Location,
        { id: record.id, data, previousData: record, meta },
        { returnPromise: true },
      );
      notify("shared.message.update.ok", {
        type: "success",
        messageArgs: {
          name: translate("resources.locations.name", {
            smart_count: 1,
          }),
          smart_count: 1,
        },
      });
      navigate(Routes.Locations);
    } catch (err: any) {
      notify(
        `${translate("shared.message.submit.fail")}: ${
          err?.message ?? JSON.stringify(err)
        }`,
        { type: "error" },
      );
    }
  };

  const LocationEditForm = () => (
    <Form noValidate onSubmit={handleSubmit(onSubmit)}>
      <TextField
        error={Boolean(formState?.errors?.name)}
        helperText={formState?.errors?.name?.message}
        label={translate("resources.locations.fields.name")}
        {...register("name")}
      />
      <TextField
        multiline
        rows={4}
        error={Boolean(formState?.errors?.pickupNotes)}
        helperText={formState?.errors?.pickupNotes?.message}
        label={translate("resources.locations.fields.pickupNotes")}
        {...register("pickupNotes")}
      />
      <DualColumns>
        <Controller
          control={control}
          name="cutoffTime"
          render={({ field }) => (
            <TimePicker
              {...field}
              onClose={field.onBlur}
              inputFormat={TIME_DISPLAY_FORMAT}
              disableMaskedInput
              label={translate("resources.locations.fields.cutoffTime")}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={Boolean(formState?.errors?.cutoffTime)}
                  helperText={translate(
                    formState?.errors?.cutoffTime?.message ?? "",
                  )}
                />
              )}
            />
          )}
        />
        <Controller
          control={control}
          name="pickupTime"
          render={({ field }) => (
            <TimePicker
              {...field}
              inputFormat={TIME_DISPLAY_FORMAT}
              disableMaskedInput
              disabled
              label={translate("resources.locations.fields.pickupTime")}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={Boolean(formState?.errors?.pickupTime)}
                  helperText={translate(
                    formState?.errors?.pickupTime?.message ?? "",
                  )}
                />
              )}
            />
          )}
        />
      </DualColumns>
      <Typography variant="subtitle1">
        {translate("shared.content.header.addr_info")}
      </Typography>
      <DualColumns>
        <TextField
          label={translate("shared.label.address.line1")}
          defaultValue={defaultValues.addressLine1}
          disabled
        />
        <TextField
          label={translate("shared.label.address.line2")}
          defaultValue={defaultValues.addressLine2}
          disabled
        />
      </DualColumns>
      <DualColumns>
        <TextField
          label={translate("shared.label.address.city")}
          defaultValue={defaultValues.city}
          disabled
        />
        <TextField
          label={translate("shared.label.address.state_province")}
          defaultValue={defaultValues.province}
          disabled
        />
      </DualColumns>
      <DualColumns>
        <TextField
          label={translate("shared.label.address.country")}
          defaultValue={defaultValues.country}
          disabled
        />
        <TextField
          label={translate("shared.label.address.zip_postal")}
          defaultValue={defaultValues.postalCode}
          disabled
        />
      </DualColumns>
      <Typography variant="subtitle1">
        {translate("shared.content.header.contact_info")}
      </Typography>
      <DualColumns>
        <TextField
          error={Boolean(formState?.errors?.firstName)}
          helperText={formState?.errors?.firstName?.message}
          label={translate("resources.locations.fields.contact.firstName")}
          {...register("firstName")}
        />
        <TextField
          error={Boolean(formState?.errors?.lastName)}
          helperText={formState?.errors?.lastName?.message}
          label={translate("resources.locations.fields.contact.lastName")}
          {...register("lastName")}
        />
      </DualColumns>
      <TextField
        error={Boolean(formState?.errors?.jobTitle)}
        helperText={formState?.errors?.jobTitle?.message}
        label={translate("resources.locations.fields.contact.title")}
        {...register("jobTitle")}
      />
      <TextField
        error={Boolean(formState?.errors?.email)}
        helperText={formState?.errors?.email?.message}
        label={translate("resources.locations.fields.contact.email")}
        {...register("email")}
        type={"email"}
      />
      <PhoneContainer>
        <TextField
          error={Boolean(formState?.errors?.countryCode)}
          helperText={formState?.errors?.countryCode?.message}
          label={translate("shared.label.address.countryCode")}
          type="tel"
          disabled
          {...register("countryCode")}
        />
        <TextField
          error={Boolean(formState?.errors?.phone)}
          helperText={formState?.errors?.phone?.message}
          label={translate("resources.locations.fields.contact.phone")}
          type="tel"
          {...register("phone")}
        />
      </PhoneContainer>
      <ActionsContainer>
        <Box>
          {identity?.role === UserRole.ADMIN && (
            <DeleteWithConfirmButton
              confirmTitle={translate("shared.content.delete.title", {
                name: translate("resources.locations.name", {
                  smart_count: 1,
                }),
              })}
              confirmContent={translate("ra.message.delete_content")}
              record={record}
              redirect="list"
              resource={AppResource.Location}
              sx={{ height: "100%" }}
              mutationOptions={{
                onSuccess: (data) => {
                  notify("location.message.delete.ok", {
                    type: "success",
                  });
                  redirect("list", AppResource.Location);
                  refresh();
                },
              }}
            ></DeleteWithConfirmButton>
          )}
        </Box>
        <Box>
          <Button
            type="button"
            variant="text"
            size="large"
            onClick={() => navigate(Routes.Locations)}
            disableElevation
            style={{ textTransform: "none" }}
          >
            {translate("ra.action.cancel")}
          </Button>
          <LoadingButton
            type="submit"
            variant="contained"
            loading={formState?.isSubmitting}
            size="large"
            disableElevation
            style={{ textTransform: "none" }}
          >
            {translate("ra.action.save")}
          </LoadingButton>
        </Box>
      </ActionsContainer>
    </Form>
  );

  return (
    <AppViewLayout
      title={
        record?.name
          ? `${translate("shared.action.manage")} ${record.name}`
          : translate("shared.content.edit.title", {
              name: translate("resources.locations.name", { smart_count: 1 }),
            })
      }
      breadcrumbs={
        <Breadcrumbs>
          <Link color="inherit" underline="none">
            {translate("menu.settings.settings")}
          </Link>
          <Link
            color="inherit"
            underline="hover"
            component={RouterLink}
            to={Routes.Locations}
          >
            {translate("menu.settings.locations")}
          </Link>
          <Link color="text.primary" underline="none">
            {record?.name
              ? `${translate("shared.action.manage")} ${record.name}`
              : translate("shared.content.edit.title", {
                  name: translate("resources.locations.name", {
                    smart_count: 1,
                  }),
                })}
          </Link>
        </Breadcrumbs>
      }
    >
      {isLoading && <Loading />}
      {error && <GracefulFallback type="error" />}
      {!canAccess && !error && (
        <GracefulFallback
          type="blocked-warning"
          details={translate("shared.message.update.fail_role_auth", {
            role: UserRole.ADMIN,
            name: translate("resources.locations.name", { smart_count: 1 }),
          })}
        />
      )}
      {!isLoading && !error && canAccess && defaultValues && (
        <LocationEditForm />
      )}
    </AppViewLayout>
  );
};

const Form = styled("form")(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "1fr",
  width: "400px",
  gap: 20,
  placeContent: "center",
  paddingBottom: 20,
  [theme.breakpoints.up("md")]: {
    width: "600px",
  },
}));

const DualColumns = styled(Grid)({
  display: "grid",
  gridTemplateColumns: "1fr 1fr",
  columnGap: 10,
});

const PhoneContainer = styled(Grid)({
  display: "grid",
  gridTemplateColumns: "1fr 5fr",
  columnGap: 10,
});

const ActionsContainer = styled(Grid)({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
});

export default LocationEdit;
