// This file will be revisited once migration to react admin enterprise is complete
import {
  LocalShippingIcon,
  PeopleIcon,
  SettingsIcon,
  MapIcon,
  StoreIcon,
  MonetizationOnIcon,
  DashboardIcon,
  HelpIcon,
  OpenInNewIcon,
  ReceiptLongIcon,
  Link,
  Box,
  styled,
  useMediaQuery,
  ThemeType,
} from "@helo/ui";
import { MultiLevelMenu } from "@react-admin/ra-navigation";
import { useTranslate } from "react-admin";
import { IfCanAccess } from "@react-admin/ra-rbac";

import CreateLabelIcon from "~/assets/icons/CreateLabelIcon";
import ListIcon from "~/assets/icons/ListIcon";
import { Routes } from "~/config/Routes";
import { AppResource } from "~/config/resources";

const AppSideNav = (props: any) => {
  const CLASSES = {
    navLink: `${CLASS_PREFIX}__nav-link`,
  };
  const translate = useTranslate();
  const isMobile = useMediaQuery((theme: ThemeType) =>
    theme.breakpoints.down("sm"),
  );

  return (
    <MultiLevelMenu
      initialOpen={!isMobile}
      sx={{
        borderRight: "1px solid rgba(0, 0, 0, 0.12)",
        "& .RaMenuRoot-nav": {
          paddingTop: 0,
        },
        // NOTE: The MultiLevelMenu.Item component
        // doesn't let you apply any styles to the root, and the root doesn't have a stable class
        // in a production build, so have to do a general selector instead
        "& .RaMenuRoot-list > div": {
          paddingLeft: 0,
        },
        "& .RaMenuRoot-list .RaMenuItem-nestedList": {
          paddingLeft: 0,
        },
        "& .RaMenuItem-nestedList > div": {
          paddingLeft: 0,
        },
        "& .RaMenuItem-nestedList .RaMenuItem-link": {
          paddingLeft: 6,
        },
        [`& .${CLASSES.navLink} > div`]: {
          paddingLeft: 3,
          paddingRight: 2,
          height: "48px",
          color: "rgba(0, 0, 0, 0.6)",
        },
        [`& .${CLASSES.navLink} .MuiBox-root > span`]: {
          display: "flex",
          alignItems: "center",
        },
        "& .RaMenuItem-root .RaMenuItem-nestedList": {
          paddingLeft: 0,
        },
      }}
    >
      <MultiLevelMenuItem
        to="/"
        label={translate("menu.dashboard")}
        name="dashboard"
        icon={<DashboardIcon />}
      />

      <MultiLevelMenuItem
        to=""
        label={translate("menu.shipments.shipments")}
        name="shipments"
        icon={<LocalShippingIcon />}
      >
        <IfCanAccess resource={AppResource.Shipment} action="list">
          <MultiLevelMenuItem
            end={true}
            to={Routes.Shipments}
            label={translate("menu.shipments.manage")}
            name="shipments.manage"
            icon={<ListIcon />}
          />
        </IfCanAccess>
        <IfCanAccess resource={AppResource.Shipment} action="create">
          <MultiLevelMenuItem
            end={true}
            to={`${Routes.Shipments}/create`}
            label={translate("menu.shipments.create")}
            name="shipments.labels.create"
            icon={<CreateLabelIcon />}
          />
        </IfCanAccess>
      </MultiLevelMenuItem>
      <IfCanAccess resource={AppResource.Report} action="show">
        <MultiLevelMenuItem
          to={Routes.Reports}
          label={translate("menu.reports")}
          name="reports"
          icon={<ReceiptLongIcon />}
        />
      </IfCanAccess>
      <MultiLevelMenuItem
        to=""
        label={translate("menu.settings.settings")}
        name="settings"
        icon={<SettingsIcon />}
      >
        <IfCanAccess resource={AppResource.Zone} action="list">
          <MultiLevelMenuItem
            to="/zones"
            label={translate("menu.settings.coverage")}
            name="settings.zones"
            icon={<MapIcon />}
          />
        </IfCanAccess>
        <IfCanAccess resource={AppResource.Location} action="list">
          <MultiLevelMenuItem
            to={Routes.Locations}
            label={translate("menu.settings.locations")}
            name="settings.locations"
            icon={<StoreIcon />}
          />
        </IfCanAccess>
        <IfCanAccess resource={AppResource.Rate} action="list">
          <MultiLevelMenuItem
            to={Routes.Rates}
            label={translate("menu.settings.rates")}
            name="settings.rates"
            icon={<MonetizationOnIcon />}
          />
        </IfCanAccess>
        <IfCanAccess resource={AppResource.User} action="list">
          <MultiLevelMenuItem
            to={Routes.Users}
            label={translate("menu.settings.users")}
            name="settings.users"
            icon={<PeopleIcon />}
          />
        </IfCanAccess>
      </MultiLevelMenuItem>
      <Link
        variant="body1"
        href="https://swyft.zendesk.com/hc/en-us"
        target="_blank"
        rel="noopener noreferrer"
        className={CLASSES.navLink}
        style={{
          textDecoration: "none",
          color: "inherit",
        }}
      >
        <MultiLevelMenuItem to="/help" as={"div"} name="help">
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              height: "48px",
            }}
          >
            <span>
              <HelpIcon sx={{ marginRight: "18px" }} />
              {translate("menu.help")}
            </span>
            <OpenInNewIcon sx={{ height: "20px", paddingRight: "5px" }} />
          </Box>
        </MultiLevelMenuItem>
      </Link>
    </MultiLevelMenu>
  );
};

const MultiLevelMenuItem = styled(MultiLevelMenu.Item)(({ theme }) => ({
  borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
  height: 48,
  justifyContent: "center",
  "& .RaMenuItem-link": {
    paddingLeft: 24,
    width: "100%",
    height: "100%",
    alignItems: "center",
  },
  "& .RaMenuItem-link.active": {
    background: "rgba(24, 160, 251, 0.08)",
    color: theme.palette.primary.dark,
  },
  "& .MuiTypography-root": {
    marginLeft: 1,
    marginRight: 16,
  },
}));

const CLASS_PREFIX = "app-menu-bar";

export default AppSideNav;
