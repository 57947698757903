import { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@helo/ui";
import { useNavigate } from "react-router-dom";
import { useTranslate } from "react-admin";

import { Routes } from "~/config/Routes";

export interface IConfirmationModalProps {
  visible: boolean;
  title: string;
  content: string;
  navigateTo: Routes;
}
const ConfirmationModal = ({
  visible,
  title,
  content,
  navigateTo,
}: IConfirmationModalProps) => {
  const [open, setOpen] = useState(visible);
  const navigate = useNavigate();
  const translate = useTranslate();

  useEffect(() => {
    setOpen(visible);
  }, [visible]);

  const handleClose = () => {
    setOpen(false);
    if (navigateTo) {
      navigate(navigateTo);
    }
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{content}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>{translate("shared.action.ok")}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationModal;
