import { object, string } from "yup";
import { Translate } from "react-admin";
import { REGEX_PATTERNS } from "~/common/consts";
import { OrganizationType } from "@swyft/domain/src/types/organizations";
import { UserRole, UserStatus } from "@swyft/domain/src/types/users";

export const getUserCreateSchema = (translate: Translate) =>
  object({
    firstName: string()
      .min(2, translate("shared.validation.min"))
      .max(100, translate("shared.validation.max_dynamic", { value: 100 }))
      .required(translate("shared.validation.required")),
    lastName: string()
      .min(2, translate("shared.validation.min"))
      .max(100, translate("shared.validation.max_dynamic", { value: 100 }))
      .required(translate("shared.validation.required")),
    phone: string()
      .matches(
        REGEX_PATTERNS.phone_number,
        translate("shared.validation.invalid"),
      )
      .max(10, translate("shared.validation.max_dynamic", { value: 10 }))
      .required(translate("shared.validation.required")),
    email: string()
      .email(translate("shared.validation.invalid"))
      .max(120, translate("shared.validation.max_dynamic", { value: 120 }))
      .required(translate("shared.validation.required")),
    countryCode: string(),
    role: string()
      .oneOf(
        Object.values(UserRole),
        translate("shared.validation.required_select"),
      )
      .nullable(),
    status: string()
      .oneOf(
        Object.values(UserStatus),
        translate("shared.validation.required_select"),
      )
      .nullable(),
    type: string()
      .oneOf(
        Object.values(OrganizationType),
        translate("shared.validation.required_select"),
      )
      .nullable(),
  });
