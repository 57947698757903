import { DataProvider, GetListResult, useDataProvider } from "react-admin";
import { FirebaseOptions } from "firebase/app";
import { getDownloadURL, ref, getBlob } from "firebase/storage";
import { ZonesController } from "@swyft/domain/src/controllers/zones";
import { MerchantController } from "@swyft/domain/src/controllers/merchant";
import { MerchantZone } from "@swyft/domain/src/types/merchants";
import { ZoneStatus } from "@swyft/domain/src/types/zones";
import { MapZone } from "./types";
import { firebasePublicStorage } from "~/services/firebase";
import { AppResource } from "~/config/resources";

// helper functions

//Merge data retrieved from merchants/{merchantId}/zones subcollection, with the zones list
//to add merchant specific data into each zone object (status, rate) if a corresponding merchant-zone exists

const mergeMerchantZones = (
  zones: MapZone[],
  merchantZones: MerchantZone[],
): MapZone[] => {
  merchantZones.forEach((merchantZone) => {
    const zone = zones.find((el) => merchantZone.zone.id === el.id);

    if (!zone) {
      return;
    }

    zone.status = merchantZone.status;
    zone.rate = merchantZone.rate;

    // check if active merchant-zones merchant specific 90 day trailing OTD data
    if (merchantZone.status !== ZoneStatus.ACTIVE) {
      return;
    }

    zone.trailing90OTDUrl = merchantZone.trailing90OTDUrl
      ? merchantZone.trailing90OTDUrl
      : "";
  });
  return zones;
};

/**
 * Data Provider for the "zones" resource and domain.
 *
 * @see https://github.com/marmelab/react-admin/issues/5476#issuecomment-1165471324 - return types of the core functions cannot be type-restricted at the moment due to the way the generics were written
 */
export default (firebaseConfig: FirebaseOptions): ZonesDataProvider => {
  const zonesController = new ZonesController(firebaseConfig);
  const merchantController = new MerchantController(firebaseConfig);

  return {
    getList: async (resource, params): Promise<GetListResult> => {
      let { data, total } = await zonesController.getList(params);
      let mapZones = data as MapZone[];
      const bucket = process.env.REACT_APP_FIREBASE_PUBLIC_BUCKET;

      if (params.meta?.merchantId) {
        const merchantZones = await merchantController.getZones({
          id: params.meta.merchantId,
        });

        mapZones = mergeMerchantZones(mapZones, merchantZones.data);
      }

      if (params.meta?.getCoverageUrl) {
        const coverageUrls = await Promise.allSettled(
          mapZones.map((zone) => {
            if (!zone.coverageFileUrl) {
              return Promise.reject(null);
            }

            const path = `${bucket}/${zone.coverageFileUrl}`;
            const fileRef = ref(firebasePublicStorage, path);
            return getDownloadURL(fileRef);
          }),
        );

        mapZones.forEach((zone, idx) => {
          if (coverageUrls[idx].status === "fulfilled") {
            //@ts-ignore
            mapZones[idx].coverageFileUrl = coverageUrls[idx].value;
          }
          if (coverageUrls[idx].status === "rejected") {
            //if a url is provided, but the file has been deleted reset property.
            mapZones[idx].coverageFileUrl = null;
          }
        });
      }

      if (params.meta?.get90DayOTDUrl) {
        const trailing90OTDUrls = await Promise.allSettled(
          mapZones.map((zone) => {
            if (zone.status === ZoneStatus.ACTIVE && !zone.trailing90OTDUrl) {
              //no OTD data to be shown for active merchant-zones without a file path
              return null;
            }
            const path = zone.trailing90OTDUrl
              ? `${bucket}/${zone.trailing90OTDUrl}`
              : `${bucket}/reports/90-day-otd/90Day_OTD000000000000.csv`;
            const fileRef = ref(firebasePublicStorage, path);
            return getDownloadURL(fileRef);
          }),
        );
        mapZones.forEach((zone, idx) => {
          if (trailing90OTDUrls[idx].status === "fulfilled") {
            //@ts-ignore
            mapZones[idx].trailing90OTDUrl = trailing90OTDUrls[idx].value;
          }
          if (trailing90OTDUrls[idx].status === "rejected") {
            //if a url is provided, but the file has been deleted reset property.
            mapZones[idx].trailing90OTDUrl = null;
          }
        });
      }

      if (params.meta?.fetchGeoJSON) {
        const polygons = await Promise.allSettled(
          mapZones.map(async (zone) => {
            if (zone.polygonUrl) {
              const path = `${bucket}/${zone.polygonUrl}`;
              const fileRef = ref(firebasePublicStorage, path);
              const blob = await getBlob(fileRef);
              const blobContent = await blob.text();
              return JSON.parse(blobContent);
            }
          }),
        );
        mapZones.forEach((zone, idx) => {
          if (polygons[idx].status === "fulfilled") {
            //@ts-ignore
            mapZones[idx].polygon = polygons[idx].value;
          }
          if (polygons[idx].status === "rejected") {
            //if a url is provided, but the file has been deleted reset property.
            mapZones[idx].polygon = null;
          }
        });
      }
      return { data: mapZones, total };
    },
    getOne: (resource, params) => {
      throw new Error("Function not implemented.");
    },
    getMany: (resource, params) => {
      throw new Error("Function not implemented.");
    },
    getManyReference: (resource, params) => {
      throw new Error("Function not implemented.");
    },
    update: (resource, params) => {
      throw new Error("Function not implemented.");
    },
    updateMany: (resource, params) => {
      throw new Error("Function not implemented.");
    },
    create: (resource, params) => {
      throw new Error("Function not implemented.");
    },
    delete: (resource, params) => {
      throw new Error("Function not implemented.");
    },
    deleteMany: (resource, params) => {
      throw new Error("Function not implemented.");
    },
  };
};

export const useZonesDataProvider = (): [AppResource, ZonesDataProvider] => [
  AppResource.Zone,
  useDataProvider<ZonesDataProvider>(),
];

export interface ZonesDataProvider extends DataProvider {}
