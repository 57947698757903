import {
  DataProvider,
  GetOneParams,
  GetOneResult,
  useDataProvider,
} from "react-admin";
import { FirebaseOptions } from "firebase/app";
import { MerchantController } from "@swyft/domain/src/controllers/merchant";
import { GetRatesParams } from "@swyft/domain/src/controllers/merchant/types";
import { ServiceType } from "@swyft/domain/src/types/labels";

import { DisplayRates, DisplayRateEntries } from "./types";
import { placeholderRates } from "./constants";
import {
  RateCardAddOns,
  RateCardV2,
  RateCard,
  PackageTypeRate,
  Merchant,
} from "@swyft/domain/src/types/merchants";
import { CountryISO2, Currencies } from "@swyft/domain/src/types/common";
import {
  MerchantRequestQuoteParams,
  DeactivateZoneParams,
  ContactSalesParams,
} from "@swyft/domain/src/controllers/merchant/types";
import { AppResource } from "~/config/resources";

//Map rates into a marketplace specific form for rendering rates tables
const ratesMapper = (ratecard: RateCardV2 | RateCard): DisplayRates => {
  const rates: DisplayRates = {
    addOns: [],
    currency: undefined,
    serviceLevel: { NEXTDAY: [], SAMEDAY: [] },
    hasVolumeTiers: false,
  };
  //currency is the same on both v1 & v2
  rates.currency = ratecard.currency;
  //create display addOns for bot v1 & v2 ratecards
  if (ratecard.addOns) {
    const addOns = Object.keys(ratecard.addOns);
    addOns.forEach((item: string) => {
      if (
        ratecard.addOns[item as keyof RateCardAddOns] &&
        typeof ratecard.addOns[item as keyof RateCardAddOns] === "object"
      ) {
        ratecard = ratecard as RateCardV2;
        if (ratecard.addOns[item as keyof RateCardAddOns].active) {
          rates.addOns.push({
            addOn: item,
            rate: ratecard.addOns[item as keyof RateCardAddOns].rate,
          });
        }
      } else {
        ratecard = ratecard as RateCard;
        rates.addOns.push({
          addOn: item,
          rate: ratecard.addOns[item as keyof RateCardAddOns],
        });
      }
    });
  }

  if ("entries" in ratecard) {
    // then merchant has v2 ratecard and rates are tiered via volume and has redelivery rate percentage
    rates.hasVolumeTiers = true;
    rates.redeliveryRatePercent = ratecard.redeliveryRatePercent;
    //create display entries
    ratecard.entries?.forEach((entry: DisplayRateEntries) => {
      if (entry.serviceLevel === ServiceType.SAME_DAY) {
        rates.serviceLevel.SAMEDAY.push(entry);
      }
      if (entry.serviceLevel === ServiceType.NEXT_DAY) {
        rates.serviceLevel.NEXTDAY.push(entry);
      }
    });
  } else {
    //the merchant has a v1 ratecard
    ratecard = ratecard as RateCard;
    //map services to displayRateEntries for corresponeing serviceLevel
    if (ratecard.services) {
      for (let packageSize in ratecard.services.SAMEDAY) {
        rates.serviceLevel.SAMEDAY.push({
          packageSize: packageSize,
          rate: ratecard.services.SAMEDAY[packageSize as keyof PackageTypeRate],
        });
      }
      for (let packageSize in ratecard.services.NEXTDAY) {
        rates.serviceLevel.NEXTDAY.push({
          packageSize: packageSize,
          rate: ratecard.services.NEXTDAY[packageSize as keyof PackageTypeRate],
        });
      }
    }
  }
  return rates;
};

/**
 * Data Provider for the "merchants" resource and domain.
 *
 * @see https://github.com/marmelab/react-admin/issues/5476#issuecomment-1165471324 - return types of the core functions cannot be type-restricted at the moment due to the way the generics were written
 */
export default (firebaseConfig: FirebaseOptions): MerchantsDataProvider => {
  const merchantController = new MerchantController(firebaseConfig);

  return {
    getList: (resource, params) => {
      throw new Error("Function not implemented.");
    },
    getOne: async (resource, params): Promise<GetOneResult> => {
      const { id } = params;
      const merchant = await merchantController.getOne({ id });

      return merchant;
    },
    getMany: (resource, params) => {
      throw new Error("Function not implemented.");
    },
    getManyReference: (resource, params) => {
      throw new Error("Function not implemented.");
    },
    update: (resource, params) => {
      throw new Error("Function not implemented.");
    },
    updateMany: (resource, params) => {
      throw new Error("Function not implemented.");
    },
    create: (resource, params) => {
      throw new Error("Function not implemented.");
    },
    delete: (resource, params) => {
      throw new Error("Function not implemented.");
    },
    deleteMany: (resource, params) => {
      throw new Error("Function not implemented.");
    },
    getRates: async (
      resource: string,
      params: GetRatesParams,
    ): Promise<{ data: DisplayRates }> => {
      //if merchant is unactive use placeholderRates
      if (!params.isActive) {
        if (
          params.country === "CANADA" ||
          params.country === CountryISO2.CANADA
        ) {
          placeholderRates.currency = Currencies.CAD;
        }
        return { data: ratesMapper(placeholderRates) };
      }
      const { data } = await merchantController.getRates({
        merchantId: params.merchantId,
        zoneId: params.zoneId,
      });
      return { data: ratesMapper(data) };
    },
    getZonesList: async (
      resource: string,
      params: GetOneParams,
    ): Promise<GetOneResult> => {
      return await merchantController.getZones(params);
    },
    deactivateZone: async (resource: string, params: DeactivateZoneParams) => {
      try {
        await merchantController.deactivateZone(params);

        return { data: true };
      } catch (err) {
        return Promise.reject(err);
      }
    },
    requestZoneActivation: async (
      resource: string,
      params: MerchantRequestQuoteParams,
    ) => {
      try {
        await merchantController.requestZoneActivation(params);

        return { data: true };
      } catch (err) {
        return Promise.reject(err);
      }
    },
    contactSales: async (resource: string, params: ContactSalesParams) => {
      try {
        await merchantController.contactSales(params);

        return { data: true };
      } catch (err) {
        return Promise.reject(err);
      }
    },
  };
};

export const useMerchantsDataProvider = (): [
  AppResource,
  MerchantsDataProvider,
] => [AppResource.Merchant, useDataProvider<MerchantsDataProvider>()];

export interface MerchantsDataProvider extends DataProvider {
  getRates: (
    resource: string,
    params: GetRatesParams,
  ) => Promise<{ data: DisplayRates }>;
  getZonesList: (
    resource: string,
    params: GetOneParams,
  ) => Promise<GetOneResult>;
  deactivateZone: (
    resource: string,
    params: DeactivateZoneParams,
  ) => Promise<{ data: boolean }>;
  requestZoneActivation: (
    resource: string,
    params: MerchantRequestQuoteParams,
  ) => Promise<{ data: boolean }>;
  contactSales: (
    resource: string,
    params: ContactSalesParams,
  ) => Promise<{ data: boolean }>;
}
