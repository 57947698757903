export const shallowCompare = (obj1: any, obj2: any) =>
  Object.keys(obj1).length === Object.keys(obj2).length &&
  Object.keys(obj1).every(
    (key) => obj2.hasOwnProperty(key) && obj1[key] === obj2[key],
  );

function is(x: unknown, y: unknown) {
  if (x === y) {
    return x !== 0 || y !== 0 || 1 / x === 1 / y;
  } else {
    // eslint-disable-next-line no-self-compare
    return x !== x && y !== y;
  }
}

export const shallowEqual = (objA: any, objB: any) => {
  if (is(objA, objB)) return true;

  if (
    typeof objA !== "object" ||
    objA === null ||
    typeof objB !== "object" ||
    objB === null
  ) {
    return false;
  }

  const keysA = Object.keys(objA);
  const keysB = Object.keys(objB);

  if (keysA.length !== keysB.length) return false;

  for (let i = 0; i < keysA.length; i++) {
    if (
      !Object.prototype.hasOwnProperty.call(objB, keysA[i]) ||
      !is(objA[keysA[i]], objB[keysA[i]])
    ) {
      return false;
    }
  }

  return true;
};

/**
 * format integer to currency based on locale
 * @param {string}  locale - locale of the user
 * @param {number} amount - the amount you want to format
 * @param {string} currency - the currency
 * @param {number} [divider=100] - optional parameter with default set to 100 as it expects value to be in cents
 * @returns {string} locale-based currency string
 */
export const currencyFormatter = (
  locale: string,
  amount: number,
  currency: string,
  divider: number = 100,
): string => {
  const rate = new Intl.NumberFormat(locale, {
    style: "currency",
    currency: currency,
  }).format(amount / divider);
  return rate;
};

export const displayBytes = (bytes: number) => {
  const units = ["bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  let l = 0,
    n = bytes || 0;

  while (n >= 1024 && ++l) {
    n = n / 1024;
  }

  return n.toFixed(n < 10 && l > 0 ? 1 : 0) + " " + units[l];
};

export const capitalizeWords = (words: string) =>
  words
    .split(" ")
    .map((word) => word[0]?.toUpperCase() + word?.substring(1)?.toLowerCase())
    .join(" ");

/**
 * Convert an object of kv pairs into a list of options compatible with
 * the Autocomplete component
 * @see components/ControlledAutocomplete
 *
 * @param keyValueMap object of key value pairs to be converted to options
 * @param capitalize should the label be capitalized?
 * @param filterPredicate predicate to filter the provided list of options first, before conversion
 * @param getValue predicate to get the value from an option object (underlying value of an option). Default -> the value in a kv pair.
 * @param getLabel predicate to get the label from an option object (the displayed label of an option). Default -> the key in a kv pair.
 * @returns array of objects that serve as the options prop for the Autocomplete component
 */
export const getOptions = (
  keyValueMap: object,
  options?: {
    capitalize?: boolean;
    mapKeyToValue?: boolean;
    getValue?: (option: [string, any]) => string;
    getLabel?: (option: [string, any]) => string;
    filterPredicate?: ([key, value]: [string, any]) => boolean;
  },
): SelectOption[] => {
  let { capitalize, mapKeyToValue, getValue, getLabel, filterPredicate } =
    Object.assign(
      {
        capitalize: true,
        mapKeyToValue: false,
        getValue: ([key, value]: [string, any]) => value.toString(),
        getLabel: ([key, value]: [string, any]) => key,
      },
      options,
    );

  if (mapKeyToValue) {
    getValue = ([key, value]: [string, any]) => key.toString();
    getLabel = ([key, value]: [string, any]) => value;
  }

  return Object.entries(keyValueMap)
    .filter(filterPredicate ? filterPredicate : () => true)
    .map(([key, value]) => {
      const label = getLabel([key, value]);

      return {
        value: getValue([key, value]),
        label: capitalize ? capitalizeWords(label) : label.toString(),
      };
    });
};

export type SelectOption = {
  value: string;
  label: string;
};

/**
 * Use when you need to perform an action after waiting a specified time, asynchronously
 * @param ms milliseconds to resolve after
 * @returns A promise that resolves after the specified # of milliseconds
 */
export const resolveAfter = (ms: number) => {
  return new Promise<void>((resolve, reject) => {
    try {
      setTimeout(() => resolve(), ms);
    } catch (e) {
      reject();
    }
  });
};
