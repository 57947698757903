import { Authenticated } from "react-admin";
import CustomError, { ErrorType } from "~/layouts/app/CustomError";

const PageNotFound = () => {
  const error = {
    type: ErrorType.PageNotFound,
    title: "shared.content.errors.page_not_found_title",
    desc: "shared.content.errors.page_not_found_desc",
  };

  return (
    <Authenticated>
      <CustomError error={error} />
    </Authenticated>
  );
};

export default PageNotFound;
