import { FirebaseOptions, initializeApp } from "firebase/app";
import { getFunctions, httpsCallable } from "firebase/functions";
import errorHandler from "../errorHandler";
import { ICreateDSP, IFunctions } from "./types";

const CALLABLES = ["createDSP"];

/**
  Delivery Service Provider Controller
  Use to access DSP Domain logic.
 */
export default class DSPController {
  private functions: IFunctions = {};

  /**
   * Creates DSPController instance with provided config to setup Firebase app.
   * @param {FirebaseOptions} config Firebase configuration to initialize app.
   */
  constructor(config: FirebaseOptions) {
    const firebaseApp = initializeApp(config);
    const firebaseFunctions = getFunctions(firebaseApp);

    for (const callable of CALLABLES) {
      this.functions[callable] = httpsCallable(
        firebaseFunctions,
        `dsps-${callable}`,
      );
    }
  }

  /**
   * Created new user in with the provided account detail information.
   * @param {ICreateDSP} dsp details
   *  @returns {void}
   */
  createDSP = async (dsp: ICreateDSP): Promise<string | Error> => {
    try {
      const response = await this.functions.createDSP(dsp);
      return response.data as string;
    } catch (err: unknown) {
      return errorHandler(err);
    }
  };
}
