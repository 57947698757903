/* eslint-disable import/prefer-default-export */
import {
  collection,
  doc,
  DocumentData,
  Firestore,
  FirestoreDataConverter,
  getDoc,
  getDocs,
  query,
  QueryConstraint,
  where,
  WithFieldValue,
} from "firebase/firestore";
import { ServiceType } from "~/types/labels";
import { Zone } from "../types/zones";

const zonesConverter: FirestoreDataConverter<Zone> = {
  fromFirestore(snapshot) {
    return snapshot.data() as Zone; // TODO: map appropriate values
  },
  toFirestore(model: WithFieldValue<Zone>): DocumentData {
    return model; // TODO: map appropriate values
  },
};

const getZonesCollection = (db: Firestore) =>
  collection(db, "zones").withConverter(zonesConverter);

export class ZoneNotFoundError extends Error {
  constructor(id: string) {
    super(`No zone found for id ${id}`);
  }
}

export const get = async (
  db: Firestore,
  params: {
    id: string;
  },
) => {
  const zoneDocRef = doc(getZonesCollection(db), params.id);
  const zonesDocsSnapshot = await getDoc(zoneDocRef);

  if (zonesDocsSnapshot.exists() && zonesDocsSnapshot.data().active === true) {
    return zonesDocsSnapshot.data();
  }

  throw new ZoneNotFoundError(params.id);
};

export const list = async (
  db: Firestore,
  params: {
    country?: "US" | "CA";
    serviceLevel?: ServiceType;
  } = {},
) => {
  const usersQueryConstraints: QueryConstraint[] = [
    where("active", "==", true), // only show active zones
  ];

  if (params.country) {
    usersQueryConstraints.push(where("country", "==", params.country));
  }

  if (params.serviceLevel) {
    usersQueryConstraints.push(
      where("serviceLevel", "==", params.serviceLevel),
    );
  }

  const zonesQuery = query(getZonesCollection(db), ...usersQueryConstraints);

  const zonesDocsSnapshot = await getDocs(zonesQuery);
  return zonesDocsSnapshot.docs.map((zonesDocSnapshot) =>
    zonesDocSnapshot.data(),
  );
};
