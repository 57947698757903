import { CountryISO2 } from "@swyft/domain/src/types/common";
import { ServiceType } from "@swyft/domain/src/types/labels";
import { Merchant } from "@swyft/domain/src/types/merchants";

export interface CreateLabelFormProps {
  merchant: Merchant;
  isMerchantGroup: boolean;
}

export interface ICreateLabelFormValues {
  firstName: string;
  lastName: string;
  addressLine1: string;
  addressLine2: string;
  city: string;
  postalCode: string;
  province: string;
  country: CountryISO2;
  phoneNumber: string;
  email: string;
  shipDate: string;
  orderNumber?: string;
  notes?: string;
  length: number;
  width: number;
  height: number;
  weight: number;
  signatureRequired: boolean;
  merchantName: string;
}

//CSV types

export interface ICsvTemplateRow {
  First_Name: string;
  Last_Name: string;
  Phone?: string;
  Email?: string;
  Notes?: string;
  Address_Line1: string;
  Address_Line2?: string;
  "City/Town": string;
  "Postal/Zip": string;
  "Province/State": string;
  Country: string;
  Service_Type: ServiceType;
  Order_Number?: string;
  Tracking_Number?: string;
  Package_Weight_LB: number;
  Length_IN: number;
  Width_IN: number;
  Height_IN: number;
  Signature_Required?: string | undefined;
}

export enum CsvUploadStates {
  NONE = 0,
  CSV_LOADED = 1,
  LABEL_CREATION_REQUESTED = 2,
  LABEL_CREATION_RESPONSE = 3,
  LABEL_CREATION_ERROR = 4,
}
