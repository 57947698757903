import {
  Button,
  Grid,
  LoadingButton,
  styled,
  TextField,
  Typography,
  Breadcrumbs,
  Link,
} from "@helo/ui";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslate, useCreate, useNotify } from "react-admin";
import { useCanAccess } from "@react-admin/ra-rbac";
import { useNavigate, Link as RouterLink } from "react-router-dom";
import { useState } from "react";
import { OrganizationType } from "@swyft/domain/src/types/organizations";
import { UserRole } from "@swyft/domain/src/types/users";

import { useAuthenticatedContext } from "~/components/AuthenticatedContext";
import { Routes } from "~/config/Routes";
import { getLocationCreateSchema } from "~/common/validators/locations/LocationCreateSchema";
import ConfirmationModal from "./ConfirmationModal";
import { useGetIdentity } from "~/services/auth/hooks";
import GracefulFallback from "~/components/feedback/GracefulFallback";
import AppViewLayout from "~/layouts/app/AppViewLayout";
import { AppResource } from "~/config/resources";
import Loading from "~/components/feedback/Loading";

const Form = styled("form")(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "1fr",
  width: "400px",
  gap: 20,
  placeContent: "center",
  [theme.breakpoints.up("md")]: {
    width: "600px",
  },
}));

const DualColumns = styled(Grid)({
  display: "grid",
  gridTemplateColumns: "1fr 1fr",
  columnGap: 10,
});

const PhoneContainer = styled(Grid)({
  display: "grid",
  gridTemplateColumns: "1fr 5fr",
  columnGap: 10,
});

const ButtonContainer = styled(Grid)({
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-end",
  gap: 10,
});

const toastDuration = 2000;

const LocationCreate = () => {
  const [create] = useCreate();
  const translate = useTranslate();
  const notify = useNotify();
  const { identity } = useGetIdentity();
  const [confirmationVisible, setConfirmationVisible] = useState(false);
  const { merchant } = useAuthenticatedContext();

  const onSubmit = async (formValues: any) => {
    if (!merchant?.isActive) {
      notify("location.message.create.unactive_fail", {
        type: "warning",
      });
      return;
    }

    try {
      const data = {
        org: {
          id: identity?.organizationId,
          type: OrganizationType.Merchant,
        },
        cutoffTime: formValues.cutoffTime,
        pickupTime: formValues.pickupTime,
        pickupNotes: formValues.pickupNotes,
        coverageAreas: [""],
        name: formValues.name,
        contact: {
          address: {
            line1: formValues.addressLine1,
            line2: formValues.addressLine2,
            city: formValues.city,
            postalCode: formValues.postalCode,
            province: formValues.province,
            country: formValues.country,
          },
          phone: formValues.phone,
          firstName: formValues.firstName,
          lastName: formValues.lastName,
          title: formValues.jobTitle,
          email: formValues.email,
        },
      };

      await create(AppResource.Location, { data }, { returnPromise: true });
      setConfirmationVisible(true);
    } catch (err: any) {
      notify(
        `${translate("shared.message.submit.fail")}: ${
          err?.message ?? JSON.stringify(err)
        }`,
        { type: "warning", autoHideDuration: toastDuration },
      );
    }
  };

  return (
    <AppViewLayout
      variant="centered"
      title={translate("shared.content.create.title", {
        name: translate("resources.locations.name", { smart_count: 1 }),
      })}
      breadcrumbs={
        <Breadcrumbs>
          <Link color="inherit" underline="none">
            {translate("menu.settings.settings")}
          </Link>
          <Link
            color="inherit"
            underline="hover"
            component={RouterLink}
            to={Routes.Locations}
          >
            {translate("menu.settings.locations")}
          </Link>
          <Link color="text.primary" underline="none">
            {translate("shared.content.create.title", {
              name: translate("resources.locations.name", { smart_count: 1 }),
            })}
          </Link>
        </Breadcrumbs>
      }
    >
      <LocationCreateForm submitHandler={onSubmit} />
      <ConfirmationModal
        visible={confirmationVisible}
        title={translate("shared.message.submit_request.ok")}
        content={translate("location.message.create.post_submit_desc")}
        navigateTo={Routes.Locations}
      />
    </AppViewLayout>
  );
};

const LocationCreateForm = ({ submitHandler }: LocationCreateFormProps) => {
  const translate = useTranslate();
  const navigate = useNavigate();
  const defaultValues: any = {};
  const { register, handleSubmit, formState } = useForm({
    defaultValues: defaultValues,
    resolver: yupResolver(getLocationCreateSchema(translate)),
    mode: "onChange",
  });
  const { isLoading: isIdentityLoading, error: isIdentityError } =
    useGetIdentity();
  const { canAccess, isLoading: isAccessLoading } = useCanAccess({
    action: "create",
    resource: AppResource.Location,
  });

  if (isIdentityLoading || isAccessLoading) {
    return <Loading />;
  }

  if (isIdentityError) {
    return <GracefulFallback type="error" />;
  }

  if (!canAccess) {
    return (
      <GracefulFallback
        type="blocked-warning"
        details={translate("shared.message.create.fail_role_auth", {
          role: UserRole.ADMIN,
          name: translate("resources.locations.name", { smart_count: 1 }),
        })}
      />
    );
  }

  return (
    <Form noValidate onSubmit={handleSubmit(submitHandler)}>
      <TextField
        error={Boolean(formState?.errors?.name)}
        helperText={formState?.errors?.name?.message}
        label={translate("resources.locations.fields.name")}
        {...register("name")}
      />
      <TextField
        multiline
        rows={4}
        error={Boolean(formState?.errors?.pickupNotes)}
        helperText={formState?.errors?.pickupNotes?.message}
        label={translate("resources.locations.fields.pickupNotes")}
        {...register("pickupNotes")}
      />
      <Typography variant="subtitle1" fontWeight={600}>
        {translate("shared.content.header.addr_info")}
      </Typography>
      <DualColumns>
        <TextField
          error={Boolean(formState?.errors?.addressLine1)}
          helperText={formState?.errors?.addressLine1?.message}
          label={translate("shared.label.address.line1")}
          {...register("addressLine1")}
        />
        <TextField
          label={translate("shared.label.address.line2")}
          error={Boolean(formState?.errors?.addressLine2)}
          helperText={formState?.errors?.addressLine2?.message}
          {...register("addressLine2")}
        />
      </DualColumns>
      <DualColumns>
        <TextField
          label={translate("shared.label.address.city")}
          error={Boolean(formState?.errors?.city)}
          helperText={formState?.errors?.city?.message}
          {...register("city")}
        />
        <TextField
          label={translate("shared.label.address.state_province")}
          error={Boolean(formState?.errors?.province)}
          helperText={formState?.errors?.province?.message}
          {...register("province")}
        />
      </DualColumns>
      <DualColumns>
        <TextField
          label={translate("shared.label.address.country")}
          error={Boolean(formState?.errors?.country)}
          helperText={formState?.errors?.country?.message}
          {...register("country")}
        />
        <TextField
          label={translate("shared.label.address.zip_postal")}
          error={Boolean(formState?.errors?.postalCode)}
          helperText={formState?.errors?.postalCode?.message}
          {...register("postalCode")}
        />
      </DualColumns>
      <Typography variant="subtitle1" fontWeight={600}>
        {translate("shared.content.header.contact_info")}
      </Typography>
      <DualColumns>
        <TextField
          error={Boolean(formState?.errors?.firstName)}
          helperText={formState?.errors?.firstName?.message}
          label={translate("resources.locations.fields.contact.firstName")}
          {...register("firstName")}
        />
        <TextField
          error={Boolean(formState?.errors?.lastName)}
          helperText={formState?.errors?.lastName?.message}
          label={translate("resources.locations.fields.contact.lastName")}
          {...register("lastName")}
        />
      </DualColumns>
      <TextField
        error={Boolean(formState?.errors?.jobTitle)}
        helperText={formState?.errors?.jobTitle?.message}
        label={translate("resources.locations.fields.contact.title")}
        {...register("jobTitle")}
      />
      <TextField
        error={Boolean(formState?.errors?.email)}
        helperText={formState?.errors?.email?.message}
        label={translate("resources.locations.fields.contact.email")}
        {...register("email")}
        type={"email"}
      />
      <PhoneContainer>
        <TextField
          error={Boolean(formState?.errors?.countryCode)}
          helperText={formState?.errors?.countryCode?.message}
          label={translate("shared.label.address.countryCode")}
          type="tel"
          name="countryCode"
          disabled
          defaultValue={"+1"}
        />
        <TextField
          error={Boolean(formState?.errors?.phone)}
          helperText={formState?.errors?.phone?.message}
          label={translate("resources.locations.fields.contact.phone")}
          type="tel"
          {...register("phone")}
        />
      </PhoneContainer>
      <ButtonContainer>
        <Button
          type="submit"
          variant="text"
          size="large"
          onClick={() => navigate(Routes.Locations)}
          disableElevation
          style={{ textTransform: "none" }}
        >
          {translate("ra.action.cancel")}
        </Button>
        <LoadingButton
          type="submit"
          variant="contained"
          loading={formState?.isSubmitting}
          size="large"
          disableElevation
          style={{ textTransform: "none" }}
        >
          {translate("shared.action.submit_request")}
        </LoadingButton>
      </ButtonContainer>
    </Form>
  );
};

interface LocationCreateFormProps {
  submitHandler: (formValues: any) => Promise<void>;
}

export default LocationCreate;
