import {
  Button,
  Grid,
  LoadingButton,
  styled,
  TextField,
  Breadcrumbs,
  Link,
  Box,
  Chip,
} from "@helo/ui";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  useTranslate,
  useUpdate,
  useEditController,
  useNotify,
  DeleteWithConfirmButton,
  Loading,
} from "react-admin";
import { IfCanAccess } from "@react-admin/ra-rbac";
import { useNavigate, Link as RouterLink } from "react-router-dom";
import { User } from "@swyft/domain/src/types/users";
import { OrganizationType } from "@swyft/domain/src/types/organizations";

import ControlledAutocomplete from "~/components/inputs/ControlledAutocomplete";
import { getUserCreateSchema } from "~/common/validators/user/UserCreateSchema";
import { getOptions, shallowCompare } from "~/common/helpers";
import { Routes } from "~/config/Routes";
import { UserUpdateForm } from "./types";
import { useAuthenticatedContext } from "~/components/AuthenticatedContext";
import AppViewLayout from "~/layouts/app/AppViewLayout";
import { AppResource } from "~/config/resources";
import { UserRoleOptions, UserStatusOptions } from "~/common/consts";

const UserEdit = () => {
  const { record } = useEditController<User>({
    resource: AppResource.User,
  });
  const navigate = useNavigate();
  const translate = useTranslate();
  const notify = useNotify();
  const [update] = useUpdate();
  const { identity, merchant } = useAuthenticatedContext();

  const onSubmit = async (
    formValues: UserUpdateForm,
    defaultValues: UserUpdateForm,
  ) => {
    if (shallowCompare(defaultValues, formValues)) {
      notify("shared.message.update.no_changes", {
        type: "warning",
      });
      return;
    }
    try {
      const { role, ...filteredFormValues } = formValues;

      await update(
        AppResource.User,
        {
          id: filteredFormValues.id,
          data: filteredFormValues,
          meta: {
            org: {
              type: !!merchant
                ? OrganizationType.Merchant
                : OrganizationType.DeliveryServicePartner,
              id: identity?.organizationId,
              role: role,
            },
          },
        },
        { returnPromise: true },
      );
      notify("shared.message.update.ok", {
        type: "success",
        messageArgs: {
          name: translate("resources.users.name", { smart_count: 1 }),
          smart_count: 1,
        },
      });
      navigate(Routes.Users);
    } catch (err: any) {
      notify(
        `${translate("shared.message.update.fail", {
          name: translate("resources.users.name", { smart_count: 1 }),
          smart_count: 1,
        })} ${err?.message ?? JSON.stringify(err)}`,
        { type: "warning" },
      );
    }
  };

  return (
    <AppViewLayout
      title={translate("shared.content.edit.title", {
        name: translate("resources.users.name", { smart_count: 1 }),
      })}
      breadcrumbs={
        <Breadcrumbs>
          <Link underline="none" color="inherit">
            {translate("menu.settings.settings")}
          </Link>
          <Link
            underline="hover"
            color="inherit"
            component={RouterLink}
            to={Routes.Users}
          >
            {translate("menu.settings.users")}
          </Link>
          <Link underline="none" color="text.primary">
            {translate("ra.action.edit")} {record?.email}
            {identity?.id === record?.id && (
              <Chip
                sx={{ ml: 1 }}
                label={translate("shared.content.you")}
                variant="filled"
                size="small"
                color="neutral"
              />
            )}
          </Link>
        </Breadcrumbs>
      }
    >
      <UserEditForm submitHandler={onSubmit} />
    </AppViewLayout>
  );
};

const UserEditForm = ({
  submitHandler,
}: {
  submitHandler: (
    values: UserUpdateForm,
    defaultValues: UserUpdateForm,
  ) => Promise<void>;
}) => {
  const { record, isLoading } = useEditController<User>({
    resource: AppResource.User,
  });
  const translate = useTranslate();
  const navigate = useNavigate();
  const notify = useNotify();
  const { identity, merchant } = useAuthenticatedContext();
  const defaultValues: UserUpdateForm = {
    id: record?.id ?? "",
    firstName: record?.firstName ?? "",
    lastName: record?.lastName ?? "",
    email: record?.email ?? "",
    role: record?.role ?? "",
    phone: record?.phone ?? "",
    countryCode: record?.countryCode ?? "+1",
    status: record?.status ?? "",
  };
  const { register, handleSubmit, formState, control, reset, setValue } =
    useForm({
      defaultValues,
      resolver: yupResolver(getUserCreateSchema(translate)),
      mode: "onChange",
    });

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Form
      noValidate
      onSubmit={handleSubmit((formValues) =>
        submitHandler(formValues, defaultValues),
      )}
    >
      <DualColumns>
        <TextField
          error={Boolean(formState?.errors?.firstName)}
          helperText={formState?.errors?.firstName?.message}
          label={translate("resources.users.fields.firstName")}
          {...register("firstName")}
          name="firstName"
        />
        <TextField
          error={Boolean(formState?.errors?.lastName)}
          helperText={formState?.errors?.lastName?.message}
          label={translate("resources.users.fields.lastName")}
          {...register("lastName")}
          name="lastName"
        />
      </DualColumns>

      <TextField
        error={Boolean(formState?.errors?.email)}
        helperText={formState?.errors?.email?.message}
        label={translate("resources.users.fields.email")}
        type="email"
        {...register("email")}
        name="email"
      />
      <DualColumns>
        <ControlledAutocomplete
          control={control}
          options={UserRoleOptions}
          name="role"
          rules={{ required: true }}
          error={Boolean(formState?.errors?.role)}
          helperText={formState?.errors?.role?.message}
          label={translate("resources.users.fields.role")}
        />
        <ControlledAutocomplete
          control={control}
          options={UserStatusOptions}
          name="status"
          rules={{ required: true }}
          error={Boolean(formState?.errors?.status)}
          helperText={formState?.errors?.status?.message}
          label={translate("resources.users.fields.status")}
        />
      </DualColumns>
      <PhoneContainer>
        <TextField
          error={Boolean(formState?.errors?.countryCode)}
          helperText={formState?.errors?.countryCode?.message}
          label={translate("resources.users.fields.countryCode")}
          type="tel"
          disabled
          {...register("countryCode")}
          name="countryCode"
        />
        <TextField
          error={Boolean(formState?.errors?.phone)}
          helperText={formState?.errors?.phone?.message}
          label={translate("resources.users.fields.phone")}
          type="tel"
          {...register("phone")}
          name="phone"
        />
      </PhoneContainer>
      <ButtonContainer>
        <IfCanAccess action="delete">
          <DeleteWithConfirmButton
            disabled={!record || identity?.id === record?.id}
            confirmTitle={`${translate("ra.action.remove")} ${record?.email}`}
            confirmContent={translate("shared.message.remove.confirm_desc", {
              name: record?.email,
            })}
            record={record}
            label={"ra.action.remove"}
            sx={{ height: "100%" }}
            mutationOptions={{
              meta: {
                org: {
                  id: identity?.organizationId,
                  type: !!merchant
                    ? OrganizationType.Merchant
                    : OrganizationType.DeliveryServicePartner,
                },
              },
              onSuccess: () => {
                navigate(Routes.Users);
                notify("shared.message.remove.ok", {
                  messageArgs: {
                    smart_count: 1,
                    name: translate("resources.users.name", {
                      smart_count: 1,
                    }),
                  },
                });
              },
            }}
          ></DeleteWithConfirmButton>
        </IfCanAccess>
        <Box>
          <Button
            type="button"
            variant="text"
            size="large"
            onClick={() => {
              reset();
              navigate(Routes.Users);
            }}
            disableElevation
            sx={{ textTransform: "none" }}
          >
            {translate("ra.action.cancel")}
          </Button>
          <LoadingButton
            type="submit"
            variant="contained"
            loading={formState?.isSubmitting}
            size="large"
            disableElevation
            sx={{ textTransform: "none", marginLeft: 1 }}
          >
            {translate("ra.action.save")}
          </LoadingButton>
        </Box>
      </ButtonContainer>
    </Form>
  );
};

const Form = styled("form")(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "1fr",
  width: "400px",
  gap: 40,
  placeContent: "center",
  paddingBlock: 40,
  [theme.breakpoints.up("md")]: {
    width: "600px",
  },
}));

const DualColumns = styled(Grid)(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "1fr 1fr",
  columnGap: 10,
}));

const PhoneContainer = styled(Grid)(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "1fr 5fr",
  columnGap: 10,
}));

const ButtonContainer = styled(Grid)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  gap: 10,
}));

export default UserEdit;
