import {
  signInWithEmailAndPassword,
  signOut,
  getIdTokenResult,
  ParsedToken,
  User,
} from "firebase/auth";
import { AuthProvider, UserIdentity as RaUserIdentity } from "react-admin";
import * as Sentry from "@sentry/react";

import errorHandler from "./errorHandler";
import { firebaseAuth } from "../firebase";
import { getPermissionsForUser, Permissions } from "./permissions";
import { UserRole } from "@swyft/domain/src/types/users";
import { resetUserOnLogout, setUserOnLogin } from "../analytics";

const ERROR_NOT_LOGGED_IN = "auth/not-logged-in";
const ERROR_INVALID_ROLE = "auth/invalid-role";

export interface Claims extends ParsedToken {
  role: UserRole;
  organizationId?: string;
}

export type UserIdentity = Pick<
  RaUserIdentity,
  "fullName" | "avatar" | "email"
> & {
  id: string;
  role?: UserRole;
  organizationId?: string;
  emailVerified: boolean;
};

let permissions: Permissions;
let role: UserRole | undefined;
let organizationId: string | undefined;

const setClaims = (user: User, claims: Claims): void => {
  const { role: claimsRole, organizationId: claimsOrganizationId } = claims;
  // REVIEW: the role is temporarily being normalized to lowercase to match the UserRole enum,
  // till the Firebase trigger to keep the claims in-sync with the user object is written
  let claimsRoleNormalized = claimsRole?.toLowerCase() as UserRole;

  if (claimsRole) {
    if (
      claimsRoleNormalized !== UserRole.ADMIN &&
      claimsRoleNormalized !== UserRole.MEMBER
    ) {
      throw new Error(ERROR_INVALID_ROLE);
    }

    permissions = getPermissionsForUser(
      user.uid,
      claimsRoleNormalized,
      claimsOrganizationId,
    );
    role = claimsRoleNormalized;
  } else {
    role = undefined;
  }

  organizationId = claimsOrganizationId ?? undefined;
};

const getCurrentUser = async (): Promise<User | null> => {
  return new Promise((resolve) => {
    if (firebaseAuth.currentUser) {
      return resolve(firebaseAuth.currentUser);
    }

    const unsubscribe = firebaseAuth.onAuthStateChanged((user) => {
      unsubscribe();
      return resolve(user);
    });
  });
};

const authProvider: AuthProvider = {
  login: async (params: any): Promise<void> => {
    const { email, password } = params;
    const { user } = await signInWithEmailAndPassword(
      firebaseAuth,
      email,
      password,
    );

    // get permissions for logged-in user
    const { claims } = await getIdTokenResult(user);
    setClaims(user, claims as Claims);

    // set logging identifier
    Sentry.setUser({
      id: user.uid,
      email: user.email ?? undefined,
    });

    // set analytics identifier
    setUserOnLogin({
      id: user.uid,
      email: user.email ?? undefined,
      name: user.displayName ?? undefined,
    });
  },
  logout: async () => {
    await signOut(firebaseAuth);

    // reset claims
    role = undefined;
    organizationId = undefined;

    // reset logging
    Sentry.setUser(null);

    // reset analytics
    resetUserOnLogout();
  },
  checkAuth: async (): Promise<void> => {
    const user = await getCurrentUser();

    if (!user) {
      return Promise.reject();
    }

    return Promise.resolve();
  },
  checkError: (error: {
    message: string;
    status: number;
    body: Object;
  }): Promise<void> => {
    const { message, status } = error;
    if (status === 401 || status === 403) {
      return Promise.reject(message);
    }
    return Promise.resolve();
  },
  getIdentity: async (): Promise<UserIdentity> => {
    const user = await getCurrentUser();

    if (!user) {
      throw Error(ERROR_NOT_LOGGED_IN);
    }

    // try to refresh claims if necessary claims info isn't there
    if (role === undefined || organizationId === undefined) {
      let { claims } = await getIdTokenResult(user, true);

      setClaims(user, claims as Claims);
    }

    const { uid, displayName, photoURL } = user;
    return {
      id: uid,
      fullName: displayName ?? undefined,
      avatar: photoURL ?? undefined,
      email: user.email,
      role,
      organizationId,
      emailVerified: user.emailVerified,
    };
  },

  getPermissions: async (): Promise<Permissions | null> => {
    try {
      const user = await getCurrentUser();

      if (!user) {
        throw new Error(ERROR_NOT_LOGGED_IN);
      }

      // try to refresh claims if necessary claims info isn't there
      if (role === undefined || organizationId === undefined) {
        let { claims } = await getIdTokenResult(user, true);

        setClaims(user, claims as Claims);
      }

      return getPermissionsForUser(user.uid, role, organizationId);
    } catch (error: unknown) {
      return errorHandler(error);
    }
  },

  refreshPermissions: async () => {
    try {
      const user = await getCurrentUser();

      if (!user) {
        throw new Error(ERROR_NOT_LOGGED_IN);
      }

      const { claims } = await getIdTokenResult(user, true);
      setClaims(user, claims as Claims);
    } catch (error) {
      return errorHandler(error);
    }
  },
};

export default authProvider;
