import { TextField, StandardTextFieldProps, MenuItem } from "../..";

interface CountryCodeFieldProps extends StandardTextFieldProps {}

const CountryCodeField = ({ error = false }: CountryCodeFieldProps) => {
  const countriesCode = ["+1"];

  return (
    <TextField
      select
      fullWidth
      id="countrycode"
      label="Code"
      name="countrycode"
      defaultValue={countriesCode[0]}
      error={error}
    >
      {countriesCode.map((name) => (
        <MenuItem key={name} value={name}>
          {name}
        </MenuItem>
      ))}
    </TextField>
  );
};

export default CountryCodeField;
