import { FirebaseOptions, initializeApp } from "firebase/app";
import { Firestore, getFirestore } from "firebase/firestore";

import { get, list } from "../../models/zones";
import { ZonesGetListParams, ZonesGetOneParams } from "./types";

export default class ZonesController {
  private db: Firestore;

  /**
   * Creates ZonesController instance with provided config to setup Firebase app.
   * @param {FirebaseOptions} config Firebase configuration to initialize app.
   */
  constructor(config: FirebaseOptions) {
    const firebaseApp = initializeApp(config);
    this.db = getFirestore(firebaseApp);
  }

  /**
   * Gets a list of zones
   * @param params.filter filter conditions to narrow down the list of zones
   * @returns a list of zone objects, along with a total count after any provided filters were applied
   */
  getList = async (params: ZonesGetListParams = {}) => {
    const zones = await list(this.db, {
      country: params.filter?.country,
      serviceLevel: params.filter?.serviceLevel,
    });

    return { data: zones, total: zones.length };
  };

  /**
   * Gets a single zone
   * @param params.id the id of the zone to get
   * @returns the zone object
   */
  getOne = async (params: ZonesGetOneParams) => {
    const zone = await get(this.db, {
      id: params.id,
    });

    return { data: zone };
  };
}
