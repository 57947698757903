export enum AppResource {
  User = "users",
  Merchant = "merchants",
  DSP = "dsps",
  Location = "locations",
  Rate = "rates",
  Shipment = "shipments",
  Zone = "zones",
  Report = "reports",
}
