import { Grid, LoadingButton, styled, TextField } from "@helo/ui";
import { yupResolver } from "@hookform/resolvers/yup";
import { MerchantCreateOneParams } from "@swyft/domain/src/controllers/merchant";
import { CountryISO2 } from "@swyft/domain/src/types/common";
import { useNavigate } from "react-router-dom";
import { useTranslate, useNotify, useAuthProvider } from "react-admin";
import { useForm } from "react-hook-form";

import ControlledAutocomplete from "~/components/inputs/ControlledAutocomplete";
import { PackageSize, PackageWeight, MonthlyVolume } from "~/constants/form";
import { getMerchantSchema } from "~/common/validators/ftux/merchantSchema";
import { Routes } from "~/config/Routes";
import { useDomainContext } from "~/components/DomainContext";
import {
  CountryOptions,
  MonthlyVolumeOptions,
  PackageSizeOptions,
  PackageWeightOptions,
} from "~/common/consts";

const MerchantForm = () => {
  const translate = useTranslate();
  const { merchantController } = useDomainContext();
  const defaultValues: MerchantForm = {
    country: "" as CountryISO2,
    companyName: "",
    jobTitle: "",
    monthlyVolume: "" as MonthlyVolume,
    packageSize: "" as PackageSize,
    packageWeight: "" as PackageWeight,
  };
  const { register, handleSubmit, formState, control } = useForm({
    defaultValues: defaultValues,
    resolver: yupResolver(getMerchantSchema(translate)),
    mode: "onChange",
  });
  const navigate = useNavigate();
  const notify = useNotify();

  const authProvider = useAuthProvider();

  const onSubmit = async (formValues: MerchantForm) => {
    try {
      const merchantMap: MerchantCreateOneParams = {
        companyName: formValues.companyName,
        jobTitle: formValues.jobTitle,
        monthlyVolume: formValues.monthlyVolume,
        packageSize: formValues.packageSize,
        packageWeight: formValues.packageWeight,
        country: formValues.country,
      };
      await merchantController.create(merchantMap);
      await authProvider.refreshPermissions();

      notify("shared.message.submit.ok", {
        type: "success",
      });

      navigate(Routes.Dashboard, {
        state: {
          waitUntilUserUpdate: true,
        },
      });
    } catch (err: any) {
      notify(
        `${translate("shared.message.submit.fail")}: ${
          err?.message ?? JSON.stringify(err)
        }`,
        { type: "warning" },
      );
    }
  };

  return (
    <Form noValidate onSubmit={handleSubmit(onSubmit)}>
      <TextField
        error={Boolean(formState?.errors?.companyName)}
        helperText={formState?.errors?.companyName?.message}
        label={translate("Company Name")}
        {...register("companyName")}
        name="companyName"
      />
      <TextField
        error={Boolean(formState?.errors?.jobTitle)}
        helperText={formState?.errors?.jobTitle?.message}
        label={translate("Job Title")}
        {...register("jobTitle")}
        name="jobTitle"
      />
      <ControlledAutocomplete
        control={control}
        options={CountryOptions}
        name="country"
        rules={{ required: true }}
        error={Boolean(formState?.errors?.country)}
        helperText={formState?.errors?.country?.message}
        label={translate("Country")}
        placeholder={translate("shared.form.placeholder.select_country")}
      />
      <ControlledAutocomplete
        control={control}
        options={MonthlyVolumeOptions}
        name="monthlyVolume"
        rules={{ required: true }}
        error={Boolean(formState?.errors?.monthlyVolume)}
        helperText={formState?.errors?.monthlyVolume?.message}
        label={translate("Monthly Shipping Volume")}
        placeholder={translate("shared.form.placeholder.select_volume")}
      />
      <ControlledAutocomplete
        control={control}
        options={PackageSizeOptions}
        name="packageSize"
        rules={{ required: true }}
        error={Boolean(formState?.errors?.packageSize)}
        helperText={formState?.errors?.packageSize?.message}
        label={translate("Average Package Size")}
        placeholder={translate("shared.form.placeholder.select_size")}
      />
      <ControlledAutocomplete
        control={control}
        options={PackageWeightOptions}
        name="packageWeight"
        rules={{ required: true }}
        error={Boolean(formState?.errors?.packageWeight)}
        helperText={formState?.errors?.packageWeight?.message}
        label={translate("Average Package Weight")}
        placeholder={translate("shared.form.placeholder.select_weight")}
      />
      <ButtonContainer>
        <LoadingButton
          type="submit"
          variant="contained"
          loading={formState?.isSubmitting}
          size="large"
        >
          {translate("Save")}
        </LoadingButton>
      </ButtonContainer>
    </Form>
  );
};

const Form = styled("form")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  margin: "0 auto",
  paddingRight: "16px",
  paddingLeft: "16px",
  gap: 40,
  [theme.breakpoints.up("md")]: {
    maxWidth: "500px",
    paddingRight: 0,
    paddingLeft: 0,
  },
}));

const ButtonContainer = styled(Grid)({
  display: "grid",
});

// TODO: this interface needs to check against the key of the enum instead of the value
interface MerchantForm {
  country: CountryISO2;
  companyName: string;
  jobTitle: string;
  monthlyVolume: MonthlyVolume;
  packageSize: PackageSize;
  packageWeight: PackageWeight;
}

export default MerchantForm;
