import { AppLocationContext } from "@react-admin/ra-navigation";
import { Layout } from "react-admin";
import { useLocation } from "react-router-dom";

import AppHeaderNav from "~/components/navigation/AppHeaderNav";
import AppSideNav from "~/components/navigation/AppSideNav";
import { GlobalComponentContextProvider } from "~/components/GlobalComponentContext";
import GlobalAlerts from "~/components/feedback/GlobalAlerts";
import UnhandledError from "~/components/feedback/UnhandledError";
import ContactSalesDialog from "~/components/forms/ContactSalesDialog";
import LoadingOverlay from "~/components/feedback/LoadingOverlay";
import {
  useHasOrgAssociationOnUserUpdate,
  useIsVerified,
} from "~/services/auth/hooks";
import AuthenticatedLayout from "~/layouts/app/AuthenticatedLayout";
import Loading from "~/components/feedback/Loading";

const AppLayout = (props: any) => {
  return (
    <AuthenticatedLayout>
      <AppLayoutCore {...props} />
    </AuthenticatedLayout>
  );
};

const AppLayoutCore = (props: any) => {
  // check if logged-in user has verified email
  useIsVerified();
  const location = useLocation();
  const locationState = (location.state || {}) as {
    waitUntilUserUpdate: boolean;
  };
  // check if logged-in user is associated with an organization
  const { isLoading } = useHasOrgAssociationOnUserUpdate(
    locationState.waitUntilUserUpdate,
  );

  if (isLoading) {
    return <Loading loadingPrimary="shared.message.loading.valid" />;
  }

  return (
    <GlobalComponentContextProvider>
      <AppLocationContext>
        <Layout
          {...props}
          appBar={AppHeaderNav}
          menu={AppSideNav}
          error={UnhandledError}
          sx={{
            "& .RaLayout-content": {
              padding: 0,
            },
          }}
        />
        <GlobalAlerts />
        <ContactSalesDialog />
        <LoadingOverlay />
      </AppLocationContext>
    </GlobalComponentContextProvider>
  );
};

export default AppLayout;
