import { useRedirect } from "react-admin";
import { ReactNode, useEffect } from "react";
import { styled } from "@helo/ui";

import { useAuthenticatedContext } from "~/components/AuthenticatedContext";
import BaseLayout from "~/layouts/BaseLayout";
import { Routes } from "~/config/Routes";
import { useIsVerified } from "~/services/auth/hooks";
import AuthenticatedLayout from "~/layouts/app/AuthenticatedLayout";
import Navbar from "~/components/navigation/navbar";
import Footer from "~/components/navigation/footer";

const AppShellLayout = ({ children }: AppShellLayoutProps) => {
  return (
    <AuthenticatedLayout>
      <AppShellLayoutCore>{children}</AppShellLayoutCore>
    </AuthenticatedLayout>
  );
};

const AppShellLayoutCore = ({ children }: AppShellLayoutCoreProps) => {
  useIsVerified(); // should have verified email
  const { identity, user } = useAuthenticatedContext();
  const redirect = useRedirect();

  useEffect(() => {
    // if the org association exists through claims or on the user document, optimistically take them to the main app experience
    if (identity?.organizationId || user?.organizationId) {
      redirect(Routes.Root);
    }
  }, [identity?.organizationId, user?.organizationId]);

  return (
    <BaseLayout>
      <PageContainer>
        <Navbar />
        {children}
        <Footer />
      </PageContainer>
    </BaseLayout>
  );
};

const PageContainer = styled("div")({
  height: "fit-content",
});

interface AppShellLayoutProps {
  children: ReactNode;
}

interface AppShellLayoutCoreProps {
  children: ReactNode;
}

export default AppShellLayout;
