import React from "react";
import { render } from "react-dom";

import { IPaintZones } from "../ZonesMap";
import { ZoneStatus } from "@swyft/domain/src/types/zones";
import { MapZone } from "~/services/data/types";

interface LayerColor {
  active: string;
  inactive: string;
  pending: string;
}

/*
  iterates through zones & creates a fill & border layer for each.
  relies on status for color and tallies active zones to update active zone count displayed above map
*/
export const mapOnLoad = (
  map: React.MutableRefObject<mapboxgl.Map | undefined>,
  zones: MapZone[],
  colors: LayerColor,
  setActiveCount: React.Dispatch<React.SetStateAction<number>>,
) => {
  map.current?.on("load", () => {
    let activeCount = 0;
    if (zones) {
      for (const zone of zones) {
        let color = colors.inactive;
        if (zone.status === ZoneStatus.ACTIVE) {
          color = colors.active;
          activeCount++;
        }
        if (zone.status === ZoneStatus.PENDING) color = colors.pending;

        if (zone.polygon) {
          //Main source polygon
          map.current?.addSource(zone.id, {
            type: "geojson",
            //@ts-ignore
            data: zone.polygon,
            generateId: true,
          });

          //Fill the polygon with color
          map.current?.addLayer({
            id: `${zone.id}-fill`,
            type: "fill",
            source: zone.id,
            paint: {
              "fill-color": color,
              "fill-opacity": 0.4,
            },
          });

          //Draw a border around the polygon
          map.current?.addLayer({
            id: `${zone.id}-border`,
            type: "line",
            source: zone.id,
            paint: {
              "line-color": color,
              "line-width": 1,
            },
          });
        }
      }
      setActiveCount(activeCount);
    }
  });
};

export const addClickEvent = (
  mapRef: React.MutableRefObject<mapboxgl.Map | undefined>,
  source: string,
  mapboxgl: any,
  el: JSX.Element,
  startingPoint: { zoom: number; lat: number; long: number },
  setCurrentZone: React.Dispatch<React.SetStateAction<IPaintZones | undefined>>,
) => {
  mapRef.current?.on("click", `${source}-fill`, (e: any) => {
    if (!mapRef.current) return;
    if (e.features.length > 0) {
      const layer = e.features[0].layer.id;

      // fly to clicked zone and zoom
      mapRef.current?.easeTo({
        center: [e.lngLat.lng, e.lngLat.lat],
        zoom: 7,
        duration: 1000,
      });

      // Create a popup, but don't add it to the map yet.
      const popup = new mapboxgl.Popup({ anchor: true });

      // Copy coordinates array.
      const popupNode = document.createElement("div");
      render(el, popupNode);
      popup
        .addClassName("custom-popup")
        .setLngLat(e.lngLat)
        .setDOMContent(popupNode)
        .addTo(mapRef.current);

      //highlight clicked zone
      setCurrentZone((prev) => {
        return {
          reset: prev?.focus ?? "",
          focus: layer,
          hover: "",
        };
      });

      let zoomOut = true;

      //add custom closePopup event listener
      mapRef.current?.on("closePopup", () => {
        zoomOut = true;
        popup?.remove();
      });

      mapRef.current?.on("closePopupFromSearch", () => {
        //search zooms to result, so back to default zoom should be false
        zoomOut = false;
        popup?.remove();
      });

      //on close remove highlighted zone
      popup.on("close", () => {
        //if true revert to default zoom and coordinates
        if (zoomOut) {
          mapRef.current?.easeTo({
            center: [startingPoint.long, startingPoint.lat],
            zoom: startingPoint.zoom,
            duration: 1000,
          });
        }
        //this should always default back to true
        zoomOut = true;
        setCurrentZone(() => {
          return {
            reset: layer,
            focus: "",
            hover: "",
          };
        });
      });
    }
  });
};

export const addHoverEvent = (
  mapRef: React.MutableRefObject<mapboxgl.Map | undefined>,
  source: string,
  mapboxgl: any,
  el: JSX.Element,
  setCurrentZone: React.Dispatch<React.SetStateAction<IPaintZones | undefined>>,
) => {
  // Create a popup, but don't add it to the map yet.
  const popup = new mapboxgl.Popup({ closeButton: false });
  popup.addClassName("mapbox-hover-tooltip");

  mapRef.current?.on("mouseenter", `${source}-fill`, (e: any) => {
    if (!mapRef.current) return;

    // Change the cursor style as a UI indicator.
    mapRef.current.getCanvas().style.cursor = "pointer";

    // Copy coordinates array.
    const toolTipNode = document.createElement("div");
    render(el, toolTipNode);

    setCurrentZone((prev) => {
      if (prev?.focus === `${source}-fill`) return prev;

      popup
        .setLngLat(e.lngLat)
        .setDOMContent(toolTipNode)
        .addTo(mapRef.current);

      paintZones(PaintStyles.hover, `${source}-fill`, mapRef);

      return {
        reset: "",
        focus: "",
        hover: `${source}-fill`,
      };
    });
  });

  mapRef.current?.on("mouseleave", `${source}-fill`, (e: any) => {
    if (!mapRef.current) return;

    setCurrentZone((prev) => {
      if (prev?.focus === `${source}-fill`) return prev;
      popup.remove();
      return {
        reset: `${source}-fill`,
        focus: "",
        hover: "",
      };
    });
  });
};

export enum PaintStyles {
  reset,
  focus,
  hover,
}

export const paintZones = (
  style: PaintStyles,
  zone: string,
  mapRef: React.MutableRefObject<mapboxgl.Map | undefined>,
) => {
  if (style === PaintStyles.reset) {
    {
      mapRef.current?.setPaintProperty(zone, "fill-opacity", 0.4);
      mapRef.current?.setPaintProperty(
        zone.replace(/fill/, "border"),
        "line-width",
        1,
      );
    }
  }
  if (style === PaintStyles.focus) {
    {
      mapRef.current?.setPaintProperty(zone, "fill-opacity", 0.7);
      mapRef.current?.setPaintProperty(
        zone.replace(/fill/, "border"),
        "line-width",
        3,
      );
    }
  }
  if (style === PaintStyles.hover) {
    {
      mapRef.current?.setPaintProperty(zone, "fill-opacity", 0.4);
      mapRef.current?.setPaintProperty(
        zone.replace(/fill/, "border"),
        "line-width",
        3,
      );
    }
  }
};
