import { PackageSize, MonthlyVolume, PackageWeight } from "@swyft/swyft-common";
import { CountryISO2 } from "../../types/common";

export enum Country {
  CA = "Canada",
  US = "United States",
}
export interface MerchantCreateOneParams {
  country: CountryISO2;
  companyName: string;
  jobTitle: string;
  monthlyVolume: MonthlyVolume;
  packageSize: PackageSize;
  packageWeight: PackageWeight;
}

export interface GetRatesParams {
  merchantId: string;
  zoneId?: string;
  isActive?: boolean;
  country?: string;
}

export interface MerchantRequestQuoteParams {
  merchantId: string;
  zoneId: string;
  shippingVolumeFileURL: string;
  location: {
    name: string;
    notes?: string;
    address: {
      city: string;
      country: string;
      lineOne: string;
      lineTwo?: string;
      postalZip: string;
      subDivision: string;
    };
    contact: {
      firstName: string;
      lastName: string;
      jobTitle: string;
      phone: string;
      email: string;
    };
  };
}

export interface DeactivateZoneParams {
  merchantId: string;
  zoneId: string;
}

export interface ContactSalesParams {
  merchantId: string;
  message: string;
}
