import {
  ensureInitialized,
  fetchAndActivate,
  getBoolean,
  getRemoteConfig,
} from "firebase/remote-config";
import { firebaseApp } from "./firebase";

const remoteConfig = getRemoteConfig(firebaseApp);
remoteConfig.settings.minimumFetchIntervalMillis = 300000; // ensure remote config is refreshed within 5 minutes

// remote config defaults
remoteConfig.defaultConfig = {
  feature_signup_enabled: false,
};

export const initializeRemoteConfig = async () => {
  const activated = await fetchAndActivate(remoteConfig);
  await ensureInitialized(remoteConfig);
};

export const isSignupEnabled = () =>
  getBoolean(remoteConfig, "feature_signup_enabled");
