import { themeOptions } from "@helo/ui/theme";
import { defaultTheme, RaThemeOptions } from "react-admin";

const theme: RaThemeOptions = {
  ...defaultTheme,
  ...themeOptions,
  palette: {
    primary: {
      main: "#18A0FB",
      dark: "#0072C7",
      light: "#6DD1FF",
      contrastText: "#FFFFFF",
    },
    secondary: {
      main: "#031A6B",
      dark: "#000040",
      light: "#44419A",
    },
    success: {
      main: "#00B256",
      light: "#58E584",
      dark: "#00812A",
      contrastText: "#FFFFFF",
    },
    neutral: {
      main: "#E0E0E0",
      contrastText: "#000",
      dark: "rgba(0,0,0,0.26)",
      light: "rgba(0,0,0,0.08)",
    },
  },
  sidebar: {
    width: 256,
    closedWidth: 64,
  },
  components: {
    MuiChip: {
      styleOverrides: {
        root: {
          lineHeight: "140%",
          letterSpacing: "0px",
          fontSize: "13px",
          fontFamily: '"Sofia Pro", sans-serif',
        },
      },
    },
  },
};

declare module "@mui/material/styles/createPalette" {
  interface Palette {
    neutral: PaletteColor;
  }

  interface PaletteOptions {
    neutral: PaletteColorOptions;
  }
}

declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    neutral: true;
  }
}

declare module "@mui/material/Chip" {
  interface ChipPropsColorOverrides {
    neutral: true;
  }
}

export default theme;
