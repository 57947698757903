export enum Routes {
  Root = "/",
  Dashboard = "/",
  FTUX = "/onboarding",
  Register = "/register",
  ResetPassword = "/passwords/reset",
  ForgotPassword = "/passwords/forgot",
  VerifyEmail = "/verify-email",
  UnverifiedEmail = "/unverified-email",
  Users = "/users",
  Login = "/login",
  Rates = "/rates",
  Locations = "/locations",
  Shipments = "/shipments",
  Zones = "/zones",
  Reports = "/reports",
}
