import { FirebaseOptions, initializeApp } from "firebase/app";
import {
  getFunctions,
  httpsCallable,
  HttpsCallableResult,
} from "firebase/functions";
import { Firestore, getFirestore } from "firebase/firestore";

import { PickupLocation } from "~/types/pickup-locations";
import {
  DeleteManyParams,
  DeleteOneParams,
  GetListResult,
  GetOneResult,
  UpdateParams,
  UpdateResult,
} from "../types";
import errorHandler from "../errorHandler";
import {
  CreatePickupLocationParams,
  CreatePickupLocationRequest,
  GetOnePickupLocationParams,
  GetPickupLocationsParams,
  UpdatePickupLocationRequest,
} from "./types";
import { list, get } from "../../models/pickup-locations";
import { getPaginationSlice } from "../shared";

export default class PickupLocationController {
  private db: Firestore;

  private functions;

  /**
   * Creates a Users controller instance with provided config to setup Firebase app.
   * @param {FirebaseOptions} config Firebase configuration to initialize app.
   */
  constructor(config: FirebaseOptions) {
    const firebaseApp = initializeApp(config);
    const firebaseFunctions = getFunctions(firebaseApp);

    this.db = getFirestore(firebaseApp);

    this.functions = {
      create: httpsCallable<CreatePickupLocationRequest, void>(
        firebaseFunctions,
        "pickupLocations-submitCreateRequest",
      ),
      update: httpsCallable<
        UpdatePickupLocationRequest,
        { data: PickupLocation }
      >(firebaseFunctions, "pickupLocations-update"),
      delete: httpsCallable<{ id: string }, void>(
        firebaseFunctions,
        "pickupLocations-requestRemoval",
      ),
    };
  }

  /**
   * Creates a single pickup location
   * @param params.data all the details needed to create a pickup location
   * @returns void if successful
   */
  create = async (params: CreatePickupLocationParams) => {
    try {
      await this.functions.create({
        ...params.data,
      });
    } catch (err: unknown) {
      return errorHandler(err);
    }
  };

  /**
   * Get a single pickup location
   * NOTE: only works for merchants
   * @param perams.id the id of the pickup location
   * @param params.meta the organization details
   * @returns the pickup location object
   */
  getOne = async (
    params: GetOnePickupLocationParams,
  ): Promise<GetOneResult<PickupLocation>> => {
    try {
      const pickupLocation = await get(this.db, {
        id: params.id,
        ...params.meta,
      });

      return { data: pickupLocation };
    } catch (err: unknown) {
      return errorHandler(err);
    }
  };

  /**
   * Get a list of pickup locations
   * NOTE: only works for merchants
   * @param params.filter the organization to get pickup locations for
   * @returns the list of pickup locations, and the total count of results after filters were applied
   */
  getList = async (
    params: GetPickupLocationsParams,
  ): Promise<GetListResult<PickupLocation>> => {
    try {
      const { startIndex, endIndex } = getPaginationSlice(params.pagination);
      const locations = await list(this.db, {
        filter: params.filter,
        ...(params.sort && {
          sort: {
            ...params.sort,
            order: params.sort?.order === "ASC" ? "asc" : "desc",
          },
        }),
      });

      return {
        data: locations.slice(startIndex, endIndex),
        total: locations.length,
      };
    } catch (err: unknown) {
      return errorHandler(err);
    }
  };

  /**
   * Update a pickup location
   * @param params.id the id of the pickup location to update
   * @param params.data the data of the pickup location to update
   * @returns the updated pickup location
   */
  update = async (
    params: UpdateParams<PickupLocation>,
  ): Promise<UpdateResult<PickupLocation>> => {
    try {
      const { data } = await this.functions.update({
        id: params.id,
        ...params.meta,
        name: params.data.name,
        pickupNotes: params.data.pickupNotes,
        cutoffTime: params.data.cutoffTime,
        pickupTime: params.previousData.pickupTime,
        contact: {
          address: {
            line1: params.previousData.contact.address.line1,
            line2: params.previousData.contact.address.line2,
            city: params.previousData.contact.address.city,
            postalCode: params.previousData.contact.address.postalCode,
            province: params.previousData.contact.address.province,
            country: params.previousData.contact.address.country,
          },
          firstName:
            params.data.contact?.firstName ||
            params.previousData.contact.firstName,
          lastName: params.data.contact?.lastName,
          title: params.data.contact?.title,
          email: params.data.contact?.email,
          phone: params.data.contact?.phone || "",
        },
      });

      return data;
    } catch (err: unknown) {
      return errorHandler(err);
    }
  };

  /**
   * Delete a single pickup location
   * @param params.id the id of the pickup location to delete
   * @returns void if successful
   */
  delete = async (params: DeleteOneParams) => {
    try {
      return await this.functions.delete(params);
    } catch (err: unknown) {
      return errorHandler(err);
    }
  };

  /**
   * Delete multiple pickup locations
   * @param params.ids the ids of all the pickup locations to delete
   * @returns void if successful
   */
  deleteMany = async (params: DeleteManyParams) => {
    const promises: Promise<HttpsCallableResult>[] = [];
    params.ids.forEach((singleId: string) => {
      promises.push(this.functions.delete({ id: singleId }));
    });
    await Promise.all(promises);
    return params.ids;
  };
}
